import {
  GET_REPORTS,
} from 'constants/reports';

export default (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};
