import React, { Component } from 'react';
import PropTypes from 'prop-types';

// material-ui
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Close from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

// styles
import styles from './styles';

class Deletable extends Component {
  static propTypes = {
    classes: PropTypes.object,
    children: PropTypes.node,
    onDelete: PropTypes.func,
  };

  render() {
    const { classes, children, onDelete } = this.props;

    return (
      <div className={classes.item}>
        <Grid container alignItems="center">
          <Grid item>
            {children}
          </Grid>
          <Grid item>
            <IconButton
              onClick={onDelete}
              style={{ padding: 5 }}
            >
              <Close style={{ fontSize: 20 }} />
            </IconButton>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(Deletable);
