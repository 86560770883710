import { networkAction } from 'helpers/network/networkAction';

import {
  GET_ORGANIZATIONS,
  GET_ORGANIZATION_BY_ID,
  DELETE_ORGANIZATION,
  UPDATE_ORGANIZATION,
  CREATE_ORGANIZATION,
  ADD_USER_TO_ORGANIZATION,
  GET_ORGANIZATION_USERS,
  DELETE_ORGANIZATION_USER,
  SET_ORGANIZATION_BY_DEFAULT,
  JOIN_ORGANIZATION,
} from 'constants/organizations';

import * as Api from 'api';

export const getOrganizations = () => async dispatch => networkAction(
  dispatch,
  GET_ORGANIZATIONS,
  Api.getOrganizations,
  [],
);

export const setOrganizationByDefault = id => async dispatch => networkAction(
  dispatch,
  SET_ORGANIZATION_BY_DEFAULT,
  Api.setOrganizationByDefault,
  [id],
);

export const getOrganizationUsers = id => async dispatch => networkAction(
  dispatch,
  GET_ORGANIZATION_USERS,
  Api.getOrganizationUsers,
  [id],
);

export const joinOrganization = (id, body) => async dispatch => networkAction(
  dispatch,
  JOIN_ORGANIZATION,
  Api.joinOrganization,
  [id, body],
);


export const addUserToOrganization = (id, body) => async dispatch => networkAction(
  dispatch,
  ADD_USER_TO_ORGANIZATION,
  Api.addUserToOrganization,
  [id, body],
);

export const getOrganizationById = id => async dispatch => networkAction(
  dispatch,
  GET_ORGANIZATION_BY_ID,
  Api.getOrganizationById,
  [id],
);

export const deleteOrganization = id => async dispatch => networkAction(
  dispatch,
  DELETE_ORGANIZATION,
  Api.deleteOrganization,
  [id],
);

export const deleteOrganizationUser = id => async dispatch => networkAction(
  dispatch,
  DELETE_ORGANIZATION_USER,
  Api.deleteOrganizationUser,
  [id],
);

export const createOrganization = body => async dispatch => networkAction(
  dispatch,
  CREATE_ORGANIZATION,
  Api.createOrganization,
  [body],
);

export const updateOrganization = (id, body) => async dispatch => networkAction(
  dispatch,
  UPDATE_ORGANIZATION,
  Api.updateOrganization,
  [id, body],
);
