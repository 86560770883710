// material-ui
import { withStyles } from "@material-ui/core/styles";
import AdvancedSearch from "components/AdvancedSearchRefactor";
import Table from "components/TablePagination";
// custom
import { REACT_APP_API_PREFIX } from "config";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class VendorsList extends Component {
  static propTypes = {
    classes: PropTypes.object,
    vendors: PropTypes.array,
    onSelect: PropTypes.func,
    getVendors: PropTypes.func,
    companies: PropTypes.array,
  };

  onSelect(selectedVendor) {
    const { onSelect } = this.props;
    this.setState({ selectedVendor });
    if (onSelect) {
      onSelect(selectedVendor);
    }
  }

  render() {
    const { vendors, getVendors, history, companies } = this.props;

    return (
      <AdvancedSearch
        history={history}
        rowCount={vendors.length}
        location={window.location}
        refresh={getVendors}
        keyword="vendors"
        metaDataEndpoint={`${REACT_APP_API_PREFIX}/ap-dashboard/vendors/searchMetadata`}
        metaDataOveride={{
          companyID: {
            type: "autocomplete",
            data: companies,
            valueKey: "id",
            labelKey: "name",
          },
        }}
      >
        <Table
          data={vendors}
          meta={[
            {
              path: "name",
              title: "Name",
              numeric: false,
            },
            {
              path: "companyName",
              title: "Company",
              numeric: false,
            },
          ]}
          title={"Vendors"}
          onRowSelect={this.onSelect.bind(this)}
        />
      </AdvancedSearch>
    );
  }
}

export default withStyles(styles)(VendorsList);
