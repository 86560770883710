import {
  GET_GL_ACCOUNTS,
} from 'constants/glAccounts';

export default (state = [], action) => {
    switch (action.type) {
    case `${GET_GL_ACCOUNTS}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};
