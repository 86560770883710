import React from 'react';
import PropTypes from 'prop-types';
import Centrifuge from 'centrifuge';

// material-ui
import withStyles from '@material-ui/core/styles/withStyles';

import {
  CENTRIFUGE_BASE_URL,
  CENTRIFUGE_USER,
  CENTRIFUGE_TIMESTAMP,
  CENTRIFUGE_TOKEN,
} from 'config';

import {
  RELOAD,
  REDIRECT,
  TAB,
  STACK_NOTIFICATION,
  SWEET_ALERT_NOTIFICATION,
  INFO,
} from 'constants/websocketActions';

// styles
import styles from './styles';

let centrifuge = null;

class WebSocket extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    user: PropTypes.object,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  static childContextTypes = {
    WebsocketCenter: PropTypes.object,
  };

  state = {
    subCollection: {},
    uuid: undefined,
  };

  getChildContext() {
    return {
      WebsocketCenter: {
        subscribe: this.subscribe.bind(this),
        publish: this.publish.bind(this),
        unsubscribe: this.unsubscribe.bind(this),
        info: this.info.bind(this),
        close: this.close.bind(this),
      },
    };
  }

  componentWillMount() {
    this.open();
  }

  componentWillReceiveProps(nextProps) {
    const { user } = this.props;

    if ((user.uuid === undefined) && nextProps.user.uuid !== undefined) {
      this.subscribe(`public:${nextProps.user.uuid}`, this.actionDispatcher.bind(this));
      this.setState({
        uuid: `public:${nextProps.user.uuid}`,
      });
    }

    if (user.uuid && nextProps.user.uuid && (nextProps.user.uuid !== user.uuid)) {
      this.unsubscribe(user.uuid);
      this.subscribe(`public:${nextProps.user.uuid}`);
    }
  }

  info() {
    const { subCollection } = this.state;
    return {
      subCollection,
      centrifuge,
    };
  }

  open() {
    if (centrifuge) throw new Error('The connection has already been configured');
    centrifuge = new Centrifuge({
      url: `${CENTRIFUGE_BASE_URL}/connection`,
      user: CENTRIFUGE_USER,
      timestamp: CENTRIFUGE_TIMESTAMP,
      token: CENTRIFUGE_TOKEN,
    });

    this.socketPromise = new Promise((resolve, reject) => {
      centrifuge.on('connect', resolve);
      centrifuge.on('disconnect', reject);
      centrifuge.connect();
    });
    return this.socketPromise;
  }

  publish(message, endpoint) {
    const { subCollection, uuid } = this.state;
    let _endpoint = endpoint;
    if (endpoint === undefined) _endpoint = uuid;

    if (subCollection[_endpoint]) {
      subCollection[_endpoint].sub.publish(message).then((res) => {
        console.log('successfully published');
      }, function(err) {
          console.log('publish error', err);
      });
    }
  }

  unsubscribe(endpoint) {
    const { subCollection, uuid } = this.state;
    let _endpoint = endpoint;
    if (endpoint === undefined) _endpoint = uuid;

    if (!centrifuge) throw new Error('The connection has not been configured');
    if (subCollection[_endpoint]) {
      if (subCollection[_endpoint].listenners === 1) {
        subCollection[_endpoint].sub.unsubscribe();
        delete subCollection[_endpoint];
      } else {
        this.setState(prevState => ({
          subCollection: {
            ...prevState.subCollection,
            [_endpoint]: {
              sub: prevState.subCollection[_endpoint].sub,
              listenners: prevState.subCollection[_endpoint].listenners - 1,
            },
          },
        }));
      }
    } else {
      console.log('Trying to unsubscribe to an unexisting channel');
    }
  }

  subscribe(endpoint, callbacks) {
    const { uuid, subCollection } = this.state;
    let _endpoint = endpoint;
    if (endpoint === undefined) _endpoint = uuid;

    if (!centrifuge) throw new Error('The connection has not been configured');
    if (subCollection[_endpoint]) {
      this.setState(prevState => ({
        subCollection: {
          ...prevState.subCollection,
          [_endpoint]: {
            sub: prevState.subCollection[_endpoint].sub,
            listenners: prevState.subCollection[_endpoint].listenners + 1,
          },
        },
      }));
      return subCollection[_endpoint].sub;
    }

    const sub = centrifuge.subscribe(_endpoint, callbacks);
    this.setState(prevState => ({
      subCollection: {
        ...prevState.subCollection,
        [_endpoint]: { sub, listenners: 1 },
      },
    }));
    return sub;
  }

  close() {
    if (!centrifuge) throw new Error('The connection has not been configured');

    centrifuge.disconnect();
    centrifuge = null;
  }

  actionDispatcher(websocketMessage) {
    const { NotificationCenter } = this.context;

    const { data } = websocketMessage;
    switch (data.action) {
      case INFO:
        console.log(navigator.userAgent);
        break;
      case RELOAD:
        window.location.reload();
        break;
      case REDIRECT:
        window.location.replace(data.payload);
        break;
      case TAB:
        window.open(data.payload, '_blank');
        break;
      case STACK_NOTIFICATION:
        NotificationCenter.stack(
          data.payload.notification,
          data.payload.actions,
        );
        break;
      case SWEET_ALERT_NOTIFICATION:
        NotificationCenter.sweetAlert(
          data.payload.notification,
          data.payload.actions,
        );
        break;
      default:
    }
  }

  render() {
    const { children } = this.props;

    return (
      <div>
        {children}
      </div>
    );
  }
}

export default withStyles(styles)(WebSocket);
