import {
  Backdrop,
  Button,
  ButtonGroup,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import AddIcon from "@material-ui/icons/Add";
// reactor
import Page from "components/Page";
import Payable from "components/Payable";
// custom
import PayablesList from "components/PayablesList";
// helpers
import {
  getJsonFromUrl,
  removeURLParameter,
  updateURLParameter,
} from "helpers";
import PropTypes from "prop-types";
import React from "react";
// styles
import styles from "./styles";

class WrapperPayablePage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    user: PropTypes.object,
    refresh: PropTypes.func,
    filters: PropTypes.array,
    urlParams: PropTypes.object,
    role: PropTypes.string,
    history: PropTypes.object,
    location: PropTypes.object,
    loading: PropTypes.bool,
    dataLoading: PropTypes.bool,
    getPayableById: PropTypes.func,
    payables: PropTypes.array,
    createPayable: PropTypes.func,
    deletePayable: PropTypes.func,
    selectedPayable: PropTypes.object,
    updatePayable: PropTypes.func,
    uploadPayableFile: PropTypes.func,
    refreshKey: PropTypes.string,
    payableID: PropTypes.number,
    updateGLAccount: PropTypes.func,
    lockPayable: PropTypes.func,
    getPayables: PropTypes.func,
    users: PropTypes.array,
    priorityLevels: PropTypes.array,
    vendors: PropTypes.array,
    createPayableLineItem: PropTypes.func,
    updatePayableLineItem: PropTypes.func,
    updateLineItemInternalMemo: PropTypes.func,
    deletePayableLineItem: PropTypes.func,
    uploadLineItemFile: PropTypes.func,
    createExport: PropTypes.func,
    qbExport: PropTypes.func,
    itemClasses: PropTypes.array,
    glAccounts: PropTypes.array,
    myPayables: PropTypes.array,
    companies: PropTypes.array,
    contentLoading: PropTypes.bool,
    getMyPayables: PropTypes.func,
  };

  static contextTypes = {
    MessageCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { MessageCenter } = this.context;
    const urlParams = getJsonFromUrl(window.location);
    this.state = {
      formPayableOpen: false,
      payableOpen: urlParams.id ? true : false,
      backdropOpen: true,
      exportEnabled: false,
      toExport: [],
      beforeExportingOpen: false,
      index: urlParams.index ? Number(urlParams.index) : 0,
    };
    MessageCenter.close("right");
  }

  onIndexChange(e, v) {
    const { history } = this.props;
    this.setState({ index: v });

    history.push({
      search: updateURLParameter("index", v),
    });
  }

  async exportNow() {
    const { createExport, refresh } = this.props;
    const { toExport, transactionType, companyID } = this.state;

    if (toExport.length) {
      const body = {
        companyID,
        transactionType,
        payableIDs: [],
      };
      for (const key in toExport) {
        if (toExport.hasOwnProperty(key)) {
          const payable = toExport[key];
          body.payableIDs.push(payable.id);
        }
      }
      this.setState({ exporting: true });
      const r = await createExport(body);
      if (r.success) {
        this.setState({
          exporting: false,
          exportFile: r.payload,
          exportEnabled: false,
          companyID: undefined,
          transactionType: undefined,
          toExport: [],
        });
      } else {
        this.setState({ exporting: false });
      }
      refresh();
    }
  }

  render() {
    const {
      classes,
      history,
      location,
      loading,
      createPayable,
      deletePayable,
      updatePayable,
      refresh,
      users,
      user,
      refreshKey,
      payables,
      uploadPayableFile,
      updateGLAccount,
      lockPayable,
      vendors,
      getPayables,
      createPayableLineItem,
      updatePayableLineItem,
      updateLineItemInternalMemo,
      deletePayableLineItem,
      itemClasses,
      glAccounts,
      companies,
      myPayables,
      getPayableById,
      priorityLevels,
      getMyPayables,
      uploadLineItemFile,
      qbExport,
      role,
    } = this.props;

    const {
      index,
      selectedPayable,
      exportEnabled,
      toExport,
      beforeExportingOpen,
    } = this.state;

    const data = index === 0 ? myPayables : payables;

    return (
      <div>
        <Page
          helmet="Payables"
          loading={loading}
          fab={
            <Fab
              variant="fab"
              color="primary"
              aria-label="Add"
              onClick={() =>
                this.setState({ payableOpen: true, selectedPayable: undefined })
              }
            >
              <AddIcon />
            </Fab>
          }
          loadingMessage={"Loading Payables"}
          tabs={
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              className={classes.tab}
            >
              <Tabs
                value={index}
                onChange={this.onIndexChange.bind(this)}
                indicatorColor="primary"
                textColor="primary"
                scrollable
                scrollButtons="auto"
                classes={{
                  root: classes.tabsRoot,
                  indicator: classes.tabsIndicator,
                }}
              >
                <Tab
                  label="My Payables"
                  classes={{
                    root: classes.tabRoot,
                    selected: classes.tabSelected,
                  }}
                />
                {role !== "user" ? (
                  <Tab
                    label="All"
                    classes={{
                      root: classes.tabRoot,
                      selected: classes.tabSelected,
                    }}
                  />
                ) : (
                  []
                )}
              </Tabs>
              <div>
                {toExport &&
                toExport.length &&
                (role === "manager" || role === "superadmin") ? (
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => this.setState({ confirmDialogOpen: true })}
                    style={{
                      color: "white",
                      background: "#ffa000",
                      textTransform: "none",
                      marginRight: 16,
                    }}
                  >
                    Export {toExport.length} Payable
                    {toExport.length > 1 ? "s" : ""}
                  </Button>
                ) : (
                  []
                )}
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => {
                    if (this.state.exportEnabled) {
                      this.setState({
                        exportEnabled: false,
                        companyID: undefined,
                        transactionType: undefined,
                        toExport: [],
                      });
                      const urlParams = getJsonFromUrl(window.location);
                      getPayables(
                        urlParams.filters ? JSON.parse(urlParams.filters) : []
                      );
                      getMyPayables();
                    } else {
                      this.setState({
                        beforeExportingOpen: true,
                      });
                    }
                  }}
                  style={{ textTransform: "none", marginRight: 16 }}
                >
                  {this.state.exportEnabled
                    ? `Cancel Export for ${
                        companies.find((cid) => this.state.companyID === cid.id)
                          ?.name
                      }`
                    : "Export"}
                </Button>
              </div>
            </div>
          }
        >
          <PayablesList
            payables={data}
            vendors={vendors}
            key={refreshKey}
            priorityLevels={priorityLevels}
            companies={companies}
            users={users}
            getPayables={index === 0 ? getMyPayables : getPayables}
            history={history}
            location={location}
            selectedPayable={selectedPayable}
            onSelect={(sp) => {
              this.setState({
                payableOpen: true,
                selectedPayable: sp,
              });
              history.push({ search: updateURLParameter("id", sp.id) });
            }}
            onDuplicate={(sp) => {
              this.setState({
                payableOpen: true,
              });
              history.push({
                search: updateURLParameter("duplicateID", sp.id),
              });
            }}
            groupSelectEnabled={exportEnabled}
            onGroupSelect={(p) => this.setState({ toExport: p })}
          />
          {companies.length && vendors.length && users.length ? (
            <Payable
              payable={selectedPayable}
              role={role}
              refresh={refresh}
              priorityLevels={priorityLevels}
              close={() => {
                this.setState({
                  payableOpen: false,
                  selectedPayable: undefined,
                });
                history.push({ search: removeURLParameter("id") });
              }}
              history={history}
              open={this.state.payableOpen}
              companies={companies}
              glAccounts={glAccounts}
              vendors={vendors}
              itemClasses={itemClasses}
              users={users}
              user={user}
              api={{
                updateGLAccount,
                createPayableLineItem,
                uploadPayableFile,
                uploadLineItemFile,
                updatePayableLineItem,
                updateLineItemInternalMemo,
                deletePayableLineItem,
                lockPayable,
                createPayable,
                deletePayable,
                updatePayable,
                updatePayable,
                getPayableById,
                qbExport,
                refresh,
              }}
            />
          ) : (
            []
          )}
          <Dialog
            open={this.state.confirmDialogOpen}
            onClose={() => this.setState({ confirmDialogOpen: false })}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            {this.state.exporting ? (
              <div style={{ textAlign: "center" }}>
                <DialogTitle id="alert-dialog-title">
                  <CircularProgress />
                  <br />
                  Exporting...
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    We are uploading the file to Google Drive. Please wait.
                  </DialogContentText>
                </DialogContent>
              </div>
            ) : (
              <div>
                {this.state.exportFile ? (
                  <>
                    <DialogTitle id="alert-dialog-title">
                      Successfully Exported
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        A new file <b>{this.state.exportFile.name}</b> has been
                        added to Google Drive.
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          this.setState({
                            confirmDialogOpen: false,
                            exportFile: undefined,
                          })
                        }
                      >
                        Close
                      </Button>
                    </DialogActions>
                  </>
                ) : (
                  <>
                    <DialogTitle id="alert-dialog-title">
                      You are about to export {this.state.toExport.length}{" "}
                      Payable
                      {this.state.toExport.length > 1 ? "s" : ""}
                      <br />
                      <Typography variant="caption" color="primary">
                        {`${
                          this.state.transactionType === 0 ? "Payable" : "Credit"
                        } for ${
                          companies.find(
                            (cid) => this.state.companyID === cid.id
                          )?.name
                        }`}
                      </Typography>
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Your export will be available on the Quickbooks Export
                        Folder on Google drive.
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={() =>
                          this.setState({ confirmDialogOpen: false })
                        }
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={this.exportNow.bind(this)}
                        color="primary"
                        autoFocus
                        variant="contained"
                      >
                        Export Now
                      </Button>
                    </DialogActions>
                  </>
                )}
              </div>
            )}
          </Dialog>
          <Backdrop
            style={{ zIndex: 99999 }}
            open={
              this.state.backdropOpen &&
              !(companies.length && vendors.length && users.length)
            }
            onClick={() => this.setState({ backdropOpen: false })}
          >
            <CircularProgress color="inherit" style={{ color: "white" }} />
          </Backdrop>
          <Dialog
            open={beforeExportingOpen}
            onClose={() => this.setState({ beforeExportingOpen: false })}
          >
            <DialogTitle id="alert-dialog-title">
              Before you export...
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography style={{ color: "black" }}>
                      Please select the company you wish to export payables
                      from:
                    </Typography>
                    <TextField
                      select
                      fullWidth
                      label="Company"
                      variant="filled"
                      value={this.state.companyID}
                      onChange={(e) =>
                        this.setState({ companyID: e.target.value })
                      }
                    >
                      {companies.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography style={{ color: "black" }}>
                      What is the kind of export?
                    </Typography>
                    <ButtonGroup
                      color="primary"
                      aria-label="outlined primary button group"
                    >
                      <Button
                        style={{ boxShadow: "none" }}
                        variant={
                          this.state.transactionType === 0 ? "contained" : undefined
                        }
                        onClick={() => this.setState({ transactionType: 0 })}
                      >
                        Bill
                      </Button>
                      <Button
                        style={{ boxShadow: "none" }}
                        variant={
                          this.state.transactionType === 1 ? "contained" : undefined
                        }
                        onClick={() => this.setState({ transactionType: 1 })}
                      >
                        Credit
                      </Button>
                      <Button
                        style={{ boxShadow: "none" }}
                        variant={
                          this.state.transactionType === 2 ? "contained" : undefined
                        }
                        onClick={() => this.setState({ transactionType: 2 })}
                      >
                        Check
                      </Button>
                      <Button
                        style={{ boxShadow: "none" }}
                        variant={
                          this.state.transactionType === 3 ? "contained" : undefined
                        }
                        onClick={() => this.setState({ transactionType: 3 })}
                      >
                        Journal Entry
                      </Button>
                    </ButtonGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography style={{ color: "black" }}>
                      Do you want to see all exportable payables?
                    </Typography>
                    <ButtonGroup
                      color="primary"
                      aria-label="outlined primary button group"
                    >
                      <Button
                        style={{ boxShadow: "none" }}
                        variant={
                          this.state.exportAll === 0 ? "contained" : undefined
                        }
                        onClick={() => this.setState({ exportAll: 0 })}
                      >
                        No, Only Mine
                      </Button>
                      <Button
                        style={{ boxShadow: "none" }}
                        variant={
                          this.state.exportAll === 1 ? "contained" : undefined
                        }
                        onClick={() => this.setState({ exportAll: 1 })}
                      >
                        Yes
                      </Button>
                    </ButtonGroup>
                  </Grid>
                </Grid>
              </DialogContentText>
              <DialogActions>
                <Button
                  onClick={() =>
                    this.setState({
                      beforeExportingOpen: false,
                      companyID: undefined,
                      exportMine: undefined,
                      toExport: [],
                      transactionType: undefined,
                      exportEnabled: false,
                    })
                  }
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    this.setState((prevState) => ({
                      exportEnabled: !prevState.exportEnabled,
                      beforeExportingOpen: false,
                      toExport: [],
                    }));
                    const filters = [
                      {
                        name: "payables.transactionType",
                        comparison: "eq",
                        value: this.state.transactionType,
                      },
                      {
                        name: "payables.companyID",
                        comparison: "eq",
                        value: this.state.companyID,
                      },
                      {
                        name: "payables.locked",
                        comparison: "eq",
                        value: true,
                      },
                      {
                        name: "payables.exportID",
                        comparison: "eq",
                        value: 0,
                      },
                    ];

                    if (this.state.exportAll === 0) {
                      this.setState({ index: 0 });
                      getMyPayables(filters);
                    } else {
                      this.setState({ index: 1 });
                      getPayables(filters);
                    }
                  }}
                  color="primary"
                  autoFocus
                  disabled={
                    this.state.companyID === undefined ||
                    this.state.transactionType === undefined
                  }
                  variant="contained"
                >
                  Next
                </Button>
              </DialogActions>
            </DialogContent>
          </Dialog>
        </Page>
      </div>
    );
  }
}
export default withStyles(styles)(WrapperPayablePage);
