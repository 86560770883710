import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  Grid,
  MenuItem,
  TextField,
  Typography, } from "@material-ui/core";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import AddIcon from "@material-ui/icons/Add";
import FormJournalEntry from "components/FormJournalEntry";
// custom
import JournalEntriesList from "components/JournalEntriesList";
// reactor
import Page from "components/Page";
import PropTypes from "prop-types";
import React from "react";
// styles
import styles from "./styles";

class WrapperJournalEntryPage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    loading: PropTypes.bool,
    journalEntries: PropTypes.array,

    getJournalEntryByID: PropTypes.func,
    getJournalEntries: PropTypes.func,
    createJournalEntry: PropTypes.func,
    deleteJournalEntry: PropTypes.func,
    updateJournalEntry: PropTypes.func,

    uploadJournalEntryFile: PropTypes.func,
    lockJournalEntry: PropTypes.func,
    createExport: PropTypes.func,

    createJournalEntryItem: PropTypes.func,
    updateJournalEntryItem: PropTypes.func,
    deleteJournalEntryItem: PropTypes.func,
    companies: PropTypes.array,
    glAccounts: PropTypes.array,
    itemClasses: PropTypes.array,
    vendors: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedJournalEntry: undefined,
      selectedJournalEntryItems: [],
      formJournalEntryOpen: false,
      history: [urlParams],
      companyID: 0,
    };
  }

  async exportNow() {
    const { createExport, refresh } = this.props;
    const { toExport, companyID } = this.state;

    if (toExport.length) {
      const body = {
        companyID,
        transactionType: 4,
        journalIDs: [],
      };
      for (const key in toExport) {
        if (toExport.hasOwnProperty(key)) {
          const journalEntry = toExport[key];
          body.journalIDs.push(journalEntry.id);
        }
      }
      this.setState({ exporting: true });
      const r = await createExport(body);
      if (r.success) {
        this.setState({
          exporting: false,
          exportFile: r.payload,
          exportEnabled: false,
          companyID: undefined,
          toExport: [],
        });
      } else {
        this.setState({ exporting: false });
      }
      refresh();
    }
  }


  render() {
    const {
      classes,
      history,
      loading,
      getJournalEntries,
      createJournalEntry,
      deleteJournalEntry,
      updateJournalEntry,
      lockJournalEntry,
      createJournalEntryItem,
      updateJournalEntryItem,
      uploadJournalEntryFile,
      deleteJournalEntryItem,
      getJournalEntryByID,
      refresh,
      journalEntries,
      companies,
      users,
      glAccounts,
      itemClasses,
      vendors,
    } = this.props;

    const {
      index,
      selectedJournalEntry,
      formJournalEntryOpen,
      selectedJournalEntryItems,
      companyID,

      beforeExportingOpen,
      exportEnabled,
      toExport,
      confirmExportDialogOpen,
    } = this.state;

    return (
      <Page
        helmet="JournalEntries"
        loading={loading}
        header={
          <div
              style={{
                float: "right"
              }}
          >
            {toExport &&
              toExport.length  ? (
              <Button
                size="small"
                variant="contained"
                onClick={() => this.setState({ confirmExportDialogOpen: true })}
                style={{
                  color: "white",
                  background: "#ffa000",
                  textTransform: "none",
                  marginRight: 16,
                  margin: 10,
                }}
              >
                Export {toExport.length} Payable
                {toExport.length > 1 ? "s" : ""}
              </Button>
            ) : (
              []
            )}
            <Button
              size="small"
              variant="outlined"
              onClick={() => {
                if (this.state.exportEnabled) {
                  this.setState({
                    exportEnabled: false,
                    companyID: undefined,
                    transactionType: undefined,
                    toExport: [],
                  });
                  refresh();
                } else {
                  this.setState({
                    beforeExportingOpen: true,
                  });
                }
              }}
              style={{ textTransform: "none", marginLeft: 16, margin: 10, float: "right" }}
            >
              {exportEnabled ? "Cancel Export" : "Export"}
            </Button>
          </div>
        }
        fab={
          <Fab
            variant="fab"
            color="primary"
            aria-label="Add"
            onClick={() => this.setState({ formJournalEntryOpen: true })}
          >
            <AddIcon />
          </Fab>
        }
        loadingMessage={"Fetching all JournalEntries"}
      >
        <JournalEntriesList
          journalEntries={journalEntries}
          refresh={refresh}
          companies={companies}
          users={users}
          history={history}
          groupSelectEnabled={exportEnabled}
          onGroupSelect={(p) => this.setState({ toExport: p })}
          selectedJournalEntry={selectedJournalEntry}
          onSelect={(selectedJournalEntry) =>
            this.setState({
              selectedJournalEntry,
              selectedJournalEntryItems: selectedJournalEntry.journalEntryItems ? selectedJournalEntry.journalEntryItems : [],
              formJournalEntryOpen: true,
            })
          }
        />
        <FormJournalEntry
          open={formJournalEntryOpen}
          journalEntry={selectedJournalEntry}
          journalEntryItems={selectedJournalEntryItems}
          fullScreen={true}
          close={() => {
            this.setState({
              formJournalEntryOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedJournalEntry: undefined,
                }),
              200
            );
          }}
          createJournalEntry={createJournalEntry}
          deleteJournalEntry={deleteJournalEntry}
          updateJournalEntry={updateJournalEntry}
          lockJournalEntry={lockJournalEntry}
          createJournalEntryItem={createJournalEntryItem}
          deleteJournalEntryItem={deleteJournalEntryItem}
          updateJournalEntryItem={updateJournalEntryItem}
          uploadJournalEntryFile={uploadJournalEntryFile}
          getJournalEntryByID={getJournalEntryByID}
          companies={companies}
          glAccounts={glAccounts}
          itemClasses={itemClasses}
          vendors={vendors}
          refresh={refresh}
          api={{
            getJournalEntryByID,
            createJournalEntry,
            lockJournalEntry,
            createJournalEntryItem,
            updateJournalEntryItem,
            deleteJournalEntryItem,
            deleteJournalEntry,
            updateJournalEntry,
            refresh,
            uploadJournalEntryFile,
          }}
        />
        <Dialog
          open={confirmExportDialogOpen}
          onClose={() => this.setState({ confirmExportDialogOpen: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {this.state.exporting ? (
            <div style={{ textAlign: "center" }}>
              <DialogTitle id="alert-dialog-title">
                <CircularProgress />
                <br />
                Exporting...
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  We are uploading the file to Google Drive. Please wait.
                </DialogContentText>
              </DialogContent>
            </div>
          ) : (
            <div>
              {this.state.exportFile ? (
                <>
                  <DialogTitle id="alert-dialog-title">
                    Successfully Exported
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      A new file <b>{this.state.exportFile.name}</b> has been
                      added to Google Drive.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        this.setState({
                          confirmExportDialogOpen: false,
                          exportFile: undefined,
                        })
                      }
                    >
                      Close
                    </Button>
                  </DialogActions>
                </>
              ) : (
                <>
                  <DialogTitle id="alert-dialog-title">
                    You are about to export {toExport ? toExport.length : 0}{" "}
                        {toExport && toExport.length > 1 ? "Journal Entries" : "Journal Entry"}
                    <br />
                    <Typography variant="caption" color="primary">
                      {`${companies.find(
                          (cid) => companyID === cid.id
                        )?.name
                        } - Journal Entries`}
                    </Typography>
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Your export will be available on the Quickbooks Export
                      Folder on Google drive.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() =>
                        this.setState({ confirmExportDialogOpen: false })
                      }
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={this.exportNow.bind(this)}
                      color="primary"
                      autoFocus
                      variant="contained"
                    >
                      Export Now
                    </Button>
                  </DialogActions>
                </>
              )}
            </div>
          )}
        </Dialog>
        <Dialog
          open={beforeExportingOpen}
          onClose={() => this.setState({ beforeExportingOpen: false })}
        >
          <DialogTitle id="alert-dialog-title">
            Before you export...
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography style={{ color: "black" }}>
                    Please select the company you wish to export payables
                    from:
                  </Typography>
                  <TextField
                    select
                    fullWidth
                    label="Company"
                    variant="filled"
                    value={companyID}
                    onChange={(e) =>
                      this.setState({ companyID: e.target.value })
                    }
                  >
                    {companies.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </DialogContentText>
            <DialogActions>
              <Button
                onClick={() =>
                  this.setState({
                    beforeExportingOpen: false,
                    companyID: undefined,
                    exportMine: undefined,
                    toExport: [],
                    transactionType: undefined,
                    exportEnabled: false,
                  })
                }
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  this.setState((prevState) => ({
                    exportEnabled: true,
                    beforeExportingOpen: false,
                    toExport: [],
                  }));
                  const filters = [
                    {
                      name: "journalEntries.companyID",
                      comparison: "eq",
                      value: companyID,
                    },
                    {
                      name: "journalEntries.locked",
                      comparison: "eq",
                      value: true,
                    },
                    {
                      name: "journalEntries.exportID",
                      comparison: "eq",
                      value: 0,
                    },
                  ];
                  getJournalEntries(filters);
                }}
                color="primary"
                autoFocus
                disabled={
                  companyID === undefined
                }
                variant="contained"
              >
                Next
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperJournalEntryPage);
