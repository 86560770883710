import {
  GET,
  PUT,
  DELETE,
  POST,
} from 'constants/methods';

import { REACT_APP_API_PREFIX } from 'config/env';

const service = 'account';
const rules = `${REACT_APP_API_PREFIX}/${service}/rules`;

export function getRules(filters) {
  const url = filters ? `${rules}${filters}` : `${rules}`;
  return {
    service,
    method: GET,
    url,
  };
}

export function getRuleById(id) {
  const url = `${rules}/${id}`;
  return {
    service,
    method: GET,
    url,
  };
}

export function updateRule(id, body) {
  const url = `${rules}/${id}`;
  return {
    service,
    method: PUT,
    url,
    body,
  };
}

export function deleteRule(id) {
  const url = `${rules}/${id}`;
  return {
    service,
    method: DELETE,
    url,
  };
}

export function createRule(body) {
  const url = `${rules}`;
  return {
    service,
    method: POST,
    url,
    body,
  };
}
