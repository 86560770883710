import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Portal from "@material-ui/core/Portal";
import Select from "@material-ui/core/Select";
import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
// material-ui
import Typography from "@material-ui/core/Typography";
// custom
import App from "components/App";
import FormOrganization from "components/FormOrganization";
import FormPersonalInfo from "components/FormPersonalInfo";
// custom
import FormResetPassword from "components/FormResetPassword";
import OrganizationDetail from "components/OrganizationDetail";
import OrganizationsList from "components/OrganizationsList";
import Page from "components/Page";
import PropTypes from "prop-types";
import React from "react";
// styles
import styles from "./styles";

class WrapperMyAccountPage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    user: PropTypes.object,
    updateUser: PropTypes.func,
    refresh: PropTypes.func,
    updateUserPassword: PropTypes.func,

    organizations: PropTypes.array,
    createOrganization: PropTypes.func,
    deleteOrganization: PropTypes.func,
    updateOrganization: PropTypes.func,
    getOrganizationUsers: PropTypes.func,
    deleteOrganizationUser: PropTypes.func,
    addUserToOrganization: PropTypes.func,
    joinOrganization: PropTypes.func,

    userApps: PropTypes.array,
  };

  static contextTypes = {
    MessageCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { MessageCenter } = this.context;
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
    };
    MessageCenter.close("right");
  }

  getStepContent() {
    const {
      updateUserPassword,
      updateUser,
      user,
      classes,
      refresh,
      userApps,
      createOrganization,
      deleteOrganization,
      updateOrganization,
      getOrganizationUsers,
      deleteOrganizationUser,
      addUserToOrganization,
      joinOrganization,
      organizations,
      history,
    } = this.props;

    const { index, formOrganizationOpen, selectedOrganization } = this.state;

    const { MessageCenter } = this.context;

    switch (index) {
      case 0:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography display="block" variant="h6" gutterBottom noWrap>
                Your Personal Data
              </Typography>
              <Typography display="block" variant="body2">
                You can here update your personal information such as your first
                and last name, your email and change your password
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormPersonalInfo
                    refresh={refresh}
                    user={user}
                    updateUser={updateUser}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormResetPassword
                    user={user}
                    updateUserPassword={updateUserPassword}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Paper className={classes.padding}>
                    <Typography display="block" variant="h6" gutterBottom>
                      Default App
                    </Typography>
                    <Typography display="block" variant="caption" gutterBottom>
                      Automaticaly launch this app after a login
                    </Typography>
                    <FormControl className={classes.formControl} fullWidth>
                      <InputLabel htmlFor="default-app">Default App</InputLabel>
                      <Select
                        inputProps={{
                          name: "Default App<",
                          id: "default-app",
                        }}
                        value={user.defaultAppID}
                        onChange={async (e) => {
                          await updateUser(user.id, {
                            defaultAppID: e.target.value,
                          });
                          refresh();
                        }}
                      >
                        {userApps.map((a) => (
                          <MenuItem key={`app_${a.id}`} value={a.id}>
                            <Chip
                              avatar={
                                <Avatar style={{ background: "#424242" }}>
                                  <App {...a} size={20} />
                                </Avatar>
                              }
                              key={`chipApp_${a.id}`}
                              className={classes.chip}
                              style={{
                                color: "white",
                                background: "#424242",
                              }}
                              label={`${a.name}`}
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper className={classes.padding}>
                    <Typography display="block" variant="h6" gutterBottom>
                      Other
                    </Typography>
                    <Grid container>
                      <Typography display="block" variant="body2">
                        Uuid
                      </Typography>
                      <Grid item xs={12}>
                        <Typography
                          display="block"
                          variant="caption"
                          gutterBottom
                        >
                          {user.uuid}
                        </Typography>
                      </Grid>
                      <Typography display="block" variant="body2">
                        Created At
                      </Typography>
                      <Grid item xs={12}>
                        <Typography
                          display="block"
                          variant="caption"
                          gutterBottom
                        >
                          {user.createdAt}
                        </Typography>
                      </Grid>
                      <Typography display="block" variant="body2">
                        Updated At
                      </Typography>
                      <Grid item xs={12}>
                        <Typography
                          display="block"
                          variant="caption"
                          gutterBottom
                        >
                          {user.updatedAt}
                        </Typography>
                      </Grid>
                      <Typography display="block" variant="body2">
                        Id
                      </Typography>
                      <Grid item xs={12}>
                        <Typography
                          display="block"
                          variant="caption"
                          gutterBottom
                        >
                          {user.id}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper className={classes.padding}>
                    <Typography display="block" variant="h6" gutterBottom>
                      Services & Roles
                    </Typography>
                    {Object.keys(user.services).map((k) => (
                      <div key={`service_${k}`}>
                        <Typography display="block" variant="body2">
                          {k}
                        </Typography>
                        {user.services[k].map((r) => (
                          <Chip
                            key={`role_${k}_${r}`}
                            label={r}
                            className={classes.chip}
                          />
                        ))}
                      </div>
                    ))}
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      case 1:
        return (
          <div>
            <OrganizationsList
              user={user}
              joinOrganization={joinOrganization}
              refresh={refresh}
              selectedOrganization={this.state.selectedOrganization}
              organizations={organizations || []}
              onSelect={(so) => {
                this.setState({
                  selectedOrganization: so,
                });
                MessageCenter.open("right");
              }}
            />
            <FormOrganization
              open={formOrganizationOpen}
              close={() => {
                this.setState({
                  formOrganizationOpen: false,
                });
                setTimeout(
                  () =>
                    this.setState({
                      selectedOrganization: undefined,
                    }),
                  200
                );
              }}
              createOrganization={createOrganization}
              deleteOrganization={deleteOrganization}
              updateOrganization={updateOrganization}
              refresh={refresh}
            />
            <Portal container={MessageCenter.getPortalContainer("right")}>
              {selectedOrganization &&
              selectedOrganization.ownerID === user.id ? (
                <ClickAwayListener
                  onClickAway={() => console.log("onClickAway")}
                >
                  <OrganizationDetail
                    user={user}
                    organization={selectedOrganization}
                    deleteOrganizationUser={deleteOrganizationUser}
                    updateOrganization={updateOrganization}
                    deleteOrganization={deleteOrganization}
                    getOrganizationUsers={getOrganizationUsers}
                    addUserToOrganization={addUserToOrganization}
                    joinOrganization={joinOrganization}
                    refresh={refresh}
                    history={history}
                    close={() => {
                      this.setState({ selectedOrganization: undefined });
                      MessageCenter.close("right");
                    }}
                    refreshKey={
                      selectedOrganization ? selectedOrganization.id : undefined
                    }
                  />
                </ClickAwayListener>
              ) : (
                []
              )}
            </Portal>
          </div>
        );
      default:
        return (
          <Typography display="block" variant="h6">
            You have gone too far, Sonny Jim !
          </Typography>
        );
    }
  }

  render() {
    const { classes, history } = this.props;

    const { index } = this.state;

    return (
      <Page
        helmet="My Account"
        loadingMessage={"Loading Information"}
        tabs={
          <React.Fragment>
            <Tabs
              value={index}
              onChange={(e, v) => {
                this.setState({
                  index: v,
                });
                history.push(`?index=${v}`);
              }}
              indicatorColor="primary"
              textColor="primary"
              scrollable
              scrollButtons="auto"
              classes={{
                root: classes.tabsRoot,
                indicator: classes.tabsIndicator,
              }}
            >
              <Tab
                label="My Account"
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
              />
              <Tab
                label="Organizations"
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
              />
            </Tabs>
          </React.Fragment>
        }
      >
        {this.getStepContent()}
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperMyAccountPage);
