import { REACT_APP_API_PREFIX } from "config/env";
import { DELETE, GET, POST, PUT } from "constants/methods";

const service = "ap-dashboard";
const vendorLineItems = `${REACT_APP_API_PREFIX}/${service}/vendorLineItems`;

export function getVendorLineItems(filters) {
  const url = `${vendorLineItems}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getVendorLineItemById(id) {
  const url = `${vendorLineItems}/${id}/full`;
  return {
    method: GET,
    url,
  };
}

export function updateVendorLineItem(id, body) {
  const url = `${vendorLineItems}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteVendorLineItem(id) {
  const url = `${vendorLineItems}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createVendorLineItem(body) {
  const url = `${vendorLineItems}`;
  return {
    method: POST,
    url,
    body,
  };
}
