import {
  GET,
} from 'constants/methods';

import { REACT_APP_API_PREFIX } from 'config/env';

const service = 'ap-dashboard';
const reports = `${REACT_APP_API_PREFIX}/${service}/accountReports.csv`;

export function getReports(filters) {
  const url = `${reports}${filters ? `?q=${encodeURI(JSON.stringify(filters))}`: ""}`;
  return {
    method: GET,
    url,
  };
}