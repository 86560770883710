import { combineReducers } from "redux"; //eslint-disable-line
import app from "./app";
import companies from "./companies";
import errors from "./errors";
import exports from "./exports";
import glAccounts from "./glAccounts";
import itemClasses from "./itemClasses";
import myPayables from "./myPayables";
import payables from "./payables";
import priorityLevels from "./priorityLevels";
import searchPresets from "./searchPresets";
import user from "./user";
import userApps from "./userApps";
import users from "./users";
import vendors from "./vendors";
import journalEntries from "./journalEntries";
import reports from "./reports";
export default combineReducers({
  errors,
  user,
  searchPresets,
  users,
  app,
  userApps,
  payables,
  myPayables,
  vendors,
  glAccounts,
  itemClasses,
  companies,
  exports,
  priorityLevels,
  journalEntries,
  reports,
});
