export const serviceRoles = {
  account: {
    name: 'account',
    roles: {
      any: 'any',
      anon: 'anon',
      user: 'user',
      admin: 'admin',
      superadmin: 'superadmin',
    },
  },
  apDashboard: {
    name: 'ap-dashboard',
    roles: {
      any: 'any',
      anon: 'anon',
      user: 'user',
      accounting_manager: 'accounting_manager',
      admin: 'admin',
      superadmin: 'superadmin',
    },
  },
};
