import {
  GET,
  PUT,
  DELETE,
  POST,
} from 'constants/methods';

import { REACT_APP_API_PREFIX } from 'config/env';

const service = 'ap-dashboard';
const itemClasses = `${REACT_APP_API_PREFIX}/${service}/itemClasses`;

export function getItemClasses() {
  const url = `${itemClasses}`;
  return {
    method: GET,
    url,
  };
}

export function getItemClasseById(id) {
  const url = `${itemClasses}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateItemClasse(id, body) {
  const url = `${itemClasses}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteItemClasse(id) {
  const url = `${itemClasses}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createItemClasse(body) {
  const url = `${itemClasses}`;
  return {
    method: POST,
    url,
    body,
  };
}
