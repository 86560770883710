export default theme => ({
  background: {
    background: theme.palette.background.default,
  },
  chip: {
    marginRight: 5, marginTop: 5,
  },
  alignRight: {
    textAlign: 'right',
  },
  padding: {
    padding: 20,
  },
  scrollButtons: {
    color: theme.palette.type === 'dark' ? 'white' : 'black',
  },
  tabs: {
    background: 'white',
  },
  tabsIndicator: {
    backgroundColor: theme.palette.text,
  },
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  tabsRoot: {
    background: theme.palette.background.paper,
  },
  floatingActionButton: {
    position: 'fixed',
    right: 20,
    bottom: 20,
  },
  tabRoot: {
    textTransform: 'initial',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing.unit * 4,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: theme.palette.text,
      opacity: 1,
    },
    '&$tabSelected': {
      color: theme.palette.text,
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: theme.palette.text,
    },
  },
  tabSelected: {},
  typography: {
    padding: theme.spacing.unit * 3,
  },
});
