import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// component
import WrapperJournalEntryPage from '../component/WrapperJournalEntryPage';

// helpers
import { getJsonFromUrl } from 'helpers';

// actions
import * as Actions from 'actions';
import { getJournalEntries, getVendors } from 'api';

const mapStateToProps = state => ({
  journalEntries: state.journalEntries,
  companies: state.companies,
  glAccounts: state.glAccounts,
  itemClasses: state.itemClasses,
  vendors: state.vendors,
});

const mapDispatchToProps = dispatch => ({
  getJournalEntries: (...args) => dispatch(Actions.getJournalEntries(...args)),
  getJournalEntryByID: (...args) => dispatch(Actions.getJournalEntryByID(...args)),
  createJournalEntry: (...args) => dispatch(Actions.createJournalEntry(...args)),
  deleteJournalEntry: (...args) => dispatch(Actions.deleteJournalEntry(...args)),
  updateJournalEntry: (...args) => dispatch(Actions.updateJournalEntry(...args)),
  lockJournalEntry: (...args) => dispatch(Actions.lockJournalEntry(...args)),
  createExport: (...args) => dispatch(Actions.createExport(...args)),
  getCompanies: (...args) => dispatch(Actions.getCompanies(...args)),
  getGLAccounts: (...args) => dispatch(Actions.getGlAccounts(...args)),
  getUsers: (...args) => dispatch(Actions.getUsers(...args)),
  getItemClasses: (...args) => dispatch(Actions.getItemClasses(...args)),
  getVendors: (...args) => dispatch(Actions.getVendors(...args)),
  createJournalEntryItem: (...args) => dispatch(Actions.createJournalEntryItem(...args)),
  deleteJournalEntryItem: (...args) => dispatch(Actions.deleteJournalEntryItem(...args)),
  updateJournalEntryItem: (...args) => dispatch(Actions.updateJournalEntryItem(...args)),
  uploadJournalEntryFile: (...args) => dispatch(Actions.uploadJournalEntryFile(...args)),
});
class JournalEntryPage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    journalEntries: PropTypes.array,
    getJournalEntries: PropTypes.func,
    getJournalEntryByID: PropTypes.func,
    createJournalEntry: PropTypes.func,
    deleteJournalEntry: PropTypes.func,
    updateJournalEntry: PropTypes.func,
    lockJournalEntry: PropTypes.func,
    createExport: PropTypes.func,
    createJournalEntryItem: PropTypes.func,
    updateJournalEntryItem: PropTypes.func,
    deleteJournalEntryItem: PropTypes.func,
    uploadJournalEntryFile: PropTypes.func,

    getCompanies: PropTypes.func,
    getGLAccounts: PropTypes.func,
    getItemClasses: PropTypes.func,
    companies: PropTypes.array,
    glAccounts: PropTypes.array,
    itemClasses: PropTypes.array,
    vendors: PropTypes.array,
    users: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { location, journalEntries, getCompanies, getGLAccounts, getItemClasses, getVendors } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: journalEntries.length === 0,
    };

    getCompanies();
    getGLAccounts();
    getItemClasses();
    getVendors();
  }

  componentDidMount() {
    this.refresh();
  }

  async refresh() {
    const { getJournalEntries } = this.props;
    const urlParams = getJsonFromUrl(window.location);
    const promises = [getJournalEntries(urlParams.filters ? JSON.parse(urlParams.filters) : [])];
    await Promise.all(promises);

    this.setState({ loading: false });
  }

  render() {
    const {
      history,
      journalEntries,
      getJournalEntries,
      getJournalEntryByID,
      createJournalEntry,
      deleteJournalEntry,
      updateJournalEntry,
      lockJournalEntry,
      createExport,
      createJournalEntryItem,
      deleteJournalEntryItem,
      updateJournalEntryItem,
      uploadJournalEntryFile,
      companies,
      glAccounts,
      itemClasses,
      vendors,
      users,
    } = this.props;

    const {
      urlParams,
      loading,
    } = this.state;

    return (
      <WrapperJournalEntryPage
        history={history}
        refresh={this.refresh.bind(this)}
        loading={loading}
        journalEntries={journalEntries}
        getJournalEntryByID={getJournalEntryByID}
        getJournalEntries={getJournalEntries}
        createJournalEntry={createJournalEntry}
        deleteJournalEntry={deleteJournalEntry}
        updateJournalEntry={updateJournalEntry}
        lockJournalEntry={lockJournalEntry}
        createExport={createExport}
        createJournalEntryItem={createJournalEntryItem}
        deleteJournalEntryItem={deleteJournalEntryItem}
        updateJournalEntryItem={updateJournalEntryItem}
        uploadJournalEntryFile={uploadJournalEntryFile}
        companies={companies}
        glAccounts={glAccounts}
        itemClasses={itemClasses}
        vendors={vendors}
        users={users}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(JournalEntryPage);
