import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import MomentUtils from "@date-io/moment";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import SaveIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import LockIcon from "@material-ui/icons/Lock";
import DeleteIcon from "@material-ui/icons/Delete";
import PropTypes from "prop-types";
import React, { Component } from "react";
import moment from "moment";
import { reduceToChanges } from "helpers";
// styles
import styles from "./styles";
import { Chip, Typography } from "@material-ui/core";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import JournalEntryItem from "hooks/JournalEntryItem";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFileTwoTone";
// import File from "components/File";

import NumberFormat from "react-number-format";
import AddAttachment from "hooks/AddAttachment";
import { REACT_APP_API, REACT_APP_API_PREFIX } from "config";

import {
  Tooltip,
} from "@material-ui/core";


function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const File = ({
  file,
  onDelete,
  index,
  disabled,
  selected,
  onSelect,
  NotificationCenter,
}) => (
  <Grid item>
    <Tooltip title={file.name}>
    <div
      style={{
        display: "flex",
        padding: 5,
        border: selected
          ? "solid 1px #2196f3"
          : "solid 1px rgba(155,155,155,0.3)",
        borderRadius: 4,
        margin: 5,
        alignItems: "center",
        cursor: "pointer",
      }}
      onClick={() => onSelect(file)}
    >
      <InsertDriveFileIcon
        style={{ color: "#616161", fontSize: 40, marginLeft: 4 }}
      />
      <div>
        <Typography display="block" variant="body2">
          File {index+1}
        </Typography>
      </div>
    </div>
    </Tooltip>
    {!disabled ? (
      <Typography
        variant="caption"
        color="textSecondary"
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          marginLeft: 5,
          marginTop: -4,
          fontSize: 10,
        }}
        onClick={() => {
          NotificationCenter.sweetAlert(
            {
              title: `Are you sure ?`,
              subtitle:
                "You are about to remove an attachment. Please confirm.",
              timestamp: new Date().getTime(),
              error: true,
            },
            {
              cancel: {
                label: "Cancel",
                level: "default",
                callback: () => console.log("Callback"),
              },
              confirm: {
                label: "Delete",
                level: "error",
                callback: () => onDelete(file, index),
              },
            }
          );
        }}
      >
        <u>Delete File</u>
      </Typography>
    ) : (
      []
    )}
  </Grid>
);

class FormJournalEntry extends Component {
  static propTypes = {
    classes: PropTypes.object,
    fullScreen: PropTypes.bool,

    close: PropTypes.func,
    open: PropTypes.bool,

    journalEntry: PropTypes.object,
    getJournalEntryByID: PropTypes.func,
    createJournalEntry: PropTypes.func,
    updateJournalEntry: PropTypes.func,
    deleteJournalEntry: PropTypes.func,
    createJournalEntryItem: PropTypes.func,
    updateJournalEntryItem: PropTypes.func,
    deleteJournalEntryItem: PropTypes.func,
    companies: PropTypes.array,
    glAccounts: PropTypes.array,
    itemClasses: PropTypes.array,
    vendors: PropTypes.array,
    journalEntryItems: PropTypes.array,
    refresh: PropTypes.func,
    id: PropTypes.number,
    theme: PropTypes.object,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      loading: false,
      referenceNumber: "",
      companyID: "",
      id: "",
      files: [],
      date: "",
      locked: false,
      original: undefined,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.init(nextProps.journalEntry);
    }
  }

  handleChange = (name) => (event) => {
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  init(journalEntry) {
    this.setState({
      loading: false,
      referenceNumber: journalEntry ? journalEntry.referenceNumber : "",
      companyID:
        journalEntry && journalEntry.companyID > 0 ? journalEntry.companyID : "",
      date: journalEntry ? journalEntry.date : moment(new Date()).utc() ,
      journalEntryItems: journalEntry ? journalEntry.journalEntryItems : [{ tmpID: new Date().getTime() }, { tmpID: new Date().getTime()+1 }],
      id: journalEntry ? journalEntry.id : "",
      files: journalEntry && journalEntry.files ? journalEntry.files : [],
      locked: journalEntry ? journalEntry.locked : false,
      original: journalEntry,
    });
  }

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle:
          "If you delete this journalEntry, you won't be able to recover it.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async addJournalEntryItem() {
    const { createJournalEntryItem, role } = this.props;
    const { journalEntryItems, id } = this.state;
    const lastLineItem = journalEntryItems[journalEntryItems.length - 1];
    const body = { tmpID: new Date().getTime() };
    if (lastLineItem) {
      body.glAccountID = lastLineItem.glAccountID;
      body.classID = lastLineItem.classID;
    }
    if (id) {
      await createJournalEntryItem(id, body);
      this.refresh();
    } else {
      const _lineItems = [...journalEntryItems];
      _lineItems.push(body);
      this.setState({ journalEntryItems: _lineItems });
    }
  }

  async updateJournalEntryItem(lineItem, index) {
    const { updateJournalEntryItem, payable } = this.props;
    const { journalEntryItems, id } = this.state;
    if (id) {
      await updateJournalEntryItem(lineItem.id, lineItem);
      this.refresh();
    } else {
      const _lineItems = [...journalEntryItems];
      _lineItems[index] = lineItem;
      this.setState({ journalEntryItems: _lineItems });
    }
  }

  async deleteJournalEntryItem(lineItem, index) {
    const { deleteJournalEntryItem } = this.props;
    const { journalEntryItems } = this.state;
    if (lineItem.id) {
      await deleteJournalEntryItem(lineItem.id);
      this.refresh();
    } else {
      const _lineItems = [...journalEntryItems];
      _lineItems.splice(index, 1);
      this.setState({ journalEntryItems: _lineItems });
    }
  }

  async confirmDelete() {
    const { deleteJournalEntry, close, refresh, journalEntry } = this.props;

    const { NotificationCenter } = this.context;
    close();
    const resp = await deleteJournalEntry(journalEntry.id);
    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: "JournalEntry has been deleted.",
        success: true,
        timestamp: new Date().getTime(),
      });
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  getBody() {
    const {
      referenceNumber,
      companyID,
      date,
    } = this.state;

    let body = {
      referenceNumber,
      companyID,
      date,
    };

    if (this.state.original) {
      body = reduceToChanges(body, this.state.original);
    }

    return body;
  }

  async save() {
    const {
      updateJournalEntry,
      createJournalEntry,
      journalEntry,
      refresh,
      close,
      api,
    } = this.props;

    const { referenceNumber, companyID, date, journalEntryItems, files} = this.state;

    const { NotificationCenter } = this.context;

    let resp;
    this.setState({ loading: true });
    if (journalEntry) {
      resp = await updateJournalEntry(journalEntry.id, this.getBody());
    } else {
      resp = await createJournalEntry({
        referenceNumber,
        companyID,
        date,
        journalEntryItems,
      });
      if (resp.success) {
        const journal = resp.payload;
        const journalEntryID = journal.id;
        await new Promise(async (r) => {
          const funcs = [];
          for (const k in files) {
            if (files.hasOwnProperty(k)) {
              const file = files[k];
              funcs.push(api.uploadJournalEntryFile(journalEntryID, file));
            }
          }
          await Promise.all(funcs);
          r();
        });
      }
    }

    this.setState({ loading: false });

    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: journalEntry
          ? "JournalEntry has been updated."
          : "JournalEntry has been created.",
        success: true,
        timestamp: new Date().getTime(),
      });

      close();
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  async lockJournalEntry() {
    const {
      lockJournalEntry,
      journalEntry,
      refresh,
      close,
    } = this.props;

    const { NotificationCenter } = this.context;

    let resp;
    this.setState({ loading: true });
    if (journalEntry) {
      resp = await lockJournalEntry(journalEntry.id);
    }

    this.setState({ loading: false });

    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: "Journal Entry has been locked.",
        success: true,
        timestamp: new Date().getTime(),
      });

      close();
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  async refresh(id) {
    console.log("props", this.props)
    const { getJournalEntryByID } = this.props;
    const r = await getJournalEntryByID(id || this.state.id);
    if (r.success) {
      this.init(r.payload);
    }
  }


  async onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    const m = reorder(
      this.state.journalEntryItems,
      result.source.index,
      result.destination.index
    );
    this.setState({
      journalEntryItems: m,
    });
  }

  async userDidDrop(files) {
    const { journalEntry, api } = this.props;
    const { id } = this.state;
    if (journalEntry) {
      this.setState({ backdropOpen: true });
      await new Promise(async (r) => {
        const funcs = [];
        for (const k in files) {
          if (files.hasOwnProperty(k)) {
            const file = files[k];
            funcs.push(api.uploadJournalEntryFile(id, file));
          }
        }
        const resp = await Promise.all(funcs);
        r();
        this.refresh();
      });
      this.setState({ backdropOpen: false });
    } else {
      this.setState((prevState) => ({
        files: [...prevState.files, ...files],
      }));
    }
  }

  render() {
    const {
      classes,
      fullScreen,
      open,
      journalEntry,
      close,
      companies,
      glAccounts,
      itemClasses,
      vendors,
      api,
      theme,
    } = this.props;

    const { referenceNumber, date, companyID, loading, journalEntryItems, files, id, locked } = this.state;
    const {NotificationCenter} = this.context;

    let debits = 0;
    let credits = 0;
    const amount = 0;

    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });

    for (const key in journalEntryItems) {
      if (journalEntryItems.hasOwnProperty(key)) {
        const element = journalEntryItems[key];
        if (element.debit) {
          debits += element.debit;
        } else if (element.credit) {
          credits += element.credit;
        }
      }
    }

    const fmtFile = (name) => {

      if (name.length < 12) {
        return name;
      }
      return name.substring(0, 8) + ".." + name.substring(name.lastIndexOf("."));
    }

    const journalForm = function() {
      return (
        <div>
          <DialogTitle id="responsive-dialog-title">
            {journalEntry
              ? `Journal Entry #${journalEntry.id}`
              : "New Journal Entry"}
          </DialogTitle>
          <DialogContent>
            {/* <DialogContentText>
                {journalEntry
                  ? "To update the Journal Entry, fill the form below :"
                  : "To create a new ournalEntry, fill the form below :"}
              </DialogContentText> */}
            <MuiPickersUtilsProvider utils={MomentUtils}>

              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <FormControl className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="group-simple">Company</InputLabel>
                    <Select
                      inputProps={{
                        name: "Vendor",
                        id: "Vendor",
                      }}
                      onChange={this.handleChange("companyID")}
                      value={companyID}
                      disabled={locked}
                    >
                      {companies &&
                        companies.map((c) => (
                          <MenuItem key={`company_${c.id}`} value={c.id}>
                            {c.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <KeyboardDatePicker
                    value={moment(date).utc()}
                    fullWidth
                    disabled={locked}
                    autoOk
                    onChange={(e) => {
                      this.setState({
                        date: e.utc(),
                      });
                    }}
                    disableToolbar
                    inputProps={{
                      size: "small",
                    }}
                    keyboardIcon={
                      <CalendarTodayIcon
                        style={{
                          fontSize: 20,
                          color: "black",
                        }}
                      />
                    }
                    variant="inline"
                    format="MM/DD/YYYY"
                    id="date-picker-inline"
                    label="Date"
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    id="referenceNumber"
                    label="Entry No."
                    disabled={locked}
                    className={classes.textField}
                    value={referenceNumber}
                    onChange={this.handleChange("referenceNumber")}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="caption">
                    Files
                  </Typography>
                  <Grid container>
                    <div style={{margin: 4}}>
                    <AddAttachment
                      userDidDrop={this.userDidDrop.bind(this)}
                        disabled={locked}
                    />
                    </div>

                      {files &&
                        files.map((f, i) => (
                          <Tooltip key={i} title={f.name}>
                            <Chip
                              style={{margin: 4}}
                              key={f.name}
                              label={fmtFile(f.name)}
                              onClick={()=>{
                                const url = `${REACT_APP_API}${REACT_APP_API_PREFIX}/ap-dashboard/journalEntries/${f.modelID}/download/${f.id}?inline=1`;
                                window.open(url, '_blank').focus();
                              }}
                              onDelete={() => { }}
                            />
                            </Tooltip>
                        ))}

                  </Grid>
                </Grid>

              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" style={{ fontWeight: 700 }}>
                  Line Items
                </Typography>
                <Typography color="textSecondary" variant="caption">
                  <b>CTRL+SPACE</b> to add new line item
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", marginRight: (locked ? 0 : 25) }}
                  >
                    <Typography
                      variant="h6"
                      color="secondary"
                      style={{ flex: 3, paddingLeft: 4, background: theme.palette.primary.color[300], margin: 2 }}
                    >
                      GL Account
                    </Typography>
                    <Typography
                      variant="h6"
                      color="secondary"
                      style={{ flex: 1, maxWidth: 150, paddingLeft: 4, background: theme.palette.primary.color[300], margin: 2 }}
                    >
                      Debit
                    </Typography>
                    <Typography
                      variant="h6"
                      color="secondary"
                      style={{ flex: 1, maxWidth: 150, paddingLeft: 4, background: theme.palette.primary.color[300], margin: 2 }}
                    >
                      Credit
                    </Typography>
                    <Typography
                      variant="h6"
                      color="secondary"
                      style={{ flex: 2, paddingLeft: 4, background: theme.palette.primary.color[300], margin: 2 }}
                    >
                      Class
                    </Typography>
                    <Typography
                      variant="h6"
                      color="secondary"
                      style={{ flex: 2, paddingLeft: 4, background: theme.palette.primary.color[300], margin: 2 }}
                    >
                      Name
                    </Typography>
                    <Typography
                      variant="h6"
                      color="secondary"
                      style={{ flex: 1, paddingLeft: 4, background: theme.palette.primary.color[300], margin: 2 }}
                    >
                      Memo
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <DragDropContext
                    onDragEnd={this.onDragEnd.bind(this)}
                  >
                    <Droppable droppableId="droppable">
                      {(provided, snapshot) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {journalEntryItems &&
                            journalEntryItems.map((li, index) => (
                              <Draggable
                                isDragDisabled={li.tmpID === undefined}
                                key={li.tmpID || li.id}
                                draggableId={
                                  `${li.tmpID}` || `${li.id}`
                                }
                                index={index}
                              >
                                {(p, s) => (
                                  <div
                                    ref={p.innerRef}
                                    {...p.draggableProps}
                                    {...p.dragHandleProps}
                                  >
                                    <JournalEntryItem
                                      dragHandle={false}
                                      journalEntryItem={li}
                                      useTable={false}
                                      index={Number(index)}
                                      companyID={companyID}
                                      glAccounts={glAccounts}
                                      disabled={locked}
                                      // company={company}
                                      itemClasses={itemClasses}
                                      vendors={vendors}
                                      update={this.updateJournalEntryItem.bind(
                                        this
                                      )}
                                      onDelete={this.deleteJournalEntryItem.bind(
                                        this
                                      )}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            ))}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                  {/* <Table>
                        <TableHead>
                          <TableRow >
                            <TableCell style={{ padding: "0px 4px 0px 0px", width: "40%" }}>
                              <Typography
                              variant="h6"
                              color="secondary"
                                style={{ paddingLeft: 4, margin: 2 }}
                              >
                                Account
                              </Typography>
                            </TableCell>
                            <TableCell style={{ padding: "0px 4px 0px 0px",width:"10%"}}>
                              <Typography
                                variant="h6"
                                color="secondary"
                                style={{ paddingLeft: 4, margin: 2 }}
                              >
                                Debit
                              </Typography>
                            </TableCell>
                            <TableCell style={{ padding: "0px 4px 0px 0px",width: "10%" }}>
                              <Typography
                                variant="h6"
                                color="secondary"
                                style={{ paddingLeft: 4, margin: 2 }}
                              >
                                Credit
                              </Typography>
                            </TableCell>
                            <TableCell style={{ padding: "0px 4px 0px 0px",width: "20%" }}>
                              <Typography
                                variant="h6"
                                color="secondary"
                                style={{ paddingLeft: 4, margin: 2 }}
                              >
                                Class
                              </Typography>
                            </TableCell>
                            <TableCell style={{ padding: "0px 4px 0px 0px",width: "20%" }}>
                              <Typography
                                variant="h6"
                                color="secondary"
                                style={{ paddingLeft: 4, margin: 2 }}
                              >
                                Memo
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {(journalEntryItems && journalEntryItems.length ? journalEntryItems : []).map((li, index) => (
                            <JournalEntryItem
                              dragHandle={
                                li.tmpID !== undefined
                              }
                              journalEntryItem={li}
                              useTable={true}

                              index={Number(index)}
                              companyID={companyID}
                              glAccounts={glAccounts}
                              // company={company}
                              itemClasses={itemClasses}
                              update={me.updateJournalEntryItem.bind(
                                this
                              )}
                              onDelete={me.deleteJournalEntryItem.bind(
                                this
                              )}
                            />
                          ))}
                        </TableBody>
                      </Table> */}
                  {journalEntryItems && journalEntryItems.length ? (
                    <div
                      style={{ textAlign: "right", paddingRight: 40 }}
                    >
                      <Typography variant="caption" display="block">
                        Debits: {formatter.format(debits)}
                      </Typography>

                      <Typography variant="caption" display="block">
                        Credits: {formatter.format(credits)}
                      </Typography>

                    </div>
                  ) : (
                    []
                  )}
                  {<Chip
                    style={{ marginTop: 4 }}
                    label="+ Line Item"
                    size="small"
                    variant="outlined"
                    disabled={locked}
                    onClick={this.addJournalEntryItem.bind(this)}
                  />}
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            {journalEntry && (
              <Button
                className={classes.delete}
                onClick={this.delete.bind(this)}
                disabled={locked}
              >
                <DeleteIcon className={classes.leftIcon} />
                &nbsp;Delete
              </Button>
            )}
            <Button onClick={close}>
              <CloseIcon className={classes.leftIcon} />
              &nbsp;Close
            </Button>
            {journalEntry && (<Button onClick={this.lockJournalEntry.bind(this)} disabled={locked}>
              <LockIcon className={classes.leftIcon} />
              &nbsp;Lock
            </Button>
            )}
            <Button color="primary" autoFocus onClick={this.save.bind(this)} disabled={locked}>
              <SaveIcon className={classes.leftIcon} />
              {journalEntry ? "Save" : "Create"}
            </Button>
          </DialogActions>
        </div>
      )
    }.bind(this)

      return (
        <Dialog
          fullScreen={false}
          open={open}
          onClose={close}
          scroll="paper"
          maxWidth="xl"
          fullWidth
          aria-labelledby="responsive-dialog-title"
        >
        {loading ? (
          <div className={classes.progressContainer}>
            <DialogTitle id="alert-dialog-title">Loading</DialogTitle>
            <CircularProgress />
          </div>
        ) : (
          journalForm()
        )}
      </Dialog>)
  }
}

export default withStyles(styles, { withTheme: true })(withMobileDialog()(FormJournalEntry));
