import Hidden from "@material-ui/core/Hidden";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
// config
import { COPYRIGHT } from "config";
import PropTypes from "prop-types";
import React from "react";
// styles
import styles from "./styles";

class Copyright extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.container}>
        <Hidden smDown>
          <Typography
            display="block"
            variant="caption"
            className={classes.white}
          >
            {COPYRIGHT}
          </Typography>
        </Hidden>
      </div>
    );
  }
}

export default withStyles(styles)(Copyright);
