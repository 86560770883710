import MomentUtils from "@date-io/moment";
import {
  AppBar,
  Backdrop,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  InputAdornment,
  Popper,
  TextField,
  Toolbar,
  Typography,
  ButtonGroup,
  TextareaAutosize,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import BeenhereIcon from "@material-ui/icons/Beenhere";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import CloudUpload from "@material-ui/icons/CloudUpload";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import FlagIcon from "@material-ui/icons/Flag";
import InfoIcon from "@material-ui/icons/Info";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFileTwoTone";
import LockIcon from "@material-ui/icons/Lock";
import { Alert, Autocomplete, Rating } from "@material-ui/lab";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
// config
import { REACT_APP_API, REACT_APP_API_PREFIX } from "config";
import { getJsonFromUrl, reduceToChanges, removeURLParameter } from "helpers";
import AddAttachment from "hooks/AddAttachment";
import LineItem from "hooks/LineItem";
// material-ui
import hotkeys from "hotkeys-js";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import NumberFormat from "react-number-format";
import { Bar, Container, Section } from "react-simple-resizer";
// styles
import styles from "./styles";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getLabel = (id, array, key, debug) => {
  if (array && id && key) {
    const match = array.find((i) => i.id === id);
    if (match) return match[key];
  }
  return "";
};

const PopperMy = function (props) {
  return <Popper {...props} style={{ width: 400 }} placement="bottom-start" />;
};

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

const initState = {
  amount: "",
  approved: false,
  approvedDate: "",
  dualApproved: false,
  dualApprovedDate: "",
  assignedToUUID: "",
  companyID: "",
  lockable: false,
  lockedDate: undefined,
  locked: false,
  memo: "",
  referenceNumber: "",
  vendorID: 0,
  lineItems: [],
  init: false,
  vendor: undefined,
  company: undefined,
  original: undefined,
  vendorInput: "",
  companyInput: "",
  assign: undefined,
  assignInput: "",
  dualAssign: undefined,
  dualAssignInput: "",
  dualAssignedToUUID: "",
  date: moment().utc().format(),
  dueDate: moment().utc().format(),
  referenceNumber: "",
  files: [],
  selectedFile: undefined,
  createdBy: undefined,
  backdropOpen: false,
  id: undefined,
  exportID: undefined,
  priorityLevelID: 0,
  transactionType: 0,
  glAccount: undefined,
  glAccountID: 0,
  glAccountInput: "",
  receivablesAccount: undefined,
  batchLineItemsOpen: false,
  newLineItems: "",
};

const File = ({
  file,
  onDelete,
  index,
  disabled,
  selected,
  onSelect,
  NotificationCenter,
}) => (
  <Grid item>
    <div
      style={{
        display: "flex",
        padding: 5,
        border: selected
          ? "solid 1px #2196f3"
          : "solid 1px rgba(155,155,155,0.3)",
        borderRadius: 4,
        margin: 5,
        alignItems: "center",
        cursor: "pointer",
      }}
      onClick={() => onSelect(file)}
    >
      <InsertDriveFileIcon
        style={{ color: "#616161", fontSize: 40, marginLeft: 4 }}
      />
      <div>
        <Typography display="block" variant="body2">
          {file.name}
        </Typography>
      </div>
    </div>
    {!disabled ? (
      <Typography
        variant="caption"
        color="textSecondary"
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          marginLeft: 5,
          marginTop: -4,
          fontSize: 10,
        }}
        onClick={() => {
          NotificationCenter.sweetAlert(
            {
              title: `Are you sure ?`,
              subtitle:
                "You are about to remove an attachment. Please confirm.",
              timestamp: new Date().getTime(),
              error: true,
            },
            {
              cancel: {
                label: "Cancel",
                level: "default",
                callback: () => console.log("Callback"),
              },
              confirm: {
                label: "Delete",
                level: "error",
                callback: () => onDelete(file, index),
              },
            }
          );
        }}
      >
        <u>Delete File</u>
      </Typography>
    ) : (
      []
    )}
  </Grid>
);

class Payable extends Component {
  static propTypes = {
    classes: PropTypes.object,
    payable: PropTypes.object,
    close: PropTypes.func,
    open: PropTypes.bool,
    companies: PropTypes.array,
    itemClasses: PropTypes.array,
    vendor: PropTypes.array,
    priorityLevels: PropTypes.array,
    refresh: PropTypes.func,
    role: PropTypes.string,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = initState;
    hotkeys("ctrl+space", (e) => {
      e.stopPropagation();
      e.preventDefault();
      this.addLineItem();
    });
    hotkeys.filter = () => true;

    const urlParams = getJsonFromUrl(window.location);
    if (urlParams.id && !this.props.payable) {
      this.state.loading = true;
      this.refresh(urlParams.id);
    }
  }

  componentDidMount() {
    const { payable } = this.props;
    if (payable && !this.state.init) {
      this.init(payable);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      (nextProps.payable && this.props.payable === undefined) ||
      (this.props.payable &&
        nextProps.payable &&
        this.props.payable.id !== nextProps.payable.id)
    ) {
      this.init(nextProps.payable);
    }

    if (nextProps.open && !this.props.open) {
      const urlParams = getJsonFromUrl(window.location);
      if (urlParams.duplicateID) {
        nextProps.history.push({ search: removeURLParameter("duplicateID") });
        this.startFromDuplicate(urlParams.duplicateID);
      }
    }
  }

  async userDidDrop(files) {
    const { payable, api } = this.props;
    const { id } = this.state;
    if (id) {
      this.setState({ backdropOpen: true });
      await new Promise(async (r) => {
        const funcs = [];
        for (const k in files) {
          if (files.hasOwnProperty(k)) {
            const file = files[k];
            funcs.push(api.uploadPayableFile(id, file));
          }
        }
        const resp = await Promise.all(funcs);
        r();
        this.refresh();
      });
      this.setState({ backdropOpen: false });
    } else {
      this.setState((prevState) => ({
        files: [...prevState.files, ...files],
        selectedFile: files[0],
      }));
    }
  }

  init(payable) {
    const { companies, vendors, users, glAccounts } = this.props;
    const files = payable.files || [];
    if (payable) {
      const newState = {
        init: true,
        original: payable,
        ...payable,
        files,
        glAccount: glAccounts.find((g) => g.id === payable.glAccountID),
        company: companies.find((c) => c.id === payable.companyID),
        vendor: vendors.find((v) => v.id === payable.vendorID),
        assign: users.find((u) => u.uuid === payable.assignedToUUID),
        dualAssign: users.find((u) => u.uuid === payable.dualAssignedToUUID),
        loading: false,
      };
      if (newState.lineItems === null) newState.lineItems = [];
      this.setState(newState);
    }
  }

  handleChange = (name) => (event) => {
    //eslint-disable-line
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  async refresh(id) {
    const { api } = this.props;
    const r = await api.getPayableById(id || this.state.id);
    if (r.success) {
      this.init(r.payload);
    }
  }

  async startFromDuplicate(id) {
    const { api } = this.props;
    const r = await api.getPayableById(id);
    if (r.success) {
      let response = r.payload;
      let body = initState;
      body.lineItems = response.lineItems;
      body.dualAssignedToUUID = response.dualAssignedToUUID;
      body.priorityLevelID = response.priorityLevelID;
      body.companyID = response.companyID;
      body.companyName = response.companyName;
      body.memo = response.memo;
      body.amount = response.amount;
      body.vendorID = response.vendorID;
      body.vendorName = response.vendorName;
      body.companyInput = response.companyName;
      body.vendorInput = response.vendorName;
      body.dualAssignInput =
        `${response.dualAssignedTo?.firstName ?? ""}${
          response.dualAssignedTo?.lastName ?? ""
        }` || "";
      body.assignInput =
        `${response.assignedTo?.firstName}${response.assignedTo?.lastName}` ||
        "";

      let tmpID = new Date().getTime();
      for (const key in body.lineItems) {
        if (Object.hasOwnProperty.call(body.lineItems, key)) {
          const li = body.lineItems[key];
          li.tmpID = `${tmpID}`;
          tmpID += 1;
        }
      }
      this.init(body);
    }
  }

  async deleteLineItem(lineItem, index) {
    const { api } = this.props;
    const { lineItems } = this.state;
    if (lineItem.id) {
      await api.deletePayableLineItem(lineItem.payableID, lineItem.id);
      this.refresh();
    } else {
      const _lineItems = [...lineItems];
      _lineItems.splice(index, 1);
      this.setState({ lineItems: _lineItems });
    }
  }

  async addLineItem() {
    const { api, role } = this.props;
    const { lineItems, id } = this.state;
    if (role === "user") return;
    const lastLineItem = lineItems[lineItems.length - 1];
    const body = { tmpID: new Date().getTime() };
    if (lastLineItem) {
      body.glAccountID = lastLineItem.glAccountID;
      body.classID = lastLineItem.classID;
    }
    if (id) {
      await api.createPayableLineItem(id, body);
      this.refresh();
    } else {
      const _lineItems = [...lineItems];
      _lineItems.push(body);
      this.setState({ lineItems: _lineItems });
    }
  }

  async toggleOpenBatchLineItems() {
    const { batchLineItemsOpen } = this.state;
    this.setState({batchLineItemsOpen: !batchLineItemsOpen})
  }

  async addLineItems() {
    const { api, role } = this.props;
    const { lineItems, id, newLineItems } = this.state;
    const rows = newLineItems.split("\n")
    const _lineItems = [...lineItems];
    const re = /\s+/g
    const cursor = lineItems.length;

    for(const i in rows) {
      const cells = rows[i].split("\t")
      const body = {
        tmpID: cursor+i,
        amount: Number(cells[0].replace(",", "")),
        memo: cells.splice(1).join(" ").trim().replaceAll(re, " "),
       };
      _lineItems.push(body);
    }

    this.setState({ lineItems: _lineItems, batchLineItemsOpen: false  });
  }

  async updateLineItem(lineItem, index) {
    const { api, payable } = this.props;
    const { lineItems, id } = this.state;
    if (id) {
      await api.updatePayableLineItem(id, lineItem.id, lineItem);
      this.refresh();
    } else {
      const _lineItems = [...lineItems];
      _lineItems[index] = lineItem;
      this.setState({ lineItems: _lineItems });
    }
  }

  async updateInternalMemo(lineItem, index) {
    const { api } = this.props;
    const { lineItems, id } = this.state;
    if (id) {
      await api.updateLineItemInternalMemo(id, lineItem.id, lineItem);
      this.refresh();
    } else {
      const _lineItems = [...lineItems];
      _lineItems[index] = lineItem;
      this.setState({ lineItems: _lineItems });
    }
  }

  delete() {
    const { referenceNumber } = this.state;
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle: `You are about to delete the payable ${referenceNumber}. You won't be able to recover it.`,
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { api, payable } = this.props;
    const { referenceNumber, id } = this.state;

    const { NotificationCenter } = this.context;
    const resp = await api.deletePayable(id);
    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: `Payable ${referenceNumber} has been deleted.`,
        success: true,
        timestamp: new Date().getTime(),
      });
      api.refresh();
      this.close();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  getBody() {
    const {
      amount,
      assignedToUUID,
      dualAssignedToUUID,
      vendorID,
      date,
      companyID,
      dueDate,
      memo,
      referenceNumber,
      priorityLevelID,
      transactionType,
      glAccountID,
    } = this.state;

    let body = {
      assignedToUUID: assignedToUUID ? assignedToUUID : null,
      dualAssignedToUUID: dualAssignedToUUID ? dualAssignedToUUID : null,
      dueDate,
      companyID,
      vendorID,
      amount: Number(amount),
      date,
      memo,
      referenceNumber,
      priorityLevelID,
      transactionType,
      glAccountID,
    };

    if (this.state.original) {
      body = reduceToChanges(body, this.state.original);
    }

    return body;
  }

  close() {
    const { close } = this.props;
    this.setState({ ...initState });
    close();
  }

  async done(needClose) {
    const { payable, api, refresh } = this.props;
    const { lineItems, files, id } = this.state;
    if (this.state.locked) {
      api.refresh();
      if (needClose) this.close();
      return;
    }

    const body = this.getBody();

    let r;
    if (id && Object.keys(body).length === 0) {
      if (needClose) this.close();
      return;
    }

    if (id) {
      r = await api.updatePayable(id, body);
    } else {
      body.lineItems = lineItems;
      r = await api.createPayable(body);
      if (r.success) {
        this.setState({ backdropOpen: true });
        const pay = r.payload;
        const payableID = pay.id;
        await new Promise(async (r) => {
          const funcs = [];
          for (const k in files) {
            if (files.hasOwnProperty(k)) {
              const file = files[k];
              funcs.push(api.uploadPayableFile(payableID, file));
            }
          }
          for (const key in pay.lineItems) {
            if (Object.hasOwnProperty.call(pay.lineItems, key)) {
              const lineItem = pay.lineItems[key];
              const _lineItem = lineItems[key];
              if (_lineItem.files) {
                for (const key in _lineItem.files) {
                  if (Object.hasOwnProperty.call(_lineItem.files, key)) {
                    const file = _lineItem.files[key];
                    funcs.push(api.uploadLineItemFile(lineItem.id, file));
                  }
                }
              }
            }
          }
          await Promise.all(funcs);
          r();
        });
        this.setState({ backdropOpen: false });
      }
    }

    if (needClose) {
      refresh();
      this.close();
    }
  }

  async onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    const m = reorder(
      this.state.lineItems,
      result.source.index,
      result.destination.index
    );
    this.setState({
      lineItems: m,
    });
  }

  referenceLength() {
    const { transactionType } = this.state;
    if (transactionType === 3) {
      return 11;
    }
    return 20;
  }

  render() {
    const {
      close,
      open,
      companies,
      vendors,
      glAccounts,
      itemClasses,
      users,
      user,
      priorityLevels,
      api,
      role,
    } = this.props;

    const { NotificationCenter } = this.context;

    const {
      memo,
      companyID,
      amount,
      lineItems,
      id,
      files,
      date,
      company,
      companyInput,
      vendor,
      vendorInput,
      dualAssignedTo,
      assign,
      selectedFile,
      referenceNumber,
      assignInput,
      dualAssign,
      dualAssignInput,
      dueDate,
      locked,
      transactionType,
      priorityLevelID,
      glAccount,
      glAccountID,
      glAccountInput,
      batchLineItemsOpen,
      newLineItems,
    } = this.state;

    let balance = 0;
    let priority =
      priorityLevels && priorityLevels.find((pl) => pl.id === priorityLevelID);

    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });

    for (const key in lineItems) {
      if (lineItems.hasOwnProperty(key)) {
        const element = lineItems[key];
        if (element.amount) {
          balance += element.amount;
        }
      }
    }

    return (
      <div>
        <Dialog
          fullScreen
          open={open}
          aria-labelledby="responsive-dialog-title"
        >
          <div key={id}>
            <AppBar position="absolute" style={{ background: "#424242" }}>
              <Toolbar
                style={{ display: "flex", justifyContent: "space-between" }}
                variant="dense"
              >
                <div>
                  <Button
                    size="small"
                    onClick={this.close.bind(this)}
                    color="inherit"
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                  {id ? (
                    <Button
                      size="small"
                      onClick={this.delete.bind(this)}
                      style={{
                        marginLeft: 8,
                        color: "#f44336",
                        borderColor: "#f44336",
                      }}
                      color="inherit"
                      variant="outlined"
                    >
                      Delete
                    </Button>
                  ) : (
                    []
                  )}
                </div>
                <div />
                <div>
                  {id ? (
                    <Button
                      size="small"
                      autoFocus
                      onClick={() => this.done(false)}
                      color="inherit"
                      variant="outlined"
                      style={{
                        color: "#ffffff",
                        marginRight: 8,
                      }}
                    >
                      Save
                    </Button>
                  ) : (
                    []
                  )}
                  <Button
                    size="small"
                    autoFocus
                    onClick={() => this.done(true)}
                    color="inherit"
                    variant="contained"
                    style={{ background: "white", color: "#ff9800" }}
                  >
                    {id ? "Done" : "Create"}
                  </Button>
                </div>
              </Toolbar>
            </AppBar>
            <Container
              style={{
                marginTop: 48,
                height: "calc(100vh - 48px)",
                position: "relative",
              }}
            >
              <Section
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {selectedFile ? (
                  <div style={{ display: "flex", flex: 1 }}>
                    {selectedFile?.id ? (
                      <iframe
                        src={`${REACT_APP_API}${REACT_APP_API_PREFIX}/ap-dashboard/${selectedFile.modelType}s/${selectedFile.modelID}/download/${selectedFile.id}?inline=1`}
                        frameborder="0"
                        width="100%"
                        height="100%"
                        style={{ border: "none", flex: 1 }}
                      />
                    ) : (
                      <iframe
                        src={selectedFile?.preview}
                        frameborder="0"
                        width="100%"
                        height="100%"
                        style={{ border: "none", flex: 1 }}
                      />
                    )}
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flex: 1,
                    }}
                  >
                    <div style={{ textAlign: "center" }}>
                      {files.length ? (
                        <Typography>Select a file for preview</Typography>
                      ) : (
                        <div>
                          <CloudUpload style={{ fontSize: 40 }} />
                          <Typography variant="h6">No Files</Typography>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </Section>
              <Bar
                size={8}
                expandInteractiveArea={{ left: 20, right: 20 }}
                style={{
                  background: "rgba(155,155,155,0.3)",
                  cursor: "pointer",
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <DragHandleIcon
                  style={{
                    color: "#9e9e9e",
                    position: "absolute",
                    fontSize: 16,
                    marginLeft: -4,
                    transform: "rotate(90deg)",
                  }}
                />
              </Bar>
              <Section style={{ overflow: "scroll" }}>
                <div style={{ padding: 16 }}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <div>
                            <Typography
                              variant="h5"
                              display="block"
                              style={{ fontWeight: 700, fontSize: 36 }}
                            >
                              {id
                                ? `Payable ${referenceNumber}`
                                : " New Payable"}
                            </Typography>
                            <div>
                              {this.state.locked ? (
                                <Chip
                                  size="small"
                                  label="Locked"
                                  icon={<LockIcon />}
                                />
                              ) : (
                                []
                              )}
                              {this.state.exportID ? (
                                <Chip
                                  size="small"
                                  label={`Exported on batch ${this.state.exportID}`}
                                  style={{ marginLeft: 12 }}
                                  icon={<BeenhereIcon />}
                                />
                              ) : (
                                []
                              )}
                            </div>
                            {this.state.createdBy ? (
                              <div>
                                <div style={{ display: "flex" }}>
                                  <div>
                                    <Typography display="block">
                                      Created by{" "}
                                      {`${this.state.createdBy.firstName} ${this.state.createdBy.lastName}`}
                                    </Typography>
                                    <Typography
                                      variant="caption"
                                      color="textSecondary"
                                      display="block"
                                    >
                                      On{" "}
                                      {moment(this.state.createdAt).format(
                                        "lll"
                                      )}
                                    </Typography>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              []
                            )}
                          </div>
                          <div style={{ textAlign: "right" }}>
                            {priority ? (
                              <div>
                                <Typography>
                                  <b>{priority.name} Priority</b>
                                </Typography>
                                <Typography
                                  variant="caption"
                                  color="textSecondary"
                                >
                                  ({priority.followUpDays} Follow up days)
                                </Typography>
                              </div>
                            ) : (
                              <Typography>No Priority Set</Typography>
                            )}
                            <Rating
                              style={{
                                color: "#f44336",
                              }}
                              disabled={locked}
                              value={
                                priorityLevelID && priorityLevels
                                  ? priorityLevels.length - priorityLevelID + 1
                                  : null
                              }
                              onChange={(event, newValue) => {
                                this.setState({
                                  priorityLevelID:
                                    priorityLevels.length - newValue + 1,
                                });
                              }}
                              defaultValue={1}
                              max={priorityLevels.length}
                              icon={<FlagIcon fontSize="inherit" />}
                            />
                          </div>
                        </div>
                      </Grid>
                      {this.state.lockable &&
                      !this.state.locked &&
                      (role === "manager" || role === "superadmin") ? (
                        <Grid item xs={12}>
                          <Alert
                            severity="info"
                            variant="filled"
                            icon={<LockIcon />}
                            style={{ background: "#424242" }}
                            fullWidth
                            action={
                              <Button
                                style={{
                                  background: "white",
                                  color: "#424242",
                                  textTransform: "none",
                                }}
                                color="inherit"
                                size="small"
                                onClick={async () => {
                                  await api.lockPayable(id, {
                                    locked: true,
                                  });
                                  this.refresh();
                                }}
                              >
                                Lock Now
                              </Button>
                            }
                          >
                            This payable is ready to be locked
                          </Alert>
                        </Grid>
                      ) : (
                        []
                      )}
                      {id &&
                      this.state.assignedToUUID === user.uuid &&
                      !this.state.approved ? (
                        <Grid item xs={12}>
                          <Alert
                            severity="success"
                            fullWidth
                            action={
                              <Button
                                variant="contained"
                                style={{
                                  background: "#4caf50",
                                  color: "white",
                                  textTransform: "none",
                                }}
                                color="inherit"
                                size="small"
                                onClick={async () => {
                                  await api.updatePayable(id, {
                                    ...this.getBody(),
                                    approved: true,
                                    approvedDate: moment().utc().format(),
                                  });
                                  api.refresh();
                                  this.refresh();
                                }}
                              >
                                Approve Now
                              </Button>
                            }
                          >
                            You need to review and approve the content of this
                            payable.
                            <br />
                            <Typography
                              variant="caption"
                              style={{ opacity: 0.7 }}
                            >
                              Make sure to write a memo before you approve.
                            </Typography>
                          </Alert>
                        </Grid>
                      ) : (
                        []
                      )}
                      {id &&
                      this.state.dualAssignedToUUID === user.uuid &&
                      !this.state.dualApproved ? (
                        <Grid item xs={12}>
                          <Alert
                            severity="success"
                            fullWidth
                            action={
                              <Button
                                variant="contained"
                                style={{
                                  background: "#4caf50",
                                  color: "white",
                                  textTransform: "none",
                                }}
                                color="inherit"
                                size="small"
                                onClick={async () => {
                                  await api.updatePayable(id, {
                                    ...this.getBody(),
                                    dualApproved: true,
                                    dualApprovedDate: moment().utc().format(),
                                  });
                                  api.refresh();
                                  this.refresh();
                                }}
                              >
                                Dual Approve Now
                              </Button>
                            }
                          >
                            You need to double check the content of this
                            payable.
                            <br />
                            <Typography
                              variant="caption"
                              style={{ opacity: 0.7 }}
                            >
                              Make sure to write a memo before you approve.
                            </Typography>
                          </Alert>
                        </Grid>
                      ) : (
                        []
                      )}
                      <Grid item xs={12}>
                        <Typography variant="h5" style={{ fontWeight: 700 }}>
                          Files
                        </Typography>
                        {!locked ? (
                          <AddAttachment
                            userDidDrop={this.userDidDrop.bind(this)}
                          />
                        ) : (
                          []
                        )}
                        <Grid container style={{ marginTop: 12 }}>
                          {files &&
                            files.map((f, i) => (
                              <File
                                key={f.name}
                                onSelect={(f) =>
                                  this.setState({ selectedFile: f })
                                }
                                NotificationCenter={NotificationCenter}
                                selected={f.name === selectedFile?.name}
                                file={f}
                                disabled
                                index={Number(i)}
                              ></File>
                            ))}
                        </Grid>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="h6" style={{ fontWeight: 700 }}>
                          References
                        </Typography>
                        <Typography color="textSecondary" variant="caption">
                          Provide important information about the payable.
                        </Typography>
                      </Grid>
                      <Grid item xs={6} onClick={() => console.log(this.state)}>
                        <ButtonGroup
                          color="primary"
                          aria-label="outlined primary button group"
                          disabled={locked}
                        >
                          <Button
                            style={{ boxShadow: "none" }}
                            variant={
                              this.state.transactionType === 0 ? "contained" : undefined
                            }
                            onClick={() => this.setState({
                              transactionType: 0,
                              glAccount: this.state.company ? this.state.company.accountsPayable : undefined,
                              glAccountID: this.state.company ? this.state.company.accountsPayable.id : 0,
                              glAccountInput: this.state.company ? this.state.company.accountsPayable.name : "",
                            })}
                          >
                            Bill
                          </Button>
                          <Button
                            style={{ boxShadow: "none" }}
                            variant={
                              this.state.transactionType === 1 ? "contained" : undefined
                            }
                            onClick={() => this.setState({
                              transactionType: 1,
                              glAccount: this.state.company ? this.state.company.accountsPayable : undefined,
                              glAccountID: this.state.company ? this.state.company.accountsPayable.id : 0,
                              glAccountInput: this.state.company ? this.state.company.accountsPayable.name : "",
                            })}
                          >
                            Credit
                          </Button>
                          <Button
                            style={{ boxShadow: "none" }}
                            disabled={false}
                            variant={
                              this.state.transactionType === 2 ? "contained" : undefined
                            }
                            onClick={() => this.setState({ transactionType: 2, glAccount: undefined, glAccountID: 0, glAccountInput: "" })}
                          >
                            Check
                          </Button>
                          <Button
                            style={{ boxShadow: "none" }}
                            variant={
                              this.state.transactionType === 3 ? "contained" : undefined
                            }
                            onClick={() => this.setState({ transactionType: 3, glAccount: undefined, glAccountID: 0, glAccountInput: "" })}
                          >
                            Journal Entry
                          </Button>
                        </ButtonGroup>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          disabled={locked}
                          variant="filled"
                          label="Reference Number"
                          helperText={
                            (referenceNumber && referenceNumber.length <= this.referenceLength()) ||
                            referenceNumber.length === 0
                              ? `${this.referenceLength() - referenceNumber.length} char left`
                              : "Too long!"
                          }
                          error={referenceNumber.length > this.referenceLength()}
                          style={{ textAlign: "right" }}
                          value={referenceNumber}
                          onChange={this.handleChange("referenceNumber")}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          variant="filled"
                          label="Amount"
                          disabled={locked}
                          style={{ textAlign: "right" }}
                          value={amount}
                          error={(transactionType === 0 && parseFloat(amount) < 0) || (transactionType === 1 && parseFloat(amount) > 0) }
                          helperText={
                            (amount && transactionType === 0 && parseFloat(amount) < 0)
                              ? `A Bill amount must be positive`
                              : (amount && transactionType === 1 && parseFloat(amount) > 0)
                                ? `A Credit amount must be negative`
                                : ``
                          }
                          onChange={this.handleChange("amount")}
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                          }}
                        />
                      </Grid>
                      <Grid item xs={6} onClick={() => console.log(this.state)}>
                        <Autocomplete
                          value={company}
                          onChange={(e, v) => {
                            let body = {
                              companyID: v ? v.id : "",
                              company: v,
                              glAccount: undefined,
                              glAccountID: "",
                              glAccountInput: "",
                              vendorID: "",
                              vendor: undefined,
                              vendorInput: "",
                            }
                            if (v && transactionType < 2) {
                              body = Object.assign(body, {
                                glAccount: v.accountsPayable,
                                glAccountID: v.accountsPayable.id,
                                glAccountInput: v.accountsPayable.name,
                              })
                            }
                            this.setState(body);
                          }}
                          inputValue={companyInput}
                          onInputChange={(event, newInputValue) => {
                            this.setState({ companyInput: newInputValue });
                          }}
                          disabled={locked}
                          options={companies}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              variant="filled"
                              label="Company"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Autocomplete
                          value={vendor}
                          onChange={(e, v) => {
                            const lineItems = [...this.state.lineItems];
                            let tmpID = new Date().getTime();
                            if (v && v.defaultAssignedToUUID) {
                              this.setState({
                                assignedToUUID: v.defaultAssignedToUUID,
                                assign: users.find(
                                  (u) => u.uuid === v.defaultAssignedToUUID
                                ),
                              });
                            }
                            if (v && v.defaultDualAssignedToUUID) {
                              this.setState({
                                dualAssignedToUUID: v.defaultDualAssignedToUUID,
                                dualAssign: users.find(
                                  (u) => u.uuid === v.defaultDualAssignedToUUID
                                ),
                              });
                            }
                            if (v && v.defaultLineItems) {
                              for (const key in v.defaultLineItems) {
                                if (v.defaultLineItems.hasOwnProperty(key)) {
                                  const element = v.defaultLineItems[key];
                                  lineItems.push({
                                    amount: null,
                                    tmpID: tmpID,
                                    memo: "",
                                    glAccountID: element.glAccountID,
                                    classID: element.classID,
                                  });
                                  tmpID += 1;
                                }
                              }
                            }

                            this.setState({
                              vendorID: v ? v.id : "",
                              vendor: v,
                              lineItems,
                              dueDate:
                                v && v.terms
                                  ? moment(date)
                                      .add(v.terms.Days, "days")
                                      .utc()
                                      .format()
                                  : this.state.date,
                            });
                          }}
                          disabled={locked}
                          inputValue={vendorInput}
                          onInputChange={(event, newInputValue) => {
                            this.setState({ vendorInput: newInputValue });
                          }}
                          options={vendors.filter(
                            (v) => v.companyID === companyID && (this.state.transactionType !== 2 ? v.nameListType === 0 : true)
                          )}
                          renderOption={(option) => (
                            <div>
                              <Typography>{option.name}</Typography>
                              <Typography
                                variant="caption"
                                color="textSecondary"
                              >
                                {`${option.addr2} ${option.addr3}`}
                              </Typography>
                            </div>
                          )}
                          getOptionLabel={(option) =>
                            `${option.name} ${option.addr2} ${option.addr3}`
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              variant="filled"
                              label="Vendor"
                              helperText={
                                vendor && `${vendor.addr2} ${vendor.addr3}`
                              }
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <KeyboardDatePicker
                          value={moment(date).utc()}
                          fullWidth
                          autoOk
                          onChange={(e) => {
                            this.setState({
                              date: e.utc().format(),
                              dueDate:
                                vendor && vendor.terms && vendor.terms.Days
                                  ? e
                                      .clone()
                                      .add(vendor.terms.Days, "days")
                                      .utc()
                                      .format()
                                  : e.utc().format(),
                            });
                          }}
                          disabled={locked}
                          disableToolbar
                          inputProps={{
                            size: "small",
                          }}
                          keyboardIcon={
                            <CalendarTodayIcon
                              style={{
                                fontSize: 20,
                                color: "black",
                              }}
                            />
                          }
                          inputVariant="filled"
                          variant="inline"
                          format="MM/DD/YYYY"
                          id="date-picker-inline"
                          label="Date"
                          error={
                            (!locked &&
                              moment(date).isBefore(
                                moment(company?.glLockDate?.date)
                              )) ||
                            moment(date).format("MM/DD/YYYY") ===
                              moment(company?.glLockDate?.date).format(
                                "MM/DD/YYYY"
                              )
                          }
                          helperText={`Lock Date:
                            ${moment(company?.glLockDate?.date).format(
                              "MM/DD/YYYY"
                            )}`}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <KeyboardDatePicker
                          value={moment(dueDate).utc()}
                          fullWidth
                          autoOk
                          onChange={(e) =>
                            this.setState({ dueDate: e.utc().format() })
                          }
                          disabled={locked || transactionType !== 0}
                          disableToolbar
                          inputProps={{
                            size: "small",
                          }}
                          keyboardIcon={
                            <CalendarTodayIcon
                              style={{
                                fontSize: 20,
                                color: "black",
                              }}
                            />
                          }
                          inputVariant="filled"
                          variant="inline"
                          format="MM/DD/YYYY"
                          id="date-picker-inline"
                          label="Due Date"
                          helperText={`+ ${moment(dueDate).diff(
                            moment(date),
                            "days"
                          )} Day(s)${
                            vendor && vendor.terms && vendor.terms.Name
                              ? `. Terms: ${vendor.terms.Name}`
                              : ""
                          }`}
                          // helperText={
                          //   vendor &&
                          //   vendor.terms &&
                          //   vendor.terms.Days !== undefined
                          //     ? `Terms: ${
                          //         vendor && vendor.terms && vendor.terms.Days
                          //       } Day(s)`
                          //     : undefined
                          // }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          disabled={locked}
                          variant="filled"
                          label="Memo"
                          value={memo}
                          onChange={this.handleChange("memo")}
                          multiline
                          rows={1}
                          rowsMax={5}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <InfoIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={6} onClick={() => console.log(this.state)}>
                        <Autocomplete
                          value={glAccount}
                          onChange={(e, v) => {
                            this.setState({
                              glAccountID: v ? v.id : 0,
                              glAccount: v,
                              glAccountInput: v ? v.name : "",
                            });
                          }}
                          inputValue={glAccountInput}
                          onInputChange={(event, newInputValue, reason) => {
                            if(reason === "reset" && newInputValue === "") {
                              return;
                            }
                            this.setState({ glAccountInput: newInputValue });
                          }}
                          clearOnBlur
                          disabled={locked || this.state.transactionType < 2}
                          options={glAccounts.filter(
                            (gl) => gl.companyID === companyID && (this.state.transactionType === 2 ? gl.type === "BANK" : true)
                          )}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              variant="filled"
                              label="Account"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h6" style={{ fontWeight: 700 }}>
                          Approval
                        </Typography>
                        <Typography color="textSecondary" variant="caption">
                          Assign this payable for review to up to 2 people. They
                          will be notified by email to perform the audit by the
                          due date.
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Autocomplete
                          value={assign}
                          key={assign}
                          onChange={(e, v) =>
                            this.setState({
                              assignedToUUID: v ? v.uuid : "",
                              assign: v,
                            })
                          }
                          disabled={locked}
                          inputValue={assignInput}
                          onInputChange={(event, newInputValue) => {
                            this.setState({ assignInput: newInputValue });
                          }}
                          options={users}
                          getOptionLabel={(option) =>
                            `${option.firstName} ${option.lastName}`
                          }
                          renderInput={(params) => {
                            params.InputProps.style = {
                              background: this.state.approvedDate
                                ? "rgba(76,175,80,0.1)"
                                : undefined,
                            };
                            return (
                              <TextField
                                {...params}
                                fullWidth
                                variant={"filled"}
                                label="Primary Reviewer"
                                helperText={
                                  <span style={{ color: "#4caf50" }}>
                                    {this.state.approvedDate
                                      ? `Has approved on ${moment(
                                          this.state.approvedDate
                                        ).format("lll")}`
                                      : ""}
                                  </span>
                                }
                              />
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Autocomplete
                          value={dualAssign}
                          key={dualAssign}
                          onChange={(e, v) =>
                            this.setState({
                              dualAssignedToUUID: v ? v.uuid : "",
                              dualAssign: v,
                            })
                          }
                          disabled={locked}
                          inputValue={dualAssignInput}
                          onInputChange={(event, newInputValue) => {
                            this.setState({ dualAssignInput: newInputValue });
                          }}
                          options={users}
                          getOptionLabel={(option) =>
                            `${option.firstName} ${option.lastName}`
                          }
                          renderInput={(params) => {
                            params.InputProps.style = {
                              background: this.state.dualApprovedDate
                                ? "rgba(76,175,80,0.1)"
                                : undefined,
                            };
                            return (
                              <TextField
                                {...params}
                                fullWidth
                                variant={"filled"}
                                label="Secondary Reviewer"
                                helperText={
                                  <span style={{ color: "#4caf50" }}>
                                    {this.state.dualApprovedDate
                                      ? `Has dual approved on ${moment(
                                          this.state.dualApprovedDate
                                        ).format("lll")}`
                                      : ""}
                                  </span>
                                }
                              />
                            );
                          }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Typography variant="h6" style={{ fontWeight: 700 }}>
                          Line Items
                        </Typography>
                        <Typography color="textSecondary" variant="caption">
                          Enter the Payable’s content. <b>CTRL+SPACE</b> to add
                          new line item.
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            style={{ display: "flex", marginLeft: 60 }}
                          >
                            <Typography
                              variant="caption"
                              color="textSecondary"
                              style={{ flex: 1 }}
                            >
                              GL Account
                            </Typography>
                            <Typography
                              variant="caption"
                              color="textSecondary"
                              style={{ flex: 1 }}
                            >
                              Item Class
                            </Typography>
                            <Typography
                              variant="caption"
                              color="textSecondary"
                              style={{ flex: 1 }}
                            >
                              Memo
                            </Typography>
                            <Typography
                              variant="caption"
                              color="textSecondary"
                              style={{ flex: 1 }}
                            >
                              Amount
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <DragDropContext
                            onDragEnd={this.onDragEnd.bind(this)}
                          >
                            <Droppable droppableId="droppable">
                              {(provided, snapshot) => (
                                <div
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                >
                                  {lineItems &&
                                    lineItems.map((li, index) => (
                                      <Draggable
                                        isDragDisabled={li.tmpID === undefined}
                                        key={li.tmpID || li.id}
                                        draggableId={
                                          `${li.tmpID}` || `${li.id}`
                                        }
                                        index={index}
                                      >
                                        {(p, s) => (
                                          <div
                                            ref={p.innerRef}
                                            {...p.draggableProps}
                                            {...p.dragHandleProps}
                                          >
                                            <LineItem
                                              dragHandle={
                                                li.tmpID !== undefined
                                              }
                                              lineItem={li}
                                              disabled={
                                                locked || role === "user"
                                              }
                                              index={Number(index)}
                                              companyID={companyID}
                                              glAccounts={glAccounts}
                                              company={company}
                                              itemClasses={itemClasses}
                                              uploadLineItemFile={
                                                api.uploadLineItemFile
                                              }
                                              setSelectedFile={(e) =>
                                                this.setState({
                                                  selectedFile: e,
                                                })
                                              }
                                              update={this.updateLineItem.bind(
                                                this
                                              )}
                                              updateInternalMemo={this.updateInternalMemo.bind(this)}
                                              onDelete={this.deleteLineItem.bind(
                                                this
                                              )}
                                            />
                                          </div>
                                        )}
                                      </Draggable>
                                    ))}
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>
                          {lineItems && lineItems.length ? (
                            <div
                              style={{ textAlign: "right", paddingRight: 40 }}
                            >
                              <Typography variant="caption" display="block">
                                Balance: {formatter.format(balance)}
                              </Typography>
                              {amount - balance ? (
                                <Typography
                                  variant="caption"
                                  display="block"
                                  style={{ color: "#f44336" }}
                                >
                                  Remaining:{" "}
                                  {formatter.format(amount - balance)}
                                </Typography>
                              ) : (
                                []
                              )}
                            </div>
                          ) : (
                            []
                          )}
                          {!locked && role !== "user" ? (
                            <Chip
                              style={{ marginTop: 4 }}
                              label="+ Line Item"
                              size="small"
                              variant="outlined"
                              onClick={this.addLineItem.bind(this)}
                            />
                          ) : (
                            []
                          )}
                          {!locked && role !== "user" && id === undefined ? (
                            <Chip
                              style={{ marginTop: 4 }}
                              label="Batch Line Items"
                              size="small"
                              variant="outlined"
                              onClick={this.toggleOpenBatchLineItems.bind(this)}
                            />
                          ) : (
                            []
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </MuiPickersUtilsProvider>
                </div>
              </Section>
            </Container>
          </div>
        </Dialog>
        <Dialog

          maxWidth={"md"}
          open={batchLineItemsOpen}
          onClose={() => this.setState({ batchLineItemsOpen: false })}
        >
          <DialogTitle id="alert-dialog-title">
            Copy/Paste from Sheets
          </DialogTitle>
          <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography style={{ color: "black" }}>
                    Pasted columns should have the amount as the first column and all other columns will be put in the memo
                  </Typography>
                  </Grid>
                <Grid item xs={12}>
                  <TextField
                    multiline
                    fullWidth
                    variant="filled"
                    onChange={this.handleChange("newLineItems")}
                  >
                  </TextField>
                </Grid>
              </Grid>
            <DialogActions>
              <Button
                onClick={() =>
                  this.setState({
                    batchLineItemsOpen: false,
                  })
                }
              >
                Cancel
              </Button>
              <Button
                onClick={() =>
                  this.addLineItems()
                }
              >
                Add
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
        <Backdrop open={this.state.backdropOpen} style={{ zIndex: 99999 }}>
          <div style={{ textAlign: "center", color: "white" }}>
            <CircularProgress style={{ color: "white" }} />
            <Typography>Uploading Files...</Typography>
            <Button
              variant="outlined"
              style={{ color: "white", borderColor: "white" }}
              size="small"
              onClick={() => this.setState({ backdropOpen: false })}
            >
              Cancel
            </Button>
          </div>
        </Backdrop>
      </div>
    );
  }
}

export default withStyles(styles)(Payable);
