import Chip from "@material-ui/core/Chip";
// material-ui
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
// custom
import Table from "components/Table";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class OrganizationsList extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    joinOrganization: PropTypes.func,
    organizations: PropTypes.array,
    user: PropTypes.array,
    admin: PropTypes.bool,
    selectedOrganization: PropTypes.object,
    onSelect: PropTypes.func,
    refresh: PropTypes.func,
  };

  onSelect(selectedOrganization) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedOrganization);
    }
  }

  render() {
    const {
      organizations,
      user,
      selectedOrganization,
      joinOrganization,
      refresh,
      admin,
    } = this.props;

    return (
      <Table
        data={organizations}
        rowStyle={(d) => ({
          borderLeft:
            d && d.ownerID === user.id
              ? "solid 3px #2196f3"
              : "solid 3px #9e9e9e",
          background:
            selectedOrganization && selectedOrganization.id === d.id
              ? "rgba(33,150,243, 0.1)"
              : undefined,
        })}
        meta={[
          {
            path: "name",
            title: "Name",
            numeric: false,
            render: (t) => (
              <Typography display="block" variant="body2">
                {t}
              </Typography>
            ),
          },
          {
            path: "ownerFullName",
            title: "Owner",
            numeric: false,
            render: (t, d) =>
              d.ownerID === user.id ? (
                <span
                  style={{
                    background: "rgba(33,150,243, 0.1)",
                    border: "solid 1px rgba(33,150,243, 0.3)",
                    borderRadius: 16,
                    padding: "4px 6px 4px 6px",
                    color: "#2196f3",
                  }}
                >
                  You
                </span>
              ) : (
                <React.Fragment>
                  <Typography display="block" variant="body2">
                    {d.ownerFullName || `User #${d.ownerID}`}
                  </Typography>
                  <Typography
                    display="block"
                    variant="caption"
                    color="textSecondary"
                  >
                    {d.email}
                  </Typography>
                </React.Fragment>
              ),
          },
          {
            path: "active",
            numeric: true,
            render: (t, d) =>
              d.ownerID === user.id || admin ? (
                <Chip
                  label="Edit"
                  style={{
                    background: "#2196f3",
                    color: "white",
                  }}
                />
              ) : (
                <React.Fragment>
                  {d.active ? (
                    <Chip
                      label="Leave"
                      style={{
                        background: "#f44336",
                        color: "white",
                      }}
                      onClick={async () => {
                        await joinOrganization(d.id, { active: false });
                        refresh();
                      }}
                    />
                  ) : (
                    <Chip
                      label="Join"
                      onClick={async () => {
                        await joinOrganization(d.id, { active: true });
                        refresh();
                      }}
                      style={{
                        background: "#4caf50",
                        color: "white",
                      }}
                    />
                  )}
                </React.Fragment>
              ),
          },
        ]}
        title={"Organizations"}
        onRowSelect={this.onSelect.bind(this)}
      />
    );
  }
}

export default withStyles(styles)(OrganizationsList);
