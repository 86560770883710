import {
  DELETE,
} from 'constants/methods';

import { REACT_APP_API_PREFIX } from 'config/env';

const service = 'account';
const sessions = `${REACT_APP_API_PREFIX}/${service}/sessions`;

export function signout() {
  const url = `${sessions}/current`;
  return {
    service,
    method: DELETE,
    url,
  };
}
