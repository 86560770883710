import {
  GET,
  PUT,
  DELETE,
  POST,
} from 'constants/methods';

import { REACT_APP_API_PREFIX } from 'config/env';

const service = 'account';
const serviceNames = `${REACT_APP_API_PREFIX}/${service}/serviceNames`;

export function getServiceNames() {
  const url = `${serviceNames}`;
  return {
    service,
    method: GET,
    url,
  };
}

export function getServiceNameById(id) {
  const url = `${serviceNames}/${id}`;
  return {
    service,
    method: GET,
    url,
  };
}

export function updateServiceName(id, body) {
  const url = `${serviceNames}/${id}`;
  return {
    service,
    method: PUT,
    url,
    body,
  };
}

export function deleteServiceName(id) {
  const url = `${serviceNames}/${id}`;
  return {
    service,
    method: DELETE,
    url,
  };
}

export function createServiceName(body) {
  const url = `${serviceNames}`;
  return {
    service,
    method: POST,
    url,
    body,
  };
}
