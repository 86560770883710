import { networkAction } from 'helpers/network/networkAction';

import {
  GET_PRIORITY_LEVELS,
  GET_PRIORITY_LEVEL_BY_ID,
  DELETE_PRIORITY_LEVEL,
  UPDATE_PRIORITY_LEVEL,
  CREATE_PRIORITY_LEVEL,
} from 'constants/priorityLevels';

import * as Api from 'api';

export const getPriorityLevels = () => async dispatch => networkAction(
  dispatch,
  GET_PRIORITY_LEVELS,
  Api.getPriorityLevels,
  []
);

export const getPriorityLevelById = (id) => async dispatch => networkAction(
  dispatch,
  GET_PRIORITY_LEVEL_BY_ID,
  Api.getPriorityLevelById,
  [id]
);

export const deletePriorityLevel = (id) => async dispatch => networkAction(
  dispatch,
  DELETE_PRIORITY_LEVEL,
  Api.deletePriorityLevel,
  [id]
);

export const createPriorityLevel = (body) => async dispatch => networkAction(
  dispatch,
  CREATE_PRIORITY_LEVEL,
  Api.createPriorityLevel,
  [body]
);

export const updatePriorityLevel = (id, body) => async dispatch => networkAction(
  dispatch,
  UPDATE_PRIORITY_LEVEL,
  Api.updatePriorityLevel,
  [id, body]
);
