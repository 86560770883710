import { networkAction } from 'helpers/network/networkAction';

import {
  GET_ITEM_CLASSES,
  GET_ITEM_CLASSE_BY_ID,
  DELETE_ITEM_CLASSE,
  UPDATE_ITEM_CLASSE,
  CREATE_ITEM_CLASSE,
} from 'constants/itemClasses';

import * as Api from 'api';

export const getItemClasses = () => async dispatch => networkAction(
  dispatch,
  GET_ITEM_CLASSES,
  Api.getItemClasses,
  [],
);

export const getItemClasseById = id => async dispatch => networkAction(
  dispatch,
  GET_ITEM_CLASSE_BY_ID,
  Api.getItemClasseById,
  [id],
);

export const deleteItemClasse = id => async dispatch => networkAction(
  dispatch,
  DELETE_ITEM_CLASSE,
  Api.deleteItemClasse,
  [id],
);

export const createItemClasse = body => async dispatch => networkAction(
  dispatch,
  CREATE_ITEM_CLASSE,
  Api.createItemClasse,
  [body],
);

export const updateItemClasse = (id, body) => async dispatch => networkAction(
  dispatch,
  UPDATE_ITEM_CLASSE,
  Api.updateItemClasse,
  [id, body],
);
