export default theme => ({
  container: {
    padding: 5,
  },
  noPadding: {
    padding: 0,
  },
  checkboxPadding: {
    padding: '0px 8px 0px 20px',
  },
});
