import { createStore, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import {
  CONSOLE_LOGGER_ENABLED,
} from 'config';

import rootReducer from './reducers/rootReducer';

export default function configureStore() {
  return createStore(
    rootReducer,
    CONSOLE_LOGGER_ENABLED
      ? applyMiddleware(thunk, createLogger())
      : applyMiddleware(thunk),
  );
}
