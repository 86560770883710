import {
  GET,
  PUT,
  DELETE,
  POST,
} from 'constants/methods';

import { REACT_APP_API_PREFIX } from 'config/env';

const service = 'ap-dashboard';
const glAccounts = `${REACT_APP_API_PREFIX}/${service}/glAccounts`;

export function getGlAccounts() {
  const url = `${glAccounts}`;
  return {
    method: GET,
    url,
  };
}

export function getGlAccountById(id) {
  const url = `${glAccounts}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateGlAccount(id, body) {
  const url = `${glAccounts}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteGlAccount(id) {
  const url = `${glAccounts}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createGlAccount(body) {
  const url = `${glAccounts}`;
  return {
    method: POST,
    url,
    body,
  };
}
