export const ROOT = "/";
export const SIGNIN = "/signin";
export const DEMO = "/demo";
export const DEFAULT = "/default";
export const MY_ACCOUNT = "/my-account";
export const PAYABLES = "/payables";
export const GL_ACCOUNTS = "/glAccounts";
export const ITEM_CLASSES = "/itemClasses";
export const COMPANIES = "/companies";
export const EXPORTS = '/exports';
export const PRIORITY_LEVELS = '/priorityLevels';
export const VENDORS = '/vendors';
export const JOURNAL_ENTRIES = '/journalEntries';
export const REPORTS = '/reports';
