import { Fab } from "@material-ui/core";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import AddIcon from "@material-ui/icons/Add";
import FormGlAccount from "components/FormGlAccount";
// custom
import GlAccountsList from "components/GlAccountsList";
// reactor
import Page from "components/Page";
import PropTypes from "prop-types";
import React from "react";
// styles
import styles from "./styles";

class WrapperGlAccountPage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    loading: PropTypes.bool,
    glAccounts: PropTypes.array,
    getGlAccounts: PropTypes.func,
    createGlAccount: PropTypes.func,
    deleteGlAccount: PropTypes.func,
    updateGlAccount: PropTypes.func,
    companies: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedGlAccount: undefined,
      formGlAccountOpen: false,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const { glAccounts, history, getGlAccounts, companies } = this.props;

    switch (index) {
      case 0:
        return (
          <GlAccountsList
            history={history}
            companies={companies}
            getGlAccounts={getGlAccounts}
            glAccounts={glAccounts}
            onSelect={(selectedGlAccount) =>
              this.setState({
                selectedGlAccount,
                formGlAccountOpen: true,
              })
            }
          />
        );
      default:
    }

    return [];
  }

  render() {
    const {
      classes,
      history,
      loading,
      createGlAccount,
      deleteGlAccount,
      updateGlAccount,
      refresh,
      companies,
    } = this.props;

    const { index, selectedGlAccount, formGlAccountOpen } = this.state;

    return (
      <Page
        helmet="GlAccounts"
        loading={loading}
        fab={
          <Fab
            variant="fab"
            color="primary"
            aria-label="Add"
            onClick={() => this.setState({ formGlAccountOpen: true })}
          >
            <AddIcon />
          </Fab>
        }
        loadingMessage={"Fetching all GlAccounts"}
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({
                index: v,
              });
              history.push(`?index=${v}`);
            }}
            indicatorColor="primary"
            textColor="primary"
            scrollable
            scrollButtons="auto"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            <Tab
              label="All"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        }
      >
        {this.getStepContent()}
        <FormGlAccount
          open={formGlAccountOpen}
          glAccount={selectedGlAccount}
          close={() => {
            this.setState({
              formGlAccountOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedGlAccount: undefined,
                }),
              200
            );
          }}
          createGlAccount={createGlAccount}
          deleteGlAccount={deleteGlAccount}
          updateGlAccount={updateGlAccount}
          companies={companies}
          refresh={refresh}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperGlAccountPage);
