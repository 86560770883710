import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import { withStyles } from '@material-ui/core/styles';

// constants
import { META_ONLY } from 'constants/methods';

// styles
import styles from './styles';

class Require extends React.Component {
  static propTypes = {
    children: PropTypes.node,
  };

  static contextTypes = {
    checkAccess: PropTypes.func,
  };

  state = {
    haveAccess: false,
  };

  componentWillMount() {
    const funcToTest = [];
    const { props } = this;
    for (const k in this.props) {
      if (props.hasOwnProperty(k)) {
        const prop = props[k];
        if (typeof prop === 'function') {
          funcToTest.push(prop);
        }
      }
    }

    this.checkAccess(funcToTest);
  }

  async checkAccess(funcToTest) {
    const { checkAccess } = this.context;
    let haveAccess = true;
    let respReceived = 0;
    const updateHaveAccess = (level, meta) => {
      haveAccess = haveAccess && level && level.payload && level.payload[meta.method];
      respReceived += 1;

      if (respReceived === funcToTest.length) {
        this.setState({ haveAccess });
      }
    };

    for (const k in funcToTest) {
      if (funcToTest.hasOwnProperty(k)) {
        const func = funcToTest[k];
        const meta = await func(META_ONLY);
        checkAccess({
          method: meta.method,
          service: meta.service,
          path: meta.url,
        }).then((level) => updateHaveAccess(level, meta));
      }
    }
  }

  render() {
    const { haveAccess } = this.state;
    const { children } = this.props;
    // return children;

    return haveAccess ? children : [];
  }
}

export default withStyles(styles)(Require);
