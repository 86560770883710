// material-ui
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import Badge from "@material-ui/core/Badge";
import Grid from "@material-ui/core/Grid";
// material-ui
import BeenhereIcon from "@material-ui/icons/Beenhere";
import File from "@material-ui/icons/InsertDriveFileTwoTone";
import List from "@material-ui/icons/List";
import Lock from "@material-ui/icons/Lock";
import LockOpen from "@material-ui/icons/LockOpenTwoTone";
import { Tooltip } from "@material-ui/core";
import { REACT_APP_API_PREFIX } from "config";
import AdvancedSearch from "components/AdvancedSearchRefactor";

// custom
import Table from "components/TablePagination";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class JournalEntriesList extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    companies: PropTypes.array,
    users: PropTypes.array,
    refresh: PropTypes.func,
    journalEntries: PropTypes.array,
    selectedJournalEntry: PropTypes.object,
    history: PropTypes.object,
    onSelect: PropTypes.func,
    groupSelectEnabled: PropTypes.bool,
    onGroupSelect: PropTypes.func,
  };

  onSelect(selectedJournalEntry) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedJournalEntry);
    }
  }

  render() {
    const { journalEntries, refresh, companies, users, history, groupSelectEnabled, onGroupSelect, selectedJournalEntry, } = this.props;

    const noAdvancedSearch = false;

    const TABLE = (
      <Table
        data={journalEntries}
        groupSelectEnabled={ groupSelectEnabled }
        onGroupSelect={onGroupSelect}
        rowStyle={(d) => ({
          borderLeft: d.locked ? "solid 3px #4caf50" : "solid 3px #9e9e9e",
          background:
            selectedJournalEntry && selectedJournalEntry.id === d.id
              ? "rgba(33,150,243, 0.1)"
              : undefined,
        })}
        meta={[
          {
            path: "locked",
            title: "Status",
            width: 100,
            render: (t, d) => (
              <Grid container spacing={1} alignItems="center">
                {d.journalEntryItems && d.journalEntryItems.length ? (
                  <Grid item>
                    <Badge badgeContent={d.journalEntryItems.length}>
                      <List
                        style={{ fontSize: 18 }}
                      />
                    </Badge>
                  </Grid>
                ) : (
                  <Grid item>
                    <List
                      style={{ fontSize: 18, opacity: 0.3 }}
                    />
                  </Grid>
                )}
                {d.files && d.files.length ? (
                  <Grid item>
                    <Badge badgeContent={d.files.length}>
                      <File
                        style={{ fontSize: 18 }}
                      />
                    </Badge>
                  </Grid>
                ) : (
                  <Grid item>
                    <File
                      style={{ fontSize: 18, opacity: 0.3 }}
                    />
                  </Grid>
                )}
                {d.locked ? (
                  <Grid item>
                    <Badge>
                      <Lock style={{ fontSize: 18 }} />
                    </Badge>
                  </Grid>
                ) : (
                  <Grid item>
                    <Badge>
                      <LockOpen style={{ fontSize: 18 }} />
                    </Badge>
                  </Grid>
                )}
                {d.exportID ? (
                  <Grid item>
                    <Badge>
                      <Tooltip title={`Exported on batch #${d.exportID}`}>
                        <BeenhereIcon style={{ fontSize: 18 }} />
                      </Tooltip>
                    </Badge>
                  </Grid>
                ) : (
                  []
                )}
              </Grid>
            ),
          },
          {
            path: "id",
            title: "ID",
            numeric: true,
          },
          {
            path: "companyName",
            title: "Company",
            numeric: false,
          },
          {
            path: "referenceNumber",
            title: "Reference Number",
            numeric: false,
          },
          {
            path: "date",
            title: "Date",
            numeric: false,
            render: (d) => (
              <>
                <Typography variant="body2">{moment(d).format("ll")}</Typography>
              </>
            ),
          },
          {
            path: "createdBy",
            title: "Created By",
            numeric: false,
            transform: (t) => `${t ? t.firstName: ""} ${t ? t.lastName : ""}`,
            render: (t, d) => (
              <>
                <Typography variant="body2">{t}</Typography>
                <Typography variant="caption" color="textSecondary">
                  {moment(d.createdAt).format("lll")}
                </Typography>
              </>
            ),
          },
        ]}
        title={"JournalEntries"}
        onRowSelect={this.onSelect.bind(this)}
      />
    );
    if (noAdvancedSearch) {
      return TABLE;
    }
    return (
      <AdvancedSearch
        history={history}
        rowCount={journalEntries.length}
        location={window.location}
        disabled={false}
        refresh={refresh}
        keyword="journalEntries"
        metaDataEndpoint={`${REACT_APP_API_PREFIX}/ap-dashboard/journalEntries/searchMetadata`}
        metaDataOveride={{
          companyID: {
            type: "autocomplete",
            data: companies,
            valueKey: "id",
            labelKey: "name",
          },
          createdByUUID: {
            type: "autocomplete",
            data: users,
            valueKey: "uuid",
            labelKey: "email",
          },
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {TABLE}
          </Grid>
        </Grid>
      </AdvancedSearch>
    );

  }
}

export default withStyles(styles)(JournalEntriesList);
