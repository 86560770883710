import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
// material-ui
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
// custom
import Deletable from "components/Deletable";
// helpers
import { getJsonFromUrl } from "helpers";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

const symbols = [
  { symbol: "=", value: "eq" },
  { symbol: "≠", value: "neq" },
  { symbol: ">", value: "gt" },
  { symbol: "<", value: "lt" },
  { symbol: "≥", value: "gte" },
  { symbol: "≤", value: "lte" },
];

const symbolsDict = {
  eq: "=",
  neq: "≠",
  gt: ">",
  lt: "<",
  gte: "≥",
  lte: "≤",
};

const searchItems = [
  {
    name: "lockable",
    label: "Lockable",
    default: true,
    type: "bool",
    comparison: "eq",
  },
  {
    name: "locked",
    label: "Locked",
    default: true,
    type: "bool",
    comparison: "eq",
  },
  {
    name: "approved",
    label: "Approved",
    default: true,
    type: "bool",
    comparison: "eq",
  },
  {
    name: "dualApproved",
    label: "Dual Approved",
    default: true,
    type: "bool",
    comparison: "eq",
  },
  {
    name: "amount",
    label: "Amount",
    default: 0,
    type: "number",
    comparison: "eq",
  },
  {
    name: "generalLedgerAccountID",
    label: "General Ledger Account ID",
    default: 0,
    type: "number",
    comparison: "eq",
  },
  {
    name: "vendorID",
    label: "Vendor ID",
    default: 0,
    type: "number",
    comparison: "eq",
  },
  {
    name: "memo",
    label: "Memo",
    default: "",
    type: "string",
    comparison: "like",
  },
  {
    name: "referenceNumber",
    label: "Reference Number",
    default: "",
    type: "string",
    comparison: "like",
  },
  {
    name: "date",
    label: "Date",
    default: "",
    type: "date",
    comparison: "eq",
  },
  {
    name: "createdAt",
    label: "Created At",
    default: "",
    type: "date",
    comparison: "eq",
  },
  {
    name: "dueDate",
    label: "Due Date",
    default: "",
    type: "date",
    comparison: "eq",
  },
  {
    name: "updatedAt",
    label: "Updated Date",
    default: "",
    type: "date",
    comparison: "eq",
  },
  {
    name: "approvedDate",
    label: "Approved Date",
    default: "",
    type: "date",
    comparison: "eq",
  },
  {
    name: "dualApprovedDate",
    label: "Dual Approved Date",
    default: "",
    type: "date",
    comparison: "eq",
  },
  {
    name: "lockedDate",
    label: "Locked Date",
    default: "",
    type: "date",
    comparison: "eq",
  },
];

class AdvancedSearch extends Component {
  static propTypes = {
    classes: PropTypes.object,
    history: PropTypes.object,
    refresh: PropTypes.func,
    location: PropTypes.object,
    filters: PropTypes.array,
  };

  state = {};

  getItem(name) {
    const { filters } = this.props;
    const index = filters.findIndex((f) => f.name === name);
    if (index !== -1) {
      return filters[index];
    }

    return {};
  }

  getSearchItem(name) {
    const index = searchItems.findIndex((f) => f.name === name);
    if (index !== -1) {
      return searchItems[index];
    }

    return {};
  }

  handleClick = (e) => {
    //eslint-disable-line
    this.setState({ anchorEl: e.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  updateFilters(newFilters) {
    const { history, location } = this.props;
    const urlParams = getJsonFromUrl(location);
    delete urlParams[""];
    urlParams.filters = JSON.stringify(newFilters);
    const search = Object.keys(urlParams)
      .map(
        (k) => `${encodeURIComponent(k)}=${encodeURIComponent(urlParams[k])}`
      )
      .join("&");
    history.push({
      search,
    });
  }

  removeField = (name) => () => {
    const { filters } = this.props;
    const index = filters.findIndex((f) => f.name === name);
    filters.splice(index, 1);
    this.updateFilters(filters);
  };

  handleChange = (name, type, comparison) => (event) => {
    const { target } = event;
    const { value } = target;
    const { filters } = this.props;
    const index = filters.findIndex((f) => f.name === name);
    const searchItem = this.getSearchItem(name);
    if (index !== -1) {
      // Filters already exist
      if (value !== 0) filters[index].value = value;
      if (type === "date")
        filters[index].value = moment(value).format("YYYY-MM-DD HH:mm:ss");
      if (comparison !== undefined) filters[index].comparison = comparison;
    } else {
      // Filters is new
      filters.push({
        name,
        comparison: searchItem.comparison,
        value,
      });
    }

    this.setState({ [`anchorEl_${name}`]: null });
    this.updateFilters(filters);
  };

  handleCheckboxChange = (name) => (event) => {
    const { target } = event;
    const { checked } = target;
    const { filters } = this.props;
    const index = filters.findIndex((f) => f.name === name);
    if (index !== -1) {
      // Filters already exist
      filters[index].value = checked;
    } else {
      // Filters is new
      filters.push({
        name,
        comparison: "eq",
        value: checked,
      });
    }

    this.updateFilters(filters);
  };

  getSearchField(type, name) {
    const { classes } = this.props;
    const item = this.getItem(name);
    const searchItem = this.getSearchItem(name);

    if (item.name === undefined) {
      return [];
    }

    switch (type) {
      case "bool":
        return (
          <Grid key={name} item>
            <Deletable onDelete={this.removeField(name)}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={item.value || false}
                    onChange={this.handleCheckboxChange(name)}
                    value={name}
                    className={classes.checkboxPadding}
                    color="primary"
                  />
                }
                className={classes.noPadding}
                label={searchItem.label}
              />
            </Deletable>
          </Grid>
        );
      case "number":
        return (
          <Grid key={name} item>
            <Deletable onDelete={this.removeField(name)}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <Typography display="block" variant="body2">
                    {searchItem.label}
                  </Typography>
                </Grid>
                <Grid item>
                  <Chip
                    label={symbolsDict[item.comparison]}
                    color="primary"
                    style={{ height: 25, width: 25 }}
                    onClick={(e) =>
                      this.setState({ [`anchorEl_${name}`]: e.currentTarget })
                    }
                  />
                  <Menu
                    id="simple-menu"
                    anchorEl={this.state[`anchorEl_${name}`]}
                    open={Boolean(this.state[`anchorEl_${name}`])}
                    onClose={() =>
                      this.setState({ [`anchorEl_${name}`]: null })
                    }
                  >
                    {symbols.map((s) => (
                      <MenuItem
                        key={s.value}
                        onClick={this.handleChange(name, undefined, s.value)}
                      >
                        {s.symbol}
                      </MenuItem>
                    ))}
                  </Menu>
                </Grid>
                <Grid item>
                  <InputBase
                    value={`${item.value}`}
                    onChange={this.handleChange(name)}
                    style={{
                      width: `${item.value}`.length * 9 || 9,
                      background: "rgba(155,155,155,0.3)",
                      borderRadius: 8,
                      padding: "0px 4px 0px 4px",
                    }}
                  />
                </Grid>
              </Grid>
            </Deletable>
          </Grid>
        );
      case "string":
        return (
          <Grid key={name} item>
            <Deletable onDelete={this.removeField(name)}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <Typography display="block" variant="body2">
                    {searchItem.label}:
                  </Typography>
                </Grid>
                <Grid item>
                  <InputBase
                    value={`${item.value}`}
                    onChange={this.handleChange(name)}
                    style={{
                      width: `${item.value}`.length * 8 || 8,
                      background: "rgba(155,155,155,0.3)",
                      borderRadius: 8,
                      padding: "0px 4px 0px 4px",
                    }}
                  />
                </Grid>
              </Grid>
            </Deletable>
          </Grid>
        );
      case "date":
        return (
          <Grid key={name} item>
            <Deletable onDelete={this.removeField(name)}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <Typography display="block" variant="body2">
                    {searchItem.label}
                  </Typography>
                </Grid>
                <Grid item>
                  <Chip
                    label={symbolsDict[item.comparison]}
                    color="primary"
                    style={{ height: 25, width: 25 }}
                    onClick={(e) =>
                      this.setState({ [`anchorEl_${name}`]: e.currentTarget })
                    }
                  />
                  <Menu
                    id="simple-menu"
                    anchorEl={this.state[`anchorEl_${name}`]}
                    open={Boolean(this.state[`anchorEl_${name}`])}
                    onClose={() =>
                      this.setState({ [`anchorEl_${name}`]: null })
                    }
                  >
                    {symbols.map((s) => (
                      <MenuItem
                        key={s.value}
                        onClick={this.handleChange(name, undefined, s.value)}
                      >
                        {s.symbol}
                      </MenuItem>
                    ))}
                  </Menu>
                </Grid>
                <Grid item>
                  <InputBase
                    value={moment(item.value).format("YYYY-MM-DD")}
                    type="date"
                    onChange={this.handleChange(name, "date")}
                    style={{
                      width: 150,
                      background: "rgba(155,155,155,0.3)",
                      borderRadius: 8,
                      padding: "0px 4px 0px 4px",
                    }}
                  />
                </Grid>
              </Grid>
            </Deletable>
          </Grid>
        );
      default:
        return [];
    }
  }

  render() {
    const { classes, refresh, location, history, filters } = this.props;
    const { anchorEl } = this.state;

    const searchItemsToAdd = searchItems.filter(
      (si) => this.getItem(si.name).name === undefined
    );

    return (
      <div>
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <Chip
              label={"+ Search Item"}
              variant="outlined"
              onClick={this.handleClick}
            />
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={this.handleClose}
            >
              {searchItemsToAdd &&
                searchItemsToAdd.map((sc) => (
                  <MenuItem
                    key={`${sc.name}`}
                    onClick={() => {
                      this.handleClose();
                      this.handleChange(sc.name)({
                        target: { value: sc.default },
                      });
                    }}
                  >
                    {sc.label}
                  </MenuItem>
                ))}
            </Menu>
          </Grid>
          {searchItems.map((si) => this.getSearchField(si.type, si.name))}
        </Grid>
        {/* <br />
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            updateFilters
            const urlParams = getJsonFromUrl(location);
            urlParams.filters = JSON.stringify(filters);
            const search = Object.keys(urlParams).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(urlParams[k])}`).join('&');
            history.push({
              search,
            });
          }}
        >
          Search
        </Button> */}
        {/* <Typography display="block" variant="caption" color="textSecondary">
          {JSON.stringify(filters)}
        </Typography> */}
      </div>
    );
  }
}

export default withStyles(styles)(AdvancedSearch);
