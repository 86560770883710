export const routeNames = [
  "Default",
  "Signin",
  "MyAccount",
  "Payables",
  "Companies",
  "ItemClasses",
  "GlAccounts",
  "Exports",
  "Vendors",
  "JournalEntries",
  "Reports",
];
