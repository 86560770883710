import {
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import CloseIcon from "@material-ui/icons/Close";
import { Autocomplete } from "@material-ui/lab";
import LineItem from "hooks/LineItem";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class FormVendor extends Component {
  static propTypes = {
    classes: PropTypes.object,
    fullScreen: PropTypes.bool,

    close: PropTypes.func,
    open: PropTypes.bool,
    refresh: PropTypes.func,

    //vendors
    vendor: PropTypes.object,
    createVendor: PropTypes.func,
    updateVendor: PropTypes.func,
    deleteVendor: PropTypes.func,

    //vendorItems
    getVendorLineItems: PropTypes.func,
    createVendorLineItem: PropTypes.func,
    deleteVendorLineItem: PropTypes.func,
    updateVendorLineItem: PropTypes.func,

    //arrays
    glAccounts: PropTypes.array,
    itemClasses: PropTypes.array,
    companies: PropTypes.array,
    users: PropTypes.array,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = this.getInitialState();
  }

  getInitialState() {
    return {
      loading: false,
      name: "",
      addr1: "",
      addr2: "",
      addr3: "",
      addr4: "",
      addr5: "",
      companyID: undefined,
      cont1: "",
      contact: "",
      email: "",
      faxNum: "",
      firstName: "",
      lastName: "",
      hidden: false,
      limit: "",
      name: "",
      note: "",
      phone1: "",
      phone2: "",
      printAs: "",
      refNum: undefined,
      taxID: "",
      tenNintyNine: false,
      websitePassword: "",
      websitePortal: "",
      websiteUsername: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.init(nextProps.vendor);
    }
  }

  init(vendor) {
    const { users } = this.props;
    this.setState({
      loading: false,
      name: vendor ? vendor.name : "",
      addr1: vendor ? vendor.addr1 : "",
      addr2: vendor ? vendor.addr2 : "",
      addr3: vendor ? vendor.addr3 : "",
      addr4: vendor ? vendor.addr4 : "",
      addr5: vendor ? vendor.addr5 : "",
      companyID: vendor ? vendor.companyID : "",
      cont1: vendor ? vendor.cont1 : "",
      contact: vendor ? vendor.contact : "",
      email: vendor ? vendor.email : "",
      faxNum: vendor ? vendor.faxNum : "",
      firstName: vendor ? vendor.firstName : "",
      lastName: vendor ? vendor.lastName : "",
      hidden: false,
      limit: vendor ? vendor.limit : "",
      note: vendor ? vendor.note : "",
      phone1: vendor ? vendor.phone1 : "",
      phone2: vendor ? vendor.phone2 : "",
      printAs: vendor ? vendor.printAs : "",
      refNum: vendor ? `${vendor.refNum}` : "",
      taxID: vendor ? vendor.taxID : "",
      tenNintyNine: vendor.tenNintyNine,
      websitePassword: vendor ? vendor.websitePassword : "",
      websitePortal: vendor ? vendor.websitePortal : "",
      websiteUsername: vendor ? vendor.websiteUsername : "",
      defaultAssignedToUUID: vendor ? vendor.defaultAssignedToUUID : "",
      defaultDualAssignedToUUID: vendor ? vendor.defaultDualAssignedToUUID : "",
      assign: users.find((u) => u.uuid === vendor.defaultAssignedToUUID),
      dualAssign: users.find(
        (u) => u.uuid === vendor.defaultDualAssignedToUUID
      ),
    });
    this.getVendorLineItems(vendor);
  }

  async getVendorLineItems(vendor) {
    const { getVendorLineItems } = this.props;
    if (vendor && vendor.id) {
      const r = await getVendorLineItems([
        { name: "vendorID", comparison: "eq", value: vendor.id },
      ]);
      if (r.success) {
        this.setState({
          defaultLineItems: r.payload,
        });
      }
    }
  }

  handleChange = (name) => (event) => {
    const value = event.target.value;

    this.setState({
      [name]: value,
    });
  };

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: `Are you sure ?`,
        subtitle: "If you delete this vendor, you won't be able to recover it.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { deleteVendor, close, refresh, vendor } = this.props;

    const { NotificationCenter } = this.context;
    close();
    const resp = await deleteVendor(vendor.id);
    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: "Vendor has been deleted.",
        success: true,
        timestamp: new Date().getTime(),
      });
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  async save() {
    const { updateVendor, createVendor, vendor, refresh, close } = this.props;

    const {
      name,
      addr1,
      addr2,
      addr3,
      addr4,
      addr5,
      companyID,
      cont1,
      contact,
      email,
      faxNum,
      firstName,
      lastName,
      hidden,
      limit,
      note,
      phone1,
      phone2,
      printAs,
      taxID,
      tenNintyNine,
      websitePassword,
      websitePortal,
      websiteUsername,
      refNum,
      defaultLineItems,
      defaultAssignedToUUID,
      defaultDualAssignedToUUID,
    } = this.state;

    const { NotificationCenter } = this.context;

    const body = {
      name,
      addr1,
      addr2,
      addr3,
      addr4,
      addr5,
      companyID: Number(companyID),
      cont1,
      contact,
      email,
      faxNum,
      firstName,
      lastName,
      hidden,
      limit,
      note,
      phone1,
      phone2,
      printAs,
      refNum: Number(refNum),
      taxID,
      tenNintyNine,
      websitePassword,
      websitePortal,
      websiteUsername,
      defaultAssignedToUUID,
      defaultDualAssignedToUUID,
    };

    let resp = undefined;
    this.setState({ loading: true });
    if (vendor) {
      resp = await updateVendor(vendor.id, body);
    } else {
      resp = await createVendor(body);
    }

    this.setState({ loading: false });

    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: vendor ? "Vendor has been updated." : "Vendor has been created.",
        success: true,
        timestamp: new Date().getTime(),
      });

      close();
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  async addLineItem() {
    const { createVendorLineItem, vendor } = this.props;
    await createVendorLineItem({
      vendorID: vendor.id,
      glAccountID: 1,
      classID: 1,
    });
    this.getVendorLineItems(vendor);
  }

  async updateVendorLineItem(lineItem) {
    const { updateVendorLineItem, vendor } = this.props;
    await updateVendorLineItem(lineItem.id, lineItem);
    this.getVendorLineItems(vendor);
  }

  async deleteVendorLineItem(lineItem) {
    const { deleteVendorLineItem, vendor } = this.props;
    await deleteVendorLineItem(lineItem.id);
    this.getVendorLineItems(vendor);
  }

  render() {
    const {
      classes,
      fullScreen,
      open,
      vendor,
      close,
      glAccounts,
      itemClasses,
      companies,
      users,
      updateVendor,
    } = this.props;

    let company =
      companies && companies.find((c) => c.id === this.state.companyID);

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={close}
        scroll="paper"
        maxWidth="lg"
        fullWidth
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {vendor ? `${vendor.name}` : "Create a new Vendor"}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Identity</Typography>
              <Divider />
              <Grid container spacing={1} style={{ marginTop: 6 }}>
                <Grid item xs={12} md={6}>
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    label="Name"
                    variant="filled"
                    size="small"
                    fullWidth
                    value={this.state.name}
                    onChange={this.handleChange("name")}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    label="Print As"
                    variant="filled"
                    size="small"
                    fullWidth
                    value={this.state.printAs}
                    onChange={this.handleChange("printAs")}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    label="Ref Num"
                    variant="filled"
                    size="small"
                    fullWidth
                    value={this.state.RefNum}
                    onChange={this.handleChange("RefNum")}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  {/* <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    label="Company"
                    variant="filled"
                    size="small"
                    fullWidth
                    value={this.state.companyID}
                    onChange={this.handleChange("companyID")}
                  /> */}
                  <Autocomplete
                    value={company}
                    disabled
                    onChange={(e, v) => {
                      this.setState({
                        companyID: v ? v.id : "",
                        company: v,
                      });
                    }}
                    inputValue={this.state.companyInput}
                    onInputChange={(event, newInputValue) => {
                      this.setState({ companyInput: newInputValue });
                    }}
                    options={companies}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        size="small"
                        variant="filled"
                        label="Company"
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <br />
              <Typography variant="subtitle1">Contact Info</Typography>
              <Divider />
              <Grid container spacing={1} style={{ marginTop: 6 }}>
                <Grid item xs={12}>
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    label="Job Title"
                    variant="filled"
                    size="small"
                    fullWidth
                    value={this.state.contact}
                    onChange={this.handleChange("contact")}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    label="First Name"
                    variant="filled"
                    size="small"
                    fullWidth
                    value={this.state.firstName}
                    onChange={this.handleChange("firstName")}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    label="Last Name"
                    variant="filled"
                    size="small"
                    fullWidth
                    value={this.state.lastName}
                    onChange={this.handleChange("lastName")}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    label="Email"
                    variant="filled"
                    size="small"
                    fullWidth
                    value={this.state.email}
                    onChange={this.handleChange("email")}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    label="Fax"
                    variant="filled"
                    size="small"
                    fullWidth
                    value={this.state.faxNum}
                    onChange={this.handleChange("faxNum")}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    label="Phone 1"
                    variant="filled"
                    size="small"
                    fullWidth
                    value={this.state.phone1}
                    onChange={this.handleChange("phone1")}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    label="Phone 2"
                    variant="filled"
                    size="small"
                    fullWidth
                    value={this.state.phone2}
                    onChange={this.handleChange("phone2")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    label="Contract"
                    variant="filled"
                    size="small"
                    fullWidth
                    value={this.state.contract}
                    onChange={this.handleChange("contract")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    label="Website"
                    variant="filled"
                    size="small"
                    fullWidth
                    value={this.state.websitePortal}
                    onChange={this.handleChange("websitePortal")}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    label="Username"
                    variant="filled"
                    size="small"
                    fullWidth
                    value={this.state.websiteUsername}
                    onChange={this.handleChange("websiteUsername")}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    label="Password"
                    variant="filled"
                    size="small"
                    fullWidth
                    value={this.state.websitePassword}
                    onChange={this.handleChange("websitePassword")}
                  />
                </Grid>
              </Grid>
              <br />
              <Typography variant="subtitle1">Address</Typography>
              <Divider />
              <Grid container spacing={1} style={{ marginTop: 6 }}>
                <Grid item xs={12}>
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    label="Addr 1"
                    variant="filled"
                    size="small"
                    fullWidth
                    value={this.state.addr1}
                    onChange={this.handleChange("addr1")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    label="Addr 2"
                    variant="filled"
                    size="small"
                    fullWidth
                    value={this.state.addr2}
                    onChange={this.handleChange("addr2")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    label="Addr 3"
                    variant="filled"
                    size="small"
                    fullWidth
                    value={this.state.addr3}
                    onChange={this.handleChange("addr3")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    label="Addr 4"
                    variant="filled"
                    size="small"
                    fullWidth
                    value={this.state.addr4}
                    onChange={this.handleChange("addr4")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    label="Addr 5"
                    variant="filled"
                    size="small"
                    fullWidth
                    value={this.state.addr5}
                    onChange={this.handleChange("addr5")}
                  />
                </Grid>
              </Grid>
              <br />
              <Typography variant="subtitle1">Payment Info</Typography>
              <Divider />
              <Grid container spacing={1} style={{ marginTop: 6 }}>
                <Grid item xs={12} md={6}>
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    label="Tax ID"
                    variant="filled"
                    size="small"
                    fullWidth
                    value={this.state.taxID}
                    onChange={this.handleChange("taxID")}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    label="Limit"
                    variant="filled"
                    size="small"
                    fullWidth
                    value={this.state.limit}
                    onChange={this.handleChange("limit")}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControlLabel
                    InputProps={{
                      readOnly: true,
                    }}
                    control={
                      <Checkbox
                        checked={this.state.tenNintyNine}
                        name="tenNintyNine"
                      />
                    }
                    label="1099"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    label="Note"
                    variant="filled"
                    size="small"
                    fullWidth
                    value={this.state.note}
                    onChange={this.handleChange("note")}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                borderLeft: "solid 1px rgba(155,155,155,0.3)",
              }}
            >
              <Typography variant="subtitle1">Default Line Items</Typography>
              <Divider />
              <Grid container spacing={1} style={{ marginTop: 6 }}>
                {this.state.defaultLineItems &&
                  this.state.defaultLineItems.map((li, index) => (
                    <LineItem
                      lineItem={li}
                      index={Number(index)}
                      companyID={this.state.companyID}
                      glAccounts={glAccounts}
                      company={company}
                      itemClasses={itemClasses}
                      lite
                      update={this.updateVendorLineItem.bind(this)}
                      onDelete={this.deleteVendorLineItem.bind(this)}
                    />
                  ))}
              </Grid>
              <Chip
                style={{ marginTop: 4 }}
                label="+ Line Item"
                size="small"
                variant="outlined"
                onClick={this.addLineItem.bind(this)}
              />

              <Typography variant="subtitle1" style={{ marginTop: 16 }}>
                Default Reviewers
              </Typography>
              <Divider />
              <Autocomplete
                value={this.state.assign}
                onChange={(e, v) => {
                  this.setState({
                    defaultAssignedToUUID: v ? v.uuid : "",
                    assign: v,
                  });
                  updateVendor(vendor.id, {
                    defaultAssignedToUUID: v?.uuid || null,
                  });
                }}
                inputValue={this.state.assignInput}
                onInputChange={(event, newInputValue) => {
                  this.setState({ assignInput: newInputValue });
                }}
                options={users}
                getOptionLabel={(option) =>
                  `${option.firstName} ${option.lastName}`
                }
                renderInput={(params) => {
                  params.InputProps.style = {
                    background: this.state.approvedDate
                      ? "rgba(76,175,80,0.1)"
                      : undefined,
                  };
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      variant={"filled"}
                      label="Default Primary Reviewer"
                    />
                  );
                }}
              />
              <br />
              <Autocomplete
                value={this.state.dualAssign}
                onChange={(e, v) => {
                  this.setState({
                    defaultDualAssignedToUUID: v ? v.uuid : "",
                    dualAssign: v,
                  });
                  updateVendor(vendor.id, {
                    defaultDualAssignedToUUID: v?.uuid || null,
                  });
                }}
                inputValue={this.state.dualAssignInput}
                onInputChange={(event, newInputValue) => {
                  this.setState({ dualAssignInput: newInputValue });
                }}
                options={users}
                getOptionLabel={(option) =>
                  `${option.firstName} ${option.lastName}`
                }
                renderInput={(params) => {
                  params.InputProps.style = {
                    background: this.state.approvedDate
                      ? "rgba(76,175,80,0.1)"
                      : undefined,
                  };
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      variant={"filled"}
                      label="Default Secondary Reviewer"
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {/* {vendor ? (
            <Button className={classes.delete} onClick={this.delete.bind(this)}>
              <DeleteIcon className={classes.leftIcon} /> Delete
            </Button>
          ) : (
            []
          )} */}
          <Button onClick={close} variant="contained">
            <CloseIcon /> Close
          </Button>
          {/* <Button color="primary" autoFocus onClick={this.save.bind(this)}>
            <SaveIcon className={classes.leftIcon} />{" "}
            {vendor ? "Save" : "Create"}
          </Button> */}
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(FormVendor));
