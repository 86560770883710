import { Button, Card, Dialog, DialogTitle, DialogContent, DialogContentText } from "@material-ui/core";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import AddIcon from "@material-ui/icons/Add";
import FormItemClasse from "components/FormItemClasse";
// custom
import ReportsList from "components/ReportsList";
// reactor
import Page from "components/Page";
import PropTypes from "prop-types";
import React from "react";
// styles
import styles from "./styles";
import ReportGenerator from "components/ReportGenerator";

class WrapperReportsPage extends React.Component {
  static propTypes = {
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    generateReport: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      open: false,
    };
  }

  close() {
    this.setState({open: false});
  }

  render() {
    const {
      loading,
      generateReport
    } = this.props;

    const {
      open
    } = this.state;

    return (
      <Page
        helmet="Reports"
        loading={loading}
        loadingMessage={"Generating Report"}
        header={<Button
          color="primary"
          onClick={() => {
            this.setState({open: !open})
          }}
        >Generate</Button>}
      >
        <ReportGenerator
        open={open}
        close={this.close.bind(this)}
        generateReport={generateReport}
        ></ReportGenerator>
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperReportsPage);
