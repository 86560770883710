// material-ui
import { withStyles } from "@material-ui/core/styles";
import AdvancedSearch from "components/AdvancedSearchRefactor";
// custom
import Table from "components/TablePagination";
import { REACT_APP_API_PREFIX } from "config";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class ExportsList extends Component {
  static propTypes = {
    classes: PropTypes.object,
    exports: PropTypes.array,
    onSelect: PropTypes.func,
  };

  onSelect(selectedExport) {
    const { onSelect } = this.props;
    this.setState({ selectedExport });
    if (onSelect) {
      onSelect(selectedExport);
    }
  }

  render() {
    const { exports, history, getExports } = this.props;

    return (
      <AdvancedSearch
        history={history}
        rowCount={exports.length}
        location={window.location}
        refresh={getExports}
        keyword="exports"
        metaDataEndpoint={`${REACT_APP_API_PREFIX}/ap-dashboard/exports/searchMetadata`}
        // metaDataOveride={{
        //   companyID: {
        //     type: "autocomplete",
        //     data: companies,
        //     valueKey: "id",
        //     labelKey: "name",
        //   },
        // }}
      >
        <Table
          data={exports}
          meta={[
            {
              path: "name",
              title: "Name",
              numeric: false,
            },
            {
              path: "createdBy",
              title: "Created By",
              numeric: false,
              transform: (t) => `${t.firstName} ${t.lastName}`,
            },
            {
              path: "createdAt",
              title: "Date",
              numeric: false,
              transform: (t) => moment(t).format("lll"),
            },
          ]}
          title={"Exports"}
          onRowSelect={this.onSelect.bind(this)}
        />
      </AdvancedSearch>
    );
  }
}

export default withStyles(styles)(ExportsList);
