export const GET_ORGANIZATIONS = 'GET_ORGANIZATIONS';
export const GET_ORGANIZATION_BY_ID = 'GET_ORGANIZATION_BY_ID';
export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION';
export const DELETE_ORGANIZATION = 'DELETE_ORGANIZATION';
export const CREATE_ORGANIZATION = 'CREATE_ORGANIZATION';
export const ADD_USER_TO_ORGANIZATION = 'ADD_USER_TO_ORGANIZATION';
export const GET_ORGANIZATION_USERS = 'GET_ORGANIZATION_USERS';
export const DELETE_ORGANIZATION_USER = 'DELETE_ORGANIZATION_USER';
export const JOIN_ORGANIZATION = 'JOIN_ORGANIZATION';
export const SET_ORGANIZATION_BY_DEFAULT = 'SET_ORGANIZATION_BY_DEFAULT';
