// material-ui
import { withStyles } from "@material-ui/core/styles";
// custom
import Table from "components/Table";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
// styles
import styles from "./styles";
import { Grid, Box, Paper } from "@material-ui/core";

import { createMuiTheme, MuiThemeProvider, styled } from "@material-ui/core/styles";


class ReportsList extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    reports: PropTypes.array,
    onSelect: PropTypes.func,
  };

  onSelect(selectedItemClasse) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedItemClasse);
    }
  }

  render() {
    const { reports } = this.props;

    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });


    const Item = styled(Paper)(({ theme }) => ({
      ...theme.typography.body2,
      textAlign: 'center',
      color: theme.palette.text.secondary,
      height: 160,
      lineHeight: '60px',
    }));

    return (
          <Table
            data={reports}
            meta={[
              {
                path: "period",
                title: "Period",
                numeric: false,
              },
              {
                path: "account",
                title: "Account Name",
                transform: (t) => `${t.name}`,
                numeric: false,
              },
              {
                path: "class",
                title: "Class Name",
                transform: (t) => `${t.name}`,
                numeric: false,
              },
              {
                path: "debits",
                title: "Debit",
                numeric: true,
                render: (t, d) => {
                  return (
                    <React.Fragment>
                      <Typography display="block" variant="body2">
                        {formatter.format(parseFloat(t)/100.0)}
                      </Typography>
                    </React.Fragment>
                  );
                },
              },
              {
                path: "credits",
                title: "Credit",
                numeric: true,
                render: (t, d) => {
                  return (
                    <React.Fragment>
                      <Typography display="block" variant="body2">
                        {formatter.format(parseFloat(t) / 100.0)}
                      </Typography>
                    </React.Fragment>
                  );
                },
              },
            ]}
            title={"Report Entries"}
            onRowSelect={this.onSelect.bind(this)}
          />);
    // );
  }
}

export default withStyles(styles)(ReportsList);
