// material-ui
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
// custom
import Table from "components/Table";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class CompaniesList extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    companies: PropTypes.array,
    onSelect: PropTypes.func,
  };

  onSelect(selectedCompany) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedCompany);
    }
  }

  render() {
    const { companies } = this.props;

    return (
      <Table
        data={companies}
        meta={[
          {
            path: "name",
            title: "Name",
            numeric: false,
          },
          {
            path: "id",
            title: "Lock Date",
            numeric: false,
            render: (t, d) => {
              return (
                <>
                  {d?.glLockDate?.date ? (
                    <Typography variant="body2">
                      {moment(d?.glLockDate?.date).format("ll")}
                      {d.glLockDate.createdBy ? (
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          display="block"
                        >
                          Set by {d.glLockDate.createdBy.firstName}{" "}
                          {d.glLockDate.createdBy.lastName} on{" "}
                          {moment(d?.glLockDate?.updatedAt).format("lll")}
                        </Typography>
                      ) : (
                        []
                      )}
                    </Typography>
                  ) : (
                    []
                  )}
                </>
              );
            },
          },
        ]}
        title={"Companies"}
        onRowSelect={this.onSelect.bind(this)}
      />
    );
  }
}

export default withStyles(styles)(CompaniesList);
