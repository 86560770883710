import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// component
import WrapperPriorityLevelsPage from '../component/WrapperPriorityLevelsPage';

// helpers
import { getJsonFromUrl } from 'helpers';

// actions
import * as Actions from 'actions';

const mapStateToProps = state => ({
  priorityLevels: state.priorityLevels,
});

const mapDispatchToProps = dispatch => ({
  getPriorityLevels: () => dispatch(Actions.getPriorityLevels()),
  getPriorityLevelById: (...args) => dispatch(Actions.getPriorityLevelById(...args)),
  createPriorityLevel: (...args) => dispatch(Actions.createPriorityLevel(...args)),
  deletePriorityLevel: (...args) => dispatch(Actions.deletePriorityLevel(...args)),
  updatePriorityLevel: (...args) => dispatch(Actions.updatePriorityLevel(...args)),
});
class PriorityLevelsPage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    priorityLevels: PropTypes.array,
    getPriorityLevels: PropTypes.func,
    getPriorityLevelById: PropTypes.func,
    createPriorityLevel: PropTypes.func,
    deletePriorityLevel: PropTypes.func,
    updatePriorityLevel: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, priorityLevels } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: priorityLevels.length === 0,
    };
  }

  componentDidMount() {
    console.log('componentDidMount');
    this.refresh();
  }

  async refresh() {
    const { getPriorityLevels } = this.props;
    await Promise.all([
      getPriorityLevels(),
    ]);

    this.setState({ loading: false });
  }

  render() {
    const {
      priorityLevels,
      getPriorityLevelById,
      createPriorityLevel,
      deletePriorityLevel,
      updatePriorityLevel,
    } = this.props;

    const {
      urlParams,
      loading,
    } = this.state;

    return (
      <WrapperPriorityLevelsPage
        refresh={this.refresh.bind(this)}
        loading={loading}
        priorityLevels={priorityLevels}
        getPriorityLevelById={getPriorityLevelById}
        createPriorityLevel={createPriorityLevel}
        deletePriorityLevel={deletePriorityLevel}
        updatePriorityLevel={updatePriorityLevel}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PriorityLevelsPage);
