// actions
import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// component
import WrapperExportsPage from "../component/WrapperExportsPage";

const mapStateToProps = (state) => ({
  exports: state.exports,
});

const mapDispatchToProps = (dispatch) => ({
  getExports: () => dispatch(Actions.getExports()),
  getExportById: (...args) => dispatch(Actions.getExportById(...args)),
  createExport: (...args) => dispatch(Actions.createExport(...args)),
  deleteExport: (...args) => dispatch(Actions.deleteExport(...args)),
  updateExport: (...args) => dispatch(Actions.updateExport(...args)),
});
class ExportsPage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    exports: PropTypes.array,
    getExports: PropTypes.func,
    getExportById: PropTypes.func,
    createExport: PropTypes.func,
    deleteExport: PropTypes.func,
    updateExport: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, exports } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: exports.length === 0,
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.refresh();
  }

  async refresh() {
    const { getExports } = this.props;
    await Promise.all([getExports()]);

    this.setState({ loading: false });
  }

  render() {
    const {
      exports,
      getExportById,
      createExport,
      deleteExport,
      updateExport,
      getExports,
      history,
    } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperExportsPage
        refresh={this.refresh.bind(this)}
        loading={loading}
        getExports={getExports}
        exports={exports}
        history={history}
        getExportById={getExportById}
        createExport={createExport}
        deleteExport={deleteExport}
        updateExport={updateExport}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ExportsPage);
