// *https://www.registers.service.gov.uk/registers/country/use-the-api*
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Popper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { Autocomplete } from "@material-ui/lab";
import React from "react";
import NumberFormat from "react-number-format";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

const PopperMy = function (props) {
  return <Popper {...props} style={{ width: 400 }} placement="bottom-start" />;
};

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

export default function LineItem({
  dragHandle,
  journalEntryItem,
  lite,
  useTable,
  glAccounts,
  itemClasses,
  vendors,
  onDelete,
  index,
  update,
  disabled,
  company,
  companyID,
  uploadLineItemFile,
  setSelectedFile,
}) {
  const [glAccount, setGlAccount] = React.useState(
    glAccounts.find((g) => g.id === journalEntryItem.glAccountID)
  );
  const [glAccountInput, setGlAccountInput] = React.useState("");

  const [itemClass, setItemClass] = React.useState(
    itemClasses.find((g) => g.id === journalEntryItem.classID)
  );
  const [vendor, setVendor] = React.useState(
    vendors.find((g) => g.id === journalEntryItem.vendorID)
  );
  const [itemClassInput, setItemClassInput] = React.useState("");
  const [vendorInput, setVendorInput] = React.useState("");

  const [debit, setDebit] = React.useState(journalEntryItem.debit);
  const [credit, setCredit] = React.useState(journalEntryItem.credit);
  const [id, setID] = React.useState(journalEntryItem.id);
  const [memo, setMemo] = React.useState(journalEntryItem.memo);
  const [files, setFiles] = React.useState(journalEntryItem.files || []);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [internalMemo, setInternalMemo] = React.useState(journalEntryItem.internalMemo);
  const [internalMemoOpen, setInternalMemoOpen] = React.useState(false);

  React.useEffect(() => {
    if (files && files.length && files.length !== journalEntryItem.files.length) {
      onBlur();
    }
  }, [files]);

  const onBlur = () => {
    update(
      {
        glAccountID: glAccount && glAccount.id,
        classID: itemClass && itemClass.id,
        internalMemo,
        debit: Number(debit),
        credit: Number(credit),
        memo: memo,
        vendorID: vendor && vendor.id,
        id: journalEntryItem.id,
        tmpID: journalEntryItem.tmpID,
        files,
      },
      index
    );
  };

  if (useTable) {
    return (<TableRow
      key={id}
      sx={{ border: 1 }}
    >
      <TableCell component="th" scope="row" style={{ padding: "0px 4px 0px 0px", margin: 0 }}>
          <Tooltip title={glAccountInput}>
            <div>
              <Autocomplete
                value={glAccount}
                onChange={(e, v) => setGlAccount(v)}
                inputValue={glAccountInput}
                onInputChange={(event, newInputValue) => {
                  setGlAccountInput(newInputValue);
                }}
                id="controllable-states-demo"
                options={glAccounts.filter((gl) => gl.companyID === companyID)}
                disableClearable
                getOptionLabel={(option) => `${option.accountNumber !== "" ? option.accountNumber + " - " : ""} ${option.name} (${option.type})`}
                renderInput={(params) => {
                  params.InputProps.style = {
                    // fontSize: 12,
                    background: "#f5f5f5",
                    padding: 4,
                    margin: 2,
                  };
                  return <TextField {...params} multiline={false} />;
                }}
                PopperComponent={PopperMy}
              />
              {glAccount && glAccount.companyID !== companyID ? (
                <Typography
                  variant="caption"
                  style={{ color: "#f44336" }}
                >{`Not in ${company && company.name}`}</Typography>
              ) : (
                []
              )}
            </div>
          </Tooltip>
      </TableCell>
      <TableCell style={{ padding: "0px 4px 0px 0px", margin: 0 }}>
        {<TextField
          fullWidth
          value={debit}
          InputProps={{
            inputComponent: NumberFormatCustom,
            style: {
              // fontSize: 12,
              background: "#f5f5f5",
              padding: 4,
              margin: 2,
            },
          }}
        />}
      </TableCell>
      <TableCell style={{ padding: "0px 4px 0px 0px", margin: 0 }}>
        {<TextField
          fullWidth
          value={credit}
          InputProps={{
            inputComponent: NumberFormatCustom,
            style: {
              // fontSize: 12,
              background: "#f5f5f5",
              padding: 4,
              margin: 2,
            },
          }}
        />}
      </TableCell>
      <TableCell style={{ padding: "0px 4px 0px 0px", margin: 0}}>
        <div >
          <Tooltip title={itemClassInput}>
            <div>
              <Autocomplete
                value={itemClass}
                onChange={(e, v) => setItemClass(v)}
                disableClearable
                inputValue={itemClassInput}
                onInputChange={(event, newInputValue) => {
                  setItemClassInput(newInputValue);
                }}
                disabled={disabled}
                id="controllable-states-demo"
                options={itemClasses.filter((ic) => ic.companyID === companyID)}
                onBlur={onBlur}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => {
                  params.InputProps.style = {
                    background: "#f5f5f5",
                    padding: 4,
                    margin: 2,
                  };
                  return <TextField {...params} multiline={false} />;
                }}
                PopperComponent={PopperMy}
              />
              {itemClass && itemClass.companyID !== companyID ? (
                <Typography
                  variant="caption"
                  style={{ color: "#f44336" }}
                >{`Not in ${company && company.name}`}</Typography>
              ) : (
                []
              )}
            </div>
          </Tooltip>
          </div>
        </TableCell>
      <TableCell style={{ padding: "0px 4px 0px 0px", margin: 0 }}>
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "stretch",
            position: "relative",
            padding: 0,
          }}
        >
        <TextField
          fullWidth
          disabled={disabled}
          value={memo}
          onBlur={onBlur}
          InputProps={{
            style: {
              // fontSize: 12,
              background: "#f5f5f5",
              padding: 4,
              margin: 2,
            },
          }}
          onChange={(e) => setMemo(e.target.value)}
        />
        {!disabled ? (
          <IconButton
            tabIndex={-1}
            style={{ padding: 4 }}
            onClick={() => onDelete(journalEntryItem, index)}
          >
            <HighlightOffIcon style={{ fontSize: 18 }} />
          </IconButton>
        ) : (
          []
        )}
        </div>
      </TableCell>
    </TableRow>)
  }

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        alignItems: "stretch",
        position: "relative",
      }}
    >
      {dragHandle ? (
        <div style={{ color: "#9e9e9e", display: "flex", marginTop: 12 }}>
          <DragIndicatorIcon style={{ fontSize: 14 }} />
        </div>
      ) : (
        []
      )}
      <div style={{
        flex: 3,
        margin: 2
        }}>
        <Tooltip title={glAccountInput}>
          <div>
            <Autocomplete
              value={glAccount}
              onChange={(e, v) => setGlAccount(v)}
              inputValue={glAccountInput}
              onInputChange={(event, newInputValue) => {
                setGlAccountInput(newInputValue);
              }}
              disabled={disabled}
              onBlur={onBlur}
              id="controllable-states-demo"
              options={glAccounts.filter((gl) => gl.companyID === companyID)}
              disableClearable
              getOptionLabel={(option) => `${option.name} (${option.type})`}
              renderInput={(params) => {
                params.InputProps.style = {
                  // fontSize: 12,
                  background: "#f5f5f5",
                  padding: 4,
                };
                return <TextField {...params} multiline={false} />;
              }}
              PopperComponent={PopperMy}
            />
            {glAccount && glAccount.companyID !== companyID ? (
              <Typography
                variant="caption"
                style={{ color: "#f44336" }}
              >{`Not in ${company && company.name}`}</Typography>
            ) : (
              []
            )}
          </div>
        </Tooltip>
      </div>
      {<TextField
          fullWidth
          style={{
            flex: 1,
            maxWidth: 150,
            margin: 2,
          }}
          disabled={disabled}
          onBlur={onBlur}
          value={debit}
          onChange={(e) => setDebit(e.target.value)}
          InputProps={{
            inputComponent: NumberFormatCustom,
            style: {
              // fontSize: 12,
              background: "#f5f5f5",
              padding: 4,
            },
          }}
        />}
      {<TextField
          fullWidth
          style={{
            flex: 1,
            margin: 2,
            maxWidth: 150,
          }}
          disabled={disabled}
          onBlur={onBlur}
          value={credit}
          onChange={(e) => setCredit(e.target.value)}
          InputProps={{
            inputComponent: NumberFormatCustom,
            style: {
              // fontSize: 12,
              background: "#f5f5f5",
              padding: 4,
            },
          }}
        />}
      <div style={{
        flex: 2,
        margin: 2,
        }}>
        <Tooltip title={itemClassInput}>
          <div>
            <Autocomplete
              value={itemClass}
              onChange={(e, v) => setItemClass(v)}
              disableClearable
              inputValue={itemClassInput}
              onInputChange={(event, newInputValue) => {
                setItemClassInput(newInputValue);
              }}
              disabled={disabled}
              id="controllable-states-demo"
              options={itemClasses.filter((ic) => ic.companyID === companyID)}
              onBlur={onBlur}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => {
                params.InputProps.style = {
                  background: "#f5f5f5",
                  padding: 4,
                };
                return <TextField {...params} multiline={false} />;
              }}
              PopperComponent={PopperMy}
            />
            {itemClass && itemClass.companyID !== companyID ? (
              <Typography
                variant="caption"
                style={{ color: "#f44336" }}
              >{`Not in ${company && company.name}`}</Typography>
            ) : (
              []
            )}
          </div>
        </Tooltip>
      </div>
      <div style={{
        flex: 2,
        margin: 2,
      }}>
        <Tooltip title={vendorInput}>
          <div>
            <Autocomplete
              value={vendor}
              onChange={(e, v) => setVendor(v)}
              disableClearable
              inputValue={vendorInput}
              onInputChange={(event, newInputValue) => {
                setVendorInput(newInputValue);
              }}
              disabled={disabled}
              id="controllable-states-demo"
              options={vendors.filter((ic) => ic.companyID === companyID)}
              onBlur={onBlur}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => {
                params.InputProps.style = {
                  background: "#f5f5f5",
                  padding: 4,
                };
                return <TextField {...params} multiline={false} />;
              }}
              PopperComponent={PopperMy}
            />
            {vendor && vendor.companyID !== companyID ? (
              <Typography
                variant="caption"
                style={{ color: "#f44336" }}
              >{`Not in ${company && company.name}`}</Typography>
            ) : (
              []
            )}
          </div>
        </Tooltip>
      </div>
      {!lite ? (
        <TextField
          fullWidth
          style={{
            flex: 1,
            margin: 2,
          }}
          disabled={disabled}
          value={memo}
          onBlur={onBlur}
          InputProps={{
            style: {
              // fontSize: 12,
              background: "#f5f5f5",
              padding: 4,
            },
          }}
          onChange={(e) => setMemo(e.target.value)}
        />
      ) : (
        []
      )}

      {!disabled ? (
        <IconButton
          tabIndex={-1}
          style={{ padding: 4 }}
          onClick={() => onDelete(journalEntryItem, index)}
        >
          <HighlightOffIcon style={{ fontSize: 18 }} />
        </IconButton>
      ) : (
        []
      )}
      <Dialog
        open={internalMemoOpen}
        onClose={() => setInternalMemoOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <TextField
            label="Internal Memo"
            variant="outlined"
            value={internalMemo}
            multiline
            rowsMax={5}
            fullWidth
            size="small"
            onChange={(e) => setInternalMemo(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setInternalMemoOpen(false);
              onBlur();
            }}
            color="primary"
            variant="contained"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
