import { networkAction } from 'helpers/network/networkAction';

import {
  GET_REPORTS,
} from 'constants/reports';

import * as Api from 'api';

export const getReports = (filters) => async dispatch => networkAction(
  dispatch,
  GET_REPORTS,
  Api.getReports,
  [filters]
);

