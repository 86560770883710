import {
  GET_MY_PAYABLES,
} from 'constants/payables';

export default (state = [], action) => {
  switch (action.type) {
    case `${GET_MY_PAYABLES}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};
