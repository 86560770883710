import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
// material-ui
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Zoom from "@material-ui/core/Zoom";
import PropTypes from "prop-types";
import React, { Component } from "react";

// styles
const styles = (theme) => ({
  root: {
    textAlign: "center",
    cursor: "pointer",
  },
  container: {
    padding: 5,
    cursor: "pointer",
  },
  tag: {
    background: theme.palette.background.paper,
    marginTop: 4,
    borderRadius: 12,
  },
});

class App extends Component {
  static propTypes = {
    classes: PropTypes.object,
    color: PropTypes.string,
    withName: PropTypes.bool,
    fullName: PropTypes.bool,
    name: PropTypes.string,
    icon: PropTypes.string,
    size: PropTypes.number,
    url: PropTypes.string,
  };

  render() {
    const {
      withName,
      color,
      name,
      size,
      classes,
      fullName,
      url,
      icon,
    } = this.props;

    return (
      <div onClick={url ? () => window.open(`https://${url}`) : undefined}>
        <Zoom in>
          <div className={classes.root}>
            {icon ? (
              <img
                src={icon}
                height={size}
                width={size}
                alt={name}
                style={{
                  boxShadow: "0 5px 28px 0 rgba(0,0,0,.1)",
                  borderRadius: "20%",
                }}
              />
            ) : (
              <Paper
                style={{
                  paddingLeft: fullName ? 4 : undefined,
                  paddingRight: fullName ? 4 : undefined,
                  minWidth: fullName ? size : undefined,
                  width: fullName ? undefined : size,
                  margin: "auto",
                  height: size,
                  border: `solid ${size * 0.05}px ${color}`,
                  background: "#424242",
                  color,
                  fontFamily: "Futura",
                  fontSize: size * 0.5,
                  borderTopRightRadius: size * 0.5,
                  borderBottomLeftRadius: size * 0.5,
                }}
              >
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  style={{ height: size }}
                >
                  <Grid item>{fullName ? name : `${name[0]}${name[1]}`}</Grid>
                </Grid>
              </Paper>
            )}
            {withName && (
              <Typography display="block" display="block" variant="body2">
                {name}
              </Typography>
            )}
          </div>
        </Zoom>
      </div>
    );
  }
}

export default withStyles(styles)(App);
