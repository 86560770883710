import {
  GET_JOURNAL_ENTRIES,
} from 'constants/journalEntries';

export default (state = [], action) => {
  switch (action.type) {
    case `${GET_JOURNAL_ENTRIES}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};
