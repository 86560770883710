import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Link from '@material-ui/core/Link';
import withMobileDialog from '@material-ui/core/withMobileDialog';

// helpers
import { getBrowserInfo } from 'helpers';

class ResponsiveDialog extends React.Component {
  static propTypes = {
    fullScreen: PropTypes.bool.isRequired,
    open: PropTypes.bool,
  };

  render() {
    const { fullScreen, open } = this.props;

    const browser = getBrowserInfo();
    const { browserName } = browser;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          id="responsive-dialog-title"
          style={{
            textAlign: 'center',
          }}
        >
          <img
            src="https://storage.googleapis.com/wid-arranger/images/cookies.png"
            alt="cookie"
            style={{ marginBottom: 20 }}
          />
          <br />
          You need to enable Cookies to use this web application.
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            It seems that you have disabled Cookies on your web browser. This web application needs it to store your authentication token and other information that can be relevant for the app use.
            Be assured that we respect your privacy and we don't track sensitive information.
          </DialogContentText>
          <br />
          {
            browserName === 'Chrome'
              ? (
                <Link href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=en">
                  How to enable Cookie for Google Chrome
                </Link>
              )
              : <div />
          }
          {
            browserName === 'Safari'
              ? (
                <Link href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac">
                  How to enable Cookie for Safari
                </Link>
              )
              : <div />
          }
          {
            browserName === 'Firefox'
              ? (
                <Link href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">
                  How to enable Cookie for Firefox
                </Link>
              )
              : <div />
          }
          {
            browserName === 'Microsoft Internet Explorer'
              ? (
                <Link href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies">
                  How to enable Cookie for Internet Explorer
                </Link>
              )
              : <div />
          }
          {
            browserName === 'Edge'
              ? (
                <Link href="https://support.microsoft.com/en-ie/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoft-privacy">
                  How to enable Cookie for Edge
                </Link>
              )
              : <div />
          }
        </DialogContent>
      </Dialog>
    );
  }
}

export default withMobileDialog()(ResponsiveDialog);