export default theme => ({
  container: {
    padding: 10,
    border: 'solid 1px rgba(155,155,155,0.3)',
    background: 'rgba(155,155,155,0.1)',
    borderRadius: 4,
    paddingTop: 15,
  },
  inputBase: {
    fontSize: '0.875rem',
    background: theme.palette.background.default,
    padding: 2,
    border: 'solid 1px #cfd8dc',
    borderRadius: 4,
  },
  item: {
    background: theme.palette.type === 'dark' ? '#424242' : 'white',
    border: 'solid 1px rgba(155, 155, 155, 0.2)',
    marginTop: 4,
    borderRadius: 4,
  },
});
