// material-ui
import { withStyles } from "@material-ui/core/styles";
// custom
import Table from "components/Table";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class ItemClassesList extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    itemClasses: PropTypes.array,
    onSelect: PropTypes.func,
  };

  onSelect(selectedItemClasse) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedItemClasse);
    }
  }

  render() {
    const { itemClasses } = this.props;

    return (
      <Table
        data={itemClasses}
        meta={[
          {
            path: "name",
            title: "Name",
            numeric: false,
          },
        ]}
        title={"ItemClasses"}
        onRowSelect={this.onSelect.bind(this)}
      />
    );
  }
}

export default withStyles(styles)(ItemClassesList);
