export const CHECK_ACCESS = 'CHECK_ACCESS';
export const GET_USERS = 'GET_USERS';
export const GET_USER_BY_ID = 'GET_USER_BY_ID';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const CREATE_USER = 'CREATE_USER';
export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const SIGNUP = 'SIGNUP';
export const VALIDATE_EMAIL = 'VALIDATE_EMAIL';
export const REQUEST_RESET_PASSWORD = 'REQUEST_RESET_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const UPDATE_USER_PASSWORD = 'UPDATE_USER_PASSWORD';
export const GET_USER_GROUPS = 'GET_USER_GROUPS';
export const DELETE_USER_GROUP = 'DELETE_USER_GROUP';
export const CREATE_USER_GROUP = 'CREATE_USER_GROUP';
export const GET_USER_APPS = 'GET_USER_APPS';
export const IMPERSONATE = 'IMPERSONATE';
export const STOP_IMPERSONATE = 'STOP_IMPERSONATE';
