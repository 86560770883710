import { REACT_APP_API_PREFIX } from "config/env";
import { DATA, DELETE, GET, POST, PUT } from "constants/methods";

const service = "ap-dashboard";
const payables = `${REACT_APP_API_PREFIX}/${service}/payables`;
const lineItems = `${REACT_APP_API_PREFIX}/${service}/lineItems`;
const my = `${REACT_APP_API_PREFIX}/${service}/my`;

export function getPayables(filters) {
  const url = `${payables}${
    filters
      ? `?filters=${JSON.stringify(filters)}&limit=100&sorter=${JSON.stringify([
          { name: "payables.id", asc: false },
        ])}`
      : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function qbExport(body) {
  const url = `${payables}/qbExport`;
  return {
    method: POST,
    url,
    body,
  };
}

export function getPayableById(id) {
  const url = `${payables}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function getMyPayables(filters) {
  const url = `${my}/payables${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function lockPayable(id, body) {
  const url = `${payables}/${id}/lock`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function updateGLAccount(id, body) {
  const url = `${payables}/${id}/gl_account`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function uploadPayableFile(id, file) {
  const data = new FormData();
  data.append("file", file);

  const url = `${payables}/${id}/upload`;

  return {
    method: DATA,
    url,
    body: data,
  };
}

export function uploadLineItemFile(id, file) {
  const data = new FormData();
  data.append("file", file);

  const url = `${lineItems}/${id}/upload`;

  return {
    method: DATA,
    url,
    body: data,
  };
}

export function deletePayableFile(payableID, fileID) {
  const url = `${payables}/${payableID}/files/${fileID}`;

  return {
    method: DELETE,
    url,
  };
}

export function updatePayable(id, body) {
  const url = `${payables}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deletePayable(id) {
  const url = `${payables}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createPayable(body) {
  const url = `${payables}`;
  return {
    method: POST,
    url,
    body,
  };
}

export function createPayableLineItem(payableID, body) {
  const url = `${payables}/${payableID}/lineItems`;
  return {
    method: POST,
    url,
    body,
  };
}

export function updatePayableLineItem(payableID, lineItemID, body) {
  const url = `${lineItems}/${lineItemID}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function updateLineItemInternalMemo(payableID, lineItemID, body) {
  const url = `${lineItems}/${lineItemID}/internalMemo`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deletePayableLineItem(payableID, lineItemID) {
  const url = `${lineItems}/${lineItemID}`;
  return {
    method: DELETE,
    url,
  };
}
