import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import SaveIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class FormGlAccount extends Component {
  static propTypes = {
    classes: PropTypes.object,
    fullScreen: PropTypes.bool,

    close: PropTypes.func,
    open: PropTypes.bool,

    glAccount: PropTypes.object,

    createGlAccount: PropTypes.func,
    updateGlAccount: PropTypes.func,
    deleteGlAccount: PropTypes.func,
    refresh: PropTypes.func,
    companies: PropTypes.array,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      loading: false,
      name: "",
      description: "",
      companyID: "",
      accountNumber: "",
      type: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.init(nextProps.glAccount);
    }
  }

  handleChange = (name) => (event) => {
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  init(glAccount) {
    this.setState({
      loading: false,
      name: glAccount ? glAccount.name : "",
      description: glAccount ? glAccount.description : "",
      companyID: glAccount ? glAccount.companyID : "",
      accountNumber: glAccount ? glAccount.accountNumber : "",
      type: glAccount ? glAccount.type : "",
    });
  }

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle:
          "If you delete this glAccount, you won't be able to recover it.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { deleteGlAccount, close, refresh, glAccount } = this.props;

    const { NotificationCenter } = this.context;
    close();
    const resp = await deleteGlAccount(glAccount.id);
    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: "GlAccount has been deleted.",
        success: true,
        timestamp: new Date().getTime(),
      });
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  async save() {
    const {
      updateGlAccount,
      createGlAccount,
      glAccount,
      refresh,
      close,
    } = this.props;

    const { name, description, companyID, accountNumber, type } = this.state;

    const { NotificationCenter } = this.context;

    let resp;
    this.setState({ loading: true });
    if (glAccount) {
      resp = await updateGlAccount(glAccount.id, {
        name,
        description,
        companyID,
        accountNumber,
        type,
      });
    } else {
      resp = await createGlAccount({
        name,
        description,
        companyID,
        accountNumber,
        type,
      });
    }

    this.setState({ loading: false });

    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: glAccount
          ? "GlAccount has been updated."
          : "GlAccount has been created.",
        success: true,
        timestamp: new Date().getTime(),
      });

      close();
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  render() {
    const {
      classes,
      fullScreen,
      open,
      glAccount,
      close,
      companies,
    } = this.props;

    const {
      name,
      loading,
      description,
      type,
      accountNumber,
      companyID,
    } = this.state;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        scroll="paper"
        onClose={close}
        aria-labelledby="responsive-dialog-title"
      >
        {loading ? (
          <div className={classes.progressContainer}>
            <DialogTitle id="alert-dialog-title">Loading</DialogTitle>
            <CircularProgress />
          </div>
        ) : (
          <div>
            <DialogTitle id="responsive-dialog-title">
              {glAccount ? `${glAccount.name}` : "Let's Create a New GlAccount"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {glAccount
                  ? "To update the glAccount, fill the form below :"
                  : "To create a new glAccount, fill the form below :"}
              </DialogContentText>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id="name"
                    label="Name"
                    helperText="Required"
                    className={classes.textField}
                    value={name}
                    onChange={this.handleChange("name")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="type"
                    label="Type"
                    helperText="Required"
                    className={classes.textField}
                    value={type}
                    onChange={this.handleChange("type")}
                    multiline
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="group-simple">Company</InputLabel>
                    <Select
                      inputProps={{
                        name: "Vendor",
                        id: "Vendor",
                      }}
                      onChange={this.handleChange("companyID")}
                      value={companyID}
                    >
                      {companies &&
                        companies.map((c) => (
                          <MenuItem key={`company_${c.id}`} value={c.id}>
                            {c.name}
                          </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>Required</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="accountNumber"
                    label="Account Number"
                    className={classes.textField}
                    value={accountNumber}
                    onChange={this.handleChange("accountNumber")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="description"
                    label="Description"
                    className={classes.textField}
                    value={description}
                    onChange={this.handleChange("description")}
                    multiline
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              {glAccount && (
                <Button
                  className={classes.delete}
                  onClick={this.delete.bind(this)}
                >
                  <DeleteIcon className={classes.leftIcon} />
                  &nbsp;Delete
                </Button>
              )}
              <Button onClick={close}>
                <CloseIcon className={classes.leftIcon} />
                &nbsp;Close
              </Button>
              <Button color="primary" autoFocus onClick={this.save.bind(this)}>
                <SaveIcon className={classes.leftIcon} />
                {glAccount ? "Save" : "Create"}
              </Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(FormGlAccount));
