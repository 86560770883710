// material-ui
import { withStyles } from "@material-ui/core/styles";
// custom
import Table from "components/Table";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
// styles
import styles from "./styles";
import { Grid, Stack, Paper, Checkbox } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import { createMuiTheme, MuiThemeProvider, styled } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import MomentUtils from "@date-io/moment";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
// material-ui
import TextField from "@material-ui/core/TextField";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import SaveIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import BookIcon from '@material-ui/icons/Book';
import LockIcon from "@material-ui/icons/Lock";
import DeleteIcon from "@material-ui/icons/Delete";
import moment from "moment";
import { reduceToChanges } from "helpers";
import { REACT_APP_API } from "config";


class ReportsList extends Component {
  static propTypes = {
    classes: PropTypes.object,
    close: PropTypes.func,
    generateReport: PropTypes.func,
    open: PropTypes.bool,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      periodType: undefined,
      periodList: [],
      periods: [],
      year: undefined,
    };
  }

  async generate() {
    const { generateReport, close } = this.props;

    const {
      periodList,
      periodType,
      periods,
      year,
    } = this.state;

    console.log("YEAR", year)
    const body = {
      "period": periods.map(x => `${x} - ${year.value}`),
      "comparison": periodType.value,
    };

    let req = undefined;
    // this.setState({ loading: true });

    req =  generateReport(body);
    console.log(req)
    const url = REACT_APP_API+req.url
    window.open(url, '_blank');
    close();


    // this.setState({ loading: false });

    // console.log(resp)
    // if (resp.success) {
    //   NotificationCenter.sweetAlert({
    //     title: "Your report has been generated",
    //     success: true,
    //     timestamp: new Date().getTime(),
    //   });

    //   setTimeout(NotificationCenter.hide, 1500);
    // }
  }

  render() {
    const { open, close } = this.props;
    const { periodList, periodType, periods, disabled } = this.state;

    const months = [
      {
        "name": "Jan", "value": "1"
      },
      {
        "name": "Feb", "value": "2"
      },
      {
        "name": "Mar", "value": "3"
      },
      {
        "name": "Apr", "value": "4"
      },
      {
        "name": "May", "value": "5"
      },
      {
        "name": "Jun", "value": "6"
      },
      {
        "name": "Jul", "value": "7"
      },
      {
        "name": "Aug", "value": "8"
      },
      {
        "name": "Sep", "value": "9"
      },
      {
        "name": "Oct", "value": "10"
      },
      {
        "name": "Nov", "value": "11"
      },
      {
        "name": "Dec", "value": "12"
      },
    ]

    const periodTypeList = [
      {
        "name": "Months", "value": "months"
      },
      {
        "name": "Weeks", "value": "weeks"
      },
    ]

    var weeks = [];
    for (var i = 0; i < 53; i++) {
      weeks.push({"name": i.toString(), "value": i.toString().padStart(2, '0')})
    }

    var years = [];
    const curYear = new Date().getFullYear()
    for (var i = 0; i < 3; i++) {
      let y = curYear-i;
      years.push({ "name": y.toString(), "value": y.toString().substr(2) })
    }


    const Item = styled(Paper)(({ theme }) => ({
      ...theme.typography.body2,
      color: theme.palette.text.secondary,
      height: 250,
      marginTop: "10%"

    }));

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    return (
      <Dialog
        open={open}
      >
        <DialogTitle>Generate GL Export</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To download a general ledger report, you must choose the period type, year and period.
          </DialogContentText>
          <Autocomplete
            id="tags-standard"
            options={periodTypeList}
            onChange={(e, v) => {
              if (v && v.value === "weeks") {
                this.setState({
                  periodList: weeks,
                  periodType: v,
                });
              } else if (v && v.value === "months") {
                this.setState({
                  periodList: months,
                  periodType: v,
                });
              } else {
                this.setState({
                  periodList: [],
                  periodType: undefined,
                });
              }
            }}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Period Type"
                placeholder="Type"
              />
            )}
          />
          <Autocomplete
            id="years"
            options={years}
            getOptionLabel={(option) => option.name}
            onChange={(e, v) => {
              this.setState({
                year: v,
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Year"
                placeholder="Year"
              />
            )}
          />
          <Autocomplete
            multiple
            id="period"
            options={periodList}
            getOptionLabel={(option) => option.name}
            disableCloseOnSelect
            onChange={(e, v) => {
              console.log(e, v);
              if (v) {
                this.setState({
                  periods: v.map(x => x.value),
                });
              }else {
                this.setState({
                  periods: [],
                });
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Period"
                placeholder={periodType ? periodType.name : ""}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={close}><CloseIcon /> Close</Button>
        <Button
          color="primary"
          disabled={periodType === undefined || periods.length < 1}
          onClick={this.generate.bind(this)}
        >
            <CloudDownloadIcon /> Generate
          </Button>
          </DialogActions >
      </Dialog>
          );

  }
}

export default withStyles(styles)(ReportsList);
