// routes
import Account from "@material-ui/icons/AccountBalanceTwoTone";
import AccountCircle from "@material-ui/icons/AccountCircleTwoTone";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpwardTwoTone";
import Bookmarks from "@material-ui/icons/BookmarksTwoTone";
import Business from "@material-ui/icons/BusinessTwoTone";
import Receipt from "@material-ui/icons/ReceiptTwoTone";
import StoreIcon from "@material-ui/icons/StoreTwoTone";
import TableChartIcon from '@material-ui/icons/TableChart';
import BookIcon from '@material-ui/icons/Book';

import {
  COMPANIES,
  EXPORTS,
  GL_ACCOUNTS,
  ITEM_CLASSES,
  MY_ACCOUNT,
  PAYABLES,
  VENDORS,
  JOURNAL_ENTRIES,
  REPORTS,
} from "constants/routes";

export const menu = [
  {
    icon: Receipt,
    label: "Payables",
    path: PAYABLES,
    divider: false,
  },
  {
    icon: Business,
    label: "Companies",
    path: COMPANIES,
    divider: false,
  },
  {
    icon: StoreIcon,
    label: "Vendors",
    path: VENDORS,
    divider: false,
  },
  {
    icon: Account,
    label: "GL Accounts",
    path: GL_ACCOUNTS,
    divider: false,
  },
  {
    icon: Bookmarks,
    label: "Item Classes",
    path: ITEM_CLASSES,
    divider: false,
  },
  {
    icon: TableChartIcon,
    label: "Journal Entries",
    path: JOURNAL_ENTRIES,
    divider: true,
  },
  {
    icon: BookIcon,
    label: "Reports",
    path: REPORTS,
    divider: true,
  },
  {
    icon: ArrowUpwardIcon,
    label: "Exports",
    path: EXPORTS,
    divider: true,
  },
  {
    icon: AccountCircle,
    label: "My Account",
    path: MY_ACCOUNT,
    divider: true,
  },
];
