// import PropTypes from 'prop-types';
// material-ui
import {
  Avatar,
  Button,
  Chip,
  CircularProgress,
  DialogActions,
  DialogContent,
  Grid,
  Icon,
  ListItemAvatar,
  Typography,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import * as Actions from "actions";
import React from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function ImportVendors({ refresh }) {
  const [companyID, setCompanyID] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [result, setResult] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [file, setFile] = React.useState(false);
  const classes = useStyles(styles);
  const onDrop = React.useCallback((acceptedFiles) => {
    setOpen(true);
    setFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const companies = useSelector((state) => state.companies);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (file && companyID) {
      setLoading(true);
      dispatch(Actions.importVendors(file, companyID)).then((r) => {
        setResult(r.payload);
        setLoading(false);
        refresh();
        setCompanyID();
        setFile();
      });
    }
  }, [companyID]);

  function close() {
    setOpen(false);
    setResult();
  }

  return (
    <>
      <div
        {...getRootProps()}
        style={{ cursor: "pointer", display: "inline-block", marginRight: 10 }}
      >
        <input {...getInputProps()} />
        <Chip size="small" label="Import Vendors" />
      </div>
      <Dialog
        onClose={() => (!loading ? setOpen(false) : undefined)}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        {!loading && !result ? (
          <DialogTitle id="simple-dialog-title">Select the Company</DialogTitle>
        ) : (
          []
        )}
        {result ? (
          <DialogTitle id="simple-dialog-title">All done!</DialogTitle>
        ) : (
          []
        )}
        {result ? (
          <DialogContent style={{ textAlign: "center" }}>
            <Grid container>
              <Grid item xs={6}>
                Items Updated:
              </Grid>
              <Grid item xs={6}>
                {result.itemsUpdated}
              </Grid>
              <Grid item xs={6}>
                Items Created:
              </Grid>
              <Grid item xs={6}>
                {result.itemsCreated}
              </Grid>
              <Grid item xs={6}>
                Items Skipped:
              </Grid>
              <Grid item xs={6}>
                {result.itemsSkipped}
              </Grid>
              <Grid item xs={6}>
                Items:
              </Grid>
              <Grid item xs={6}>
                {result.items}
              </Grid>
              {result.error ? (
                <Alert severity="error">{JSON.stringify(result.error)}</Alert>
              ) : (
                []
              )}
            </Grid>
          </DialogContent>
        ) : (
          []
        )}
        {loading ? (
          <DialogContent style={{ textAlign: "center" }}>
            <Typography display="block">Loading...</Typography>
            <CircularProgress />
          </DialogContent>
        ) : (
          []
        )}
        {!result && !loading ? (
          <List>
            {companies.map((c) => (
              <ListItem button onClick={() => setCompanyID(c.id)} key={c.id}>
                <ListItemAvatar>
                  <Avatar>
                    <Icon class="fad fa-building" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={c.name} />
              </ListItem>
            ))}
          </List>
        ) : (
          []
        )}
        {result ? (
          <DialogActions>
            <Button fullWidth variant="contained" onClick={close}>
              Close
            </Button>
          </DialogActions>
        ) : (
          []
        )}
      </Dialog>
    </>
  );
}
