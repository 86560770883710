import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

// material-ui
import withStyles from '@material-ui/core/styles/withStyles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

// components
import PriorityLevelsList from 'components/PriorityLevelsList';
import Page from 'components/Page';
import FormPriorityLevel from 'components/FormPriorityLevel';

// styles
import styles from './styles';

class WrapperPriorityLevelsPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    loading: PropTypes.bool,

    //priorityLevels
    priorityLevels: PropTypes.array,
    getPriorityLevelById: PropTypes.func,
    createPriorityLevel: PropTypes.func,
    deletePriorityLevel: PropTypes.func,
    updatePriorityLevel: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedPriorityLevel: undefined,
      formPriorityLevelOpen: false,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const { priorityLevels } = this.props;

    switch (index) {
      case 0:
        return (
          <PriorityLevelsList
            priorityLevels={priorityLevels}
            onSelect={(selectedPriorityLevel) => this.setState({
              selectedPriorityLevel,
              formPriorityLevelOpen: true,
            })}
          />
        );
      default:
    }
  }

  render() {
    const {
      classes,
      history,
      loading,
      createPriorityLevel,
      deletePriorityLevel,
      updatePriorityLevel,
      refresh,
    } = this.props;

    const {
      index,
      selectedPriorityLevel,
      formPriorityLevelOpen,
    } = this.state;

    return (
      <Page
        helmet={'PriorityLevels'}
        loadingMessage={'Loading Document'}
        loading={loading}
        fab={(
          <Fab
            variant="fab"
            color="primary"
            aria-label="Add"
            className={classes.floatingActionButton}
            onClick={() => this.setState({ formPriorityLevelOpen: true })}
          >
            <AddIcon />
          </Fab>
        )}
        tabs={(
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({ index: v });
              history.push(`?index=${v}`);
            }}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
          >
            <Tab
              label="PriorityLevels"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        )}
      >
        {
          this.getStepContent()
        }
        <FormPriorityLevel
          open={formPriorityLevelOpen}
          priorityLevel={selectedPriorityLevel}
          close={() => {
            this.setState({
              formPriorityLevelOpen: false,
            });
            setTimeout(() => this.setState({
              selectedPriorityLevel: undefined,
            }), 200);
          }
          }
          createPriorityLevel={createPriorityLevel}
          deletePriorityLevel={deletePriorityLevel}
          updatePriorityLevel={updatePriorityLevel}
          refresh={refresh}
        />
      </Page>
    )
  }
}
export default withStyles(styles)(WrapperPriorityLevelsPage);

