import * as Api from "api";
import {
  CREATE_VENDOR,
  DELETE_VENDOR,
  GET_VENDORS,
  GET_VENDOR_BY_ID,
  IMPORT_VENDORS,
  UPDATE_VENDOR,
} from "constants/vendors";
import { networkAction } from "helpers/network/networkAction";

export const getVendors = (filters) => async (dispatch) =>
  networkAction(dispatch, GET_VENDORS, Api.getVendors, [filters]);

export const importVendors = (file, companyID) => async (dispatch) =>
  networkAction(dispatch, IMPORT_VENDORS, Api.importVendors, [file, companyID]);

export const getVendorById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_VENDOR_BY_ID, Api.getVendorById, [id]);

export const deleteVendor = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_VENDOR, Api.deleteVendor, [id]);

export const createVendor = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_VENDOR, Api.createVendor, [body]);

export const updateVendor = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_VENDOR, Api.updateVendor, [id, body]);
