import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
// material-ui
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Error from "@material-ui/icons/ErrorTwoTone";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import worker from "react-pdf/src/pdf.worker.entry";
// styles
import styles from "./styles";

pdfjs.GlobalWorkerOptions.workerSrc = worker;

class PDF extends Component {
  static propTypes = {
    classes: PropTypes.object,
    src: PropTypes.any,
    spacing: PropTypes.number,
    ratio: PropTypes.number,
    scale: PropTypes.number,
  };

  static defaultProps = {
    spacing: 16,
    ratio: 1.2941,
  };

  constructor(...args) {
    super(...args);
    console.log("constructor");
    this.container = React.createRef();
    this.state = {
      width: 0,
      height: 0,
      init: false,
      pages: [],
      delta: 200,
      resizing: false,
    };

    window.addEventListener("resize", () => {
      console.log("resize");
      this.setState({ resizing: true });
      this.state.resizing = true;
      this.state.rtime = new Date();
      this.state.timeout = false;
      if (this.state.timeout === false) {
        this.state.timeout = true;
        setTimeout(this.onResizeEnd.bind(this), this.state.delta);
      }
    });
  }
  componentWillMount() {
    console.log("componentWillMount");
    setTimeout(this.updateFrame.bind(this), 100);
  }

  componentWillReceiveProps(nextProps) {
    console.log("componentWillReceiveProps");
    const { numPages } = this.state;
    if (nextProps.scale !== this.props.scale) {
      this.setState({ init: false });
      setTimeout(() => this.onDocumentLoadSuccess({ numPages }), 100);
    }
  }

  shouldComponentUpdate() {
    console.log("shouldComponentUpdate");
    if (this.state.init === false) {
      return true;
    }
    return false;
  }

  onResizeEnd() {
    console.log("onResizeEnd");
    const { rtime, delta } = this.state;
    if (new Date() - rtime < delta) {
      setTimeout(this.onResizeEnd.bind(this), delta);
    } else {
      this.state.timeout = false;
      if (this.state.resizing) {
        this.state.resizing = false;
        this.setState(
          {
            resizing: false,
            width: 0,
            height: 0,
          },
          this.updateFrame.bind(this)
        );
      }
    }
  }

  onDocumentLoadSuccess(meta) {
    console.log("onDocumentLoadSuccess");
    const { numPages } = meta;
    const { height, width } = this.state;
    const { spacing, ratio, scale } = this.props;
    const pages = [];
    let pdfWidth;
    let pdfHeight;
    if ((width - 2 * spacing) * ratio < height - 2 * spacing) {
      pdfWidth = width - 2 * spacing;
      if (scale) pdfWidth = pdfWidth * scale;
    } else {
      pdfHeight = height - 2 * spacing;
      if (scale) pdfHeight = pdfHeight * scale;
    }

    for (let index = 0; index < numPages; index++) {
      pages.push(
        <Grid item style={{ padding: spacing }} key={`page_${index}`}>
          <div
            style={{
              boxShadow: "0px 0px 50px 1px rgba(0,0,0,0.3)",
            }}
          >
            <Page
              pageNumber={index + 1}
              width={pdfWidth}
              height={pdfHeight}
              ratio={1.2941}
            />
          </div>
        </Grid>
      );
    }
    this.setState({ pages, init: true, numPages });
  }

  updateFrame() {
    const { numPages, init } = this.state;
    console.log("updateFrame");
    if (this.container && this.container.current) {
      this.setState(
        {
          width: this.container.current.clientWidth,
          height: this.container.current.clientHeight,
        },
        init ? () => this.onDocumentLoadSuccess({ numPages }) : undefined
      );
    }
  }

  render() {
    const { classes, src } = this.props;
    const { height, width, pages, init, resizing } = this.state;

    return (
      <div ref={this.container} className={classes.container}>
        {!resizing ? (
          <div
            style={{
              height,
              width,
              overflowX: "hidden",
              overflowY: "scroll",
            }}
          >
            <Document
              file={{
                url: src,
                withCredentials: true,
              }}
              error={
                <Grid
                  container
                  direction="column"
                  justify="center"
                  style={{ color: "white", textAlign: "center", height }}
                >
                  <Grid item>
                    <Error fontSize="large" style={{ color: "white" }} />
                  </Grid>
                  <Grid item>
                    <Typography display="block" variant="h6" color="inherit">
                      The Server Couldn't Generate the PDF
                    </Typography>
                    <Typography
                      display="block"
                      variant="caption"
                      color="inherit"
                    >
                      Please retry. Contact the support if you think it is a
                      bug.
                    </Typography>
                  </Grid>
                </Grid>
              }
              loading={
                <Grid
                  container
                  justify="center"
                  direction="column"
                  style={{ color: "white", height }}
                  alignItems="center"
                >
                  <Grid item>
                    <CircularProgress />
                  </Grid>
                  <Grid item>
                    <Typography display="block" color="inherit">
                      Loading
                    </Typography>
                  </Grid>
                </Grid>
              }
              onLoadSuccess={
                !init ? (meta) => this.onDocumentLoadSuccess(meta) : undefined
              }
            >
              <Grid container justify={"center"}>
                {pages}
              </Grid>
            </Document>
          </div>
        ) : (
          []
        )}
      </div>
    );
  }
}

export default withStyles(styles)(PDF);
