import React from 'react';
import PropTypes from 'prop-types';

// creativeTim
import Badge from 'creativeTim/Badge/Badge';

// material-ui
import { withStyles } from '@material-ui/core/styles';
import {
  DELETE,
  PUT,
  GET,
  POST,
} from 'constants/methods';

// styles
import styles from './styles';

class TableMethod extends React.Component {
  static propTypes = {
    datum: PropTypes.object,
  };

  render() {
    const { datum } = this.props;

    let color = 'gray';

    switch (datum.method) {
      case DELETE:
      case DELETE.toUpperCase():
        color = 'danger';
        break;
      case POST:
      case POST.toUpperCase():
        color = 'info';
        break;
      case GET:
      case GET.toUpperCase():
        color = 'success';
        break;
      case PUT:
      case PUT.toUpperCase():
        color = 'rose';
        break;
      default:
    }

    return (
      <Badge
        color={color}
      >
        {
          datum.method
        }
      </Badge>
    );
  }
}

export default withStyles(styles)(TableMethod);
