import { Fab } from "@material-ui/core";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import AddIcon from "@material-ui/icons/Add";
// custom
import CompaniesList from "components/CompaniesList";
import FormCompany from "components/FormCompany";
// reactor
import Page from "components/Page";
import PropTypes from "prop-types";
import React from "react";
// styles
import styles from "./styles";

class WrapperCompanyPage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    loading: PropTypes.bool,
    companies: PropTypes.array,
    createCompany: PropTypes.func,
    deleteCompany: PropTypes.func,
    updateCompany: PropTypes.func,
    companiesGlLockDates: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedCompany: undefined,
      formCompanyOpen: false,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const { companies } = this.props;

    switch (index) {
      case 0:
        return (
          <CompaniesList
            companies={companies}
            onSelect={(selectedCompany) =>
              this.setState({
                selectedCompany,
                formCompanyOpen: true,
              })
            }
          />
        );
      default:
    }

    return [];
  }

  render() {
    const {
      classes,
      history,
      loading,
      createCompany,
      deleteCompany,
      updateCompany,
      companiesGlLockDates,
      refresh,
    } = this.props;

    const { index, selectedCompany, formCompanyOpen } = this.state;

    return (
      <Page
        helmet="Companies"
        loading={loading}
        fab={
          <Fab
            variant="fab"
            color="primary"
            aria-label="Add"
            onClick={() => this.setState({ formCompanyOpen: true })}
          >
            <AddIcon />
          </Fab>
        }
        loadingMessage={"Fetching all Companies"}
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({
                index: v,
              });
              history.push(`?index=${v}`);
            }}
            indicatorColor="primary"
            textColor="primary"
            scrollable
            scrollButtons="auto"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            <Tab
              label="All"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        }
      >
        {this.getStepContent()}
        <FormCompany
          open={formCompanyOpen}
          company={selectedCompany}
          close={() => {
            this.setState({
              formCompanyOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedCompany: undefined,
                }),
              200
            );
          }}
          createCompany={createCompany}
          deleteCompany={deleteCompany}
          updateCompany={updateCompany}
          companiesGlLockDates={companiesGlLockDates}
          refresh={refresh}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperCompanyPage);
