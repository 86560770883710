// actions
import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// component
import WrapperCompanyPage from "../component/WrapperCompanyPage";

const mapStateToProps = (state) => ({
  companies: state.companies,
});

const mapDispatchToProps = (dispatch) => ({
  getCompanies: () => dispatch(Actions.getCompanies()),
  getCompanyById: (...args) => dispatch(Actions.getCompanyById(...args)),
  createCompany: (...args) => dispatch(Actions.createCompany(...args)),
  deleteCompany: (...args) => dispatch(Actions.deleteCompany(...args)),
  updateCompany: (...args) => dispatch(Actions.updateCompany(...args)),
  companiesGlLockDates: (...args) =>
    dispatch(Actions.companiesGlLockDates(...args)),
});
class CompanyPage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    companies: PropTypes.array,
    getCompanies: PropTypes.func,
    getCompanyById: PropTypes.func,
    createCompany: PropTypes.func,
    deleteCompany: PropTypes.func,
    updateCompany: PropTypes.func,
    companiesGlLockDates: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, companies } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: companies.length === 0,
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.refresh();
  }

  async refresh() {
    const { getCompanies } = this.props;
    await Promise.all([getCompanies()]);

    this.setState({ loading: false });
  }

  render() {
    const {
      history,
      companies,
      getCompanyById,
      createCompany,
      deleteCompany,
      updateCompany,
      companiesGlLockDates,
    } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperCompanyPage
        history={history}
        refresh={this.refresh.bind(this)}
        loading={loading}
        companies={companies}
        getCompanyById={getCompanyById}
        createCompany={createCompany}
        deleteCompany={deleteCompany}
        updateCompany={updateCompany}
        companiesGlLockDates={companiesGlLockDates}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CompanyPage);
