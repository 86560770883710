import MomentUtils from "@date-io/moment";
import {
  IconButton,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import Clear from "@material-ui/icons/Clear";
import CloudDownload from "@material-ui/icons/CloudDownload";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
// material-ui
import { withStyles } from "@material-ui/styles";
// actions
import * as Actions from "actions";
import { REACT_APP_API, REACT_APP_API_PREFIX } from "config/env";
// helpers
import { getJsonFromUrl, updateURLParameter } from "helpers";
import Fetcher from "helpers/network/network";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import AsyncSelect from "react-select/async";
// custom
import Deletable from "./Deletable";
import FormPreset from "./FormPreset";
// styles
import styles from "./styles";

const symbols = [
  { symbol: "=", value: "eq" },
  { symbol: "≃", value: "like" },
  { symbol: "≠", value: "neq" },
  { symbol: ">", value: "gt" },
  { symbol: "<", value: "lt" },
  { symbol: "≥", value: "gte" },
  { symbol: "≤", value: "lte" },
];

const symbolsDict = {
  eq: "=",
  neq: "≠",
  gt: ">",
  lt: "<",
  gte: "≥",
  lte: "≤",
  like: "≃",
};

const symbolsDictTooltip = {
  eq: "Equal",
  neq: "Not Equal",
  gt: "Greater Than",
  lt: "Less Than",
  gte: "Greater or Equal Than",
  lte: "Less or Equal Than",
  like: "Like",
};

const mapStateToProps = (state) => ({
  searchPresets: state.searchPresets,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  createSearchPreset: (...args) =>
    dispatch(Actions.createSearchPreset(...args)),
  deleteSearchPreset: (...args) =>
    dispatch(Actions.deleteSearchPreset(...args)),
  getSearchPresets: (...args) => dispatch(Actions.getSearchPresets(...args)),
});

class AdvancedSearch extends Component {
  static propTypes = {
    classes: PropTypes.object,
    history: PropTypes.object,
    keyword: PropTypes.string,
    searchItems: PropTypes.array,
    children: PropTypes.node,
    rowCount: PropTypes.number,
    metaDataEndpoint: PropTypes.string,
    hideMetaData: PropTypes.array,
    exportEndpoint: PropTypes.string,
    metaDataOveride: PropTypes.object,
    createSearchPreset: PropTypes.array,
    deleteSearchPreset: PropTypes.array,
    getSearchPresets: PropTypes.array,
    searchPresets: PropTypes.array,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    searchItems: [],
    metaDataOveride: {},
  };

  constructor(...args) {
    super(...args);
    const { metaDataEndpoint, searchItems, getSearchPresets } = this.props;
    const { location } = window;
    const urlParams = getJsonFromUrl(location);

    if (metaDataEndpoint) this.getMetadata();
    getSearchPresets();

    this.state = {
      filters: urlParams.filters ? JSON.parse(urlParams.filters) : [],
      searchItems: searchItems || [],
      loading: false,
      globalPresetChipEl: false,
    };
  }

  async getMetadata() {
    const { metaDataEndpoint } = this.props;
    const fetcher = new Fetcher();
    const r = await fetcher.get({ url: metaDataEndpoint });
    let json;
    try {
      json = await r.json();
    } catch (e) {
      console.log(e);
    }

    this.convertToSearchItem(json);
  }

  getItem(name) {
    const { filters } = this.state;
    const index = filters.findIndex((f) => f.name === name);
    if (index !== -1) {
      return filters[index];
    }

    return {};
  }

  getSearchItem(name) {
    const { searchItems } = this.state;
    const index = searchItems.findIndex((f) => f.name === name);
    if (index !== -1) {
      return searchItems[index];
    }

    return {};
  }

  handleClick = (e) => {
    //eslint-disable-line
    this.setState({ anchorEl: e.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  async clearFilters() {
    const { history, refresh } = this.props;
    history.push({
      search: updateURLParameter("filters", "[]"),
    });
    this.setState({
      loading: true,
      filters: [],
    });
    await refresh([]);
    this.setState({
      loading: false,
    });
  }

  convertToSearchItem(metaData) {
    const { metaDataOveride } = this.props;
    const searchItems = [];
    for (const key in metaData) {
      if (metaData.hasOwnProperty(key)) {
        const e = metaData[key];
        searchItems.push({
          name: e.name,
          label: e.label,
          type: e.type,
          default: e.type === "bool" ? false : "",
          comparison: e.type === "string" || e.type === "date" ? "like" : "eq",
          ...metaDataOveride[e.id],
        });
      }
    }

    this.setState({ searchItems });
  }

  async updateFilters() {
    const { history, refresh } = this.props;
    const { filters } = this.state;
    history.push({
      search: updateURLParameter("filters", JSON.stringify(filters)),
    });
    this.setState({
      loading: true,
    });
    await refresh(filters);
    this.setState({
      loading: false,
    });
  }

  removeField = (index) => () => {
    const { filters } = this.state;
    filters.splice(index, 1);

    if (filters.length === 0) {
      this.clearFilters();
    }
    this.setState({ filters });
  };

  handleChange = (name, type, comparison, index) => (event) => {
    const { target } = event;
    const { value } = target;
    const { filters } = this.state;

    const searchItem = this.getSearchItem(name);
    if (index >= 0) {
      // Filters already exist
      if (value !== 0) filters[index].value = value;
      if (type === "date")
        filters[index].value = moment(value).format("YYYY-MM-DD");
      if (comparison !== undefined) filters[index].comparison = comparison;
    } else {
      // Filters is new
      filters.push({
        name,
        comparison: searchItem.comparison,
        value,
      });
    }

    this.setState({ [`anchorEl_${index}`]: null, filters });
  };

  handleCheckboxChange = (name, index) => (event) => {
    const { target } = event;
    const { checked } = target;
    const { filters } = this.state;
    if (index !== undefined) {
      // Filters already exist
      filters[index].value = checked;
    } else {
      // Filters is new
      filters.push({
        name,
        comparison: "eq",
        value: checked,
      });
    }

    this.setState({ filters });
  };

  getSearchField(type, name, index) {
    const { classes, hideMetaData } = this.props;
    const { filters } = this.state;
    const item = filters[index];
    const searchItem = this.getSearchItem(name);

    if (item.name === undefined) {
      return [];
    }

    if (hideMetaData && hideMetaData.findIndex((e) => e === name) !== -1) {
      return [];
    }

    switch (type) {
      case "autocomplete":
        const selectedOption = {
          label: item.value
            ? searchItem.data.find(
                (d) => d[searchItem.valueKey] === item.value
              )[searchItem.labelKey]
            : "",
          value: item.value,
        };

        const options = []; //eslint-disable-line
        for (const key in searchItem.data) {
          if (searchItem.data.hasOwnProperty(key)) {
            const d = searchItem.data[key];
            options.push({
              value: d[searchItem.valueKey],
              label: d[searchItem.labelKey],
            });
          }
        }

        return (
          <Tooltip title={searchItem.label}>
            <Grid key={`${name}${index}`} item style={{ marginLeft: 8 }}>
              <Deletable onDelete={this.removeField(index)}>
                <div
                  style={{ minWidth: 200 }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      this.updateFilters(e);
                    }
                  }}
                >
                  <Select
                    value={selectedOption}
                    autoFocus
                    onChange={(e) => {
                      this.handleChange(
                        name,
                        undefined,
                        undefined,
                        index
                      )({
                        target: { value: e.value },
                      });
                    }}
                    options={options}
                  />
                </div>
              </Deletable>
            </Grid>
          </Tooltip>
        );
      case "autoFill":
        return (
          <Tooltip title={searchItem.label}>
            <Grid key={`${name}${index}`} item style={{ marginLeft: 8 }}>
              <Deletable onDelete={this.removeField(index)}>
                <div
                  style={{ minWidth: 200 }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      this.updateFilters(e);
                    }
                  }}
                >
                  <AsyncSelect
                    cacheOptions
                    placeholder={filters[index].value || searchItem.label}
                    loadOptions={(inputValue, callback) => {
                      searchItem.getData(inputValue).then((resp) => {
                        const options = []; //eslint-disable-line
                        for (const key in resp.payload) {
                          if (resp.payload.hasOwnProperty(key)) {
                            const d = resp.payload[key];
                            options.push({
                              value: d[searchItem.valueKey],
                              label: searchItem.labelTransform(d),
                            });
                          }
                        }
                        callback(options);
                      });
                    }}
                    defaultOptions
                    onChange={(e) => {
                      this.handleChange(
                        name,
                        undefined,
                        undefined,
                        index
                      )({
                        target: { value: e && e.value ? e.value : undefined },
                      });
                    }}
                  />
                </div>
              </Deletable>
            </Grid>
          </Tooltip>
        );
      case "bool":
        return (
          <Tooltip title={searchItem.label}>
            <Grid key={`${name}${index}`} item style={{ marginLeft: 8 }}>
              <Deletable onDelete={this.removeField(index)}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={item.value || false}
                      onChange={this.handleCheckboxChange(name, index)}
                      value={name}
                      className={classes.checkboxPadding}
                      color="primary"
                    />
                  }
                  className={classes.noPadding}
                  label={searchItem.label}
                />
              </Deletable>
            </Grid>
          </Tooltip>
        );
      case "int":
        return (
          <Tooltip title={searchItem.label}>
            <Grid key={`${name}${index}`} item style={{ marginLeft: 8 }}>
              <Deletable onDelete={this.removeField(index)}>
                <Grid container alignItems="center">
                  <Grid item>
                    <Chip
                      size="small"
                      style={{ marginLeft: 8 }}
                      label={symbolsDict[item.comparison]}
                      onClick={(e) =>
                        this.setState({
                          [`anchorEl_${index}`]: e.currentTarget,
                        })
                      }
                    />
                    <Menu
                      id="simple-menu"
                      getContentAnchorEl={null}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      anchorEl={this.state[`anchorEl_${index}`]}
                      open={Boolean(this.state[`anchorEl_${index}`])}
                      onClose={() =>
                        this.setState({ [`anchorEl_${index}`]: null })
                      }
                    >
                      {symbols.map((s) => (
                        <Tooltip title={symbolsDictTooltip[s.value]}>
                          <MenuItem
                            key={s.value}
                            onClick={this.handleChange(
                              name,
                              undefined,
                              s.value,
                              index
                            )}
                          >
                            {s.symbol}
                          </MenuItem>
                        </Tooltip>
                      ))}
                    </Menu>
                  </Grid>
                  <Grid item style={{ marginLeft: 8 }}>
                    <InputBase
                      autoFocus
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          this.updateFilters(e);
                        }
                      }}
                      placeholder={searchItem.label}
                      value={`${item.value}`}
                      onChange={this.handleChange(
                        name,
                        undefined,
                        undefined,
                        index
                      )}
                      style={{
                        width: `calc(${`${item.value}`.length + 1}ch + 20px)`,
                        minWidth: `calc(${
                          `${searchItem.label}`.length + 1
                        }ch + 20px)`,
                      }}
                      className={classes.inputBase}
                    />
                  </Grid>
                </Grid>
              </Deletable>
            </Grid>
          </Tooltip>
        );
      case "string":
        return (
          <Tooltip title={searchItem.label}>
            <Grid key={`${name}${index}`} item style={{ marginLeft: 8 }}>
              <Deletable onDelete={this.removeField(index)}>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <InputBase
                      value={`${item.value}`}
                      autoFocus
                      placeholder={searchItem.label}
                      onChange={this.handleChange(
                        name,
                        undefined,
                        undefined,
                        index
                      )}
                      style={{
                        width: `calc(${`${item.value}`.length + 1}ch + 20px)`,
                        minWidth: `calc(${
                          `${searchItem.label}`.length + 1
                        }ch + 20px)`,
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          this.updateFilters(e);
                        }
                      }}
                      className={classes.inputBase}
                    />
                  </Grid>
                </Grid>
              </Deletable>
            </Grid>
          </Tooltip>
        );
      case "date":
        return (
          <Tooltip title={searchItem.label}>
            <Grid key={`${name}${index}`} item style={{ marginLeft: 8 }}>
              <Deletable onDelete={this.removeField(index)}>
                <Grid container alignItems="center">
                  <Grid item>
                    <Typography>{searchItem.label}</Typography>
                  </Grid>
                  <Grid item>
                    <Chip
                      size="small"
                      style={{ marginLeft: 8 }}
                      label={symbolsDict[item.comparison]}
                      onClick={(e) =>
                        this.setState({
                          [`anchorEl_${index}`]: e.currentTarget,
                        })
                      }
                    />
                    <Menu
                      anchorEl={this.state[`anchorEl_${index}`]}
                      getContentAnchorEl={null}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      open={Boolean(this.state[`anchorEl_${index}`])}
                      onClose={() =>
                        this.setState({ [`anchorEl_${index}`]: null })
                      }
                    >
                      {symbols.map((s) => (
                        <Tooltip title={symbolsDictTooltip[s.value]}>
                          <MenuItem
                            key={s.value}
                            onClick={this.handleChange(
                              name,
                              undefined,
                              s.value,
                              index
                            )}
                          >
                            {s.symbol}
                          </MenuItem>
                        </Tooltip>
                      ))}
                    </Menu>
                  </Grid>
                  <Grid item style={{ marginLeft: 8 }}>
                    <KeyboardDatePicker
                      inputVariant="outlined"
                      variant="inline"
                      value={item.value ? moment(item.value) : null}
                      placeholder="MM/DD/YYYY"
                      autoOk
                      onChange={(e) =>
                        this.handleChange(
                          name,
                          "date",
                          undefined,
                          index
                        )({ target: { value: e.format("MM/DD/YYYY") } })
                      }
                      showTodayButton
                      size="small"
                      style={{ width: 170, background: "white" }}
                      keyboardIcon={
                        <CalendarTodayIcon style={{ fontSize: 20 }} />
                      }
                      KeyboardButtonProps={{ size: "small" }}
                      format="MM/DD/YYYY"
                    />
                  </Grid>
                </Grid>
              </Deletable>
            </Grid>
          </Tooltip>
        );
      default:
        return [];
    }
  }
  render() {
    const {
      searchPresets,
      getSearchPresets,
      deleteSearchPreset,
      createSearchPreset,
      children,
      classes,
      keyword,
      exportEndpoint,
      hideMetaData,
      disabled,
      user,
    } = this.props;
    const {
      filters,
      presetOpen,
      loading,
      searchItems,
      globalPresetChipEl,
      myPresetChipEl,
    } = this.state;

    const searchItemsToAdd = searchItems;

    if (searchItems.length === 0) return <div />;

    let _filter = filters;

    if (hideMetaData) {
      _filter = [];
      for (const key in filters) {
        if (Object.hasOwnProperty.call(filters, key)) {
          const element = filters[key];
          if (hideMetaData.findIndex((e) => e === element.name) === -1) {
            _filter.push(element);
          }
        }
      }
    }

    if (disabled) return children;

    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Grid container spacing={2} justify="center">
          <Grid item xs={12}>
            <ExpansionPanel expanded={_filter.length}>
              <ExpansionPanelSummary>
                <Grid
                  container
                  spacing={1}
                  justify="space-between"
                  style={{ width: "100%" }}
                >
                  <Grid item xs={12}>
                    <Grid container justify="space-between" alignItems="center">
                      <Grid item>
                        <Grid container alignItems="center" spacing={1}>
                          <Grid item>
                            <div style={{ marginTop: -4 }}>
                              <Typography>
                                <b>Search by</b>
                              </Typography>
                            </div>
                          </Grid>
                          <Grid item style={{ marginLeft: 8 }}>
                            <Chip
                              onClick={(e) =>
                                this.setState({ anchorEl: e.currentTarget })
                              }
                              label="Field ▾"
                              size="small"
                            />
                            <Menu
                              style={{ maxHeight: 600 }}
                              getContentAnchorEl={null}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                              }}
                              anchorEl={this.state.anchorEl}
                              open={Boolean(this.state.anchorEl)}
                              onClose={() => this.setState({ anchorEl: null })}
                            >
                              {searchItemsToAdd.map((sc) => (
                                <MenuItem
                                  dense
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    this.setState({
                                      anchorEl: null,
                                    });
                                    this.handleChange(
                                      sc.name,
                                      undefined,
                                      undefined,
                                      -1
                                    )({ target: { value: sc.default } });
                                  }}
                                >
                                  <ListItemText
                                    primary={sc.label}
                                    secondary={sc.type}
                                  />
                                </MenuItem>
                              ))}
                            </Menu>
                          </Grid>
                          {searchPresets &&
                          searchPresets.filter(
                            (s) => s.global && s.keyword === keyword
                          ).length ? (
                            <Grid item>
                              <Chip
                                label="Global Presets ▾"
                                size="small"
                                onClick={(e) =>
                                  this.setState({
                                    globalPresetChipEl: e.currentTarget,
                                  })
                                }
                              />
                              <Menu
                                open={Boolean(globalPresetChipEl)}
                                getContentAnchorEl={null}
                                getContentAnchorEl={null}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "center",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "center",
                                }}
                                anchorEl={globalPresetChipEl}
                                onClose={() =>
                                  this.setState({ globalPresetChipEl: null })
                                }
                              >
                                {searchPresets &&
                                  searchPresets
                                    .filter(
                                      (s) => s.global && s.keyword === keyword
                                    )
                                    .map((sp) => {
                                      const same =
                                        JSON.stringify(filters) === sp.filter;
                                      return (
                                        <MenuItem
                                          dense
                                          item
                                          key={sp.id}
                                          style={{
                                            borderLeft: `solid 3px ${sp.color}`,
                                          }}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            console.log(JSON.parse(sp.filter));
                                            this.setState(
                                              {
                                                filters: JSON.parse(sp.filter),
                                                globalPresetChipEl: null,
                                              },
                                              () => this.updateFilters()
                                            );
                                          }}
                                        >
                                          <ListItemText
                                            primary={sp.name}
                                            secondary={
                                              sp.global ? "Global" : ""
                                            }
                                          />
                                          {sp.userUUID === user.uuid ? (
                                            <ListItemSecondaryAction>
                                              <IconButton
                                                size="small"
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  e.preventDefault();
                                                  deleteSearchPreset(
                                                    sp.id
                                                  ).then(getSearchPresets);
                                                }}
                                              >
                                                <Clear
                                                  style={{ fontSize: 14 }}
                                                />
                                              </IconButton>
                                            </ListItemSecondaryAction>
                                          ) : (
                                            []
                                          )}
                                        </MenuItem>
                                      );
                                    })}
                              </Menu>
                            </Grid>
                          ) : (
                            []
                          )}
                          {searchPresets &&
                          searchPresets.filter(
                            (s) =>
                              !s.global &&
                              s.keyword === keyword &&
                              s.userUUID === user.uuid
                          ).length ? (
                            <Grid item>
                              <Chip
                                label="My Presets ▾"
                                size="small"
                                onClick={(e) =>
                                  this.setState({
                                    myPresetChipEl: e.currentTarget,
                                  })
                                }
                              />
                              <Menu
                                open={Boolean(myPresetChipEl)}
                                anchorEl={myPresetChipEl}
                                getContentAnchorEl={null}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "center",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "center",
                                }}
                                onClose={() =>
                                  this.setState({ myPresetChipEl: null })
                                }
                              >
                                {searchPresets &&
                                  searchPresets
                                    .filter(
                                      (s) =>
                                        !s.global &&
                                        s.keyword === keyword &&
                                        s.userUUID === user.uuid
                                    )
                                    .map((sp) => {
                                      const same =
                                        JSON.stringify(filters) === sp.filter;
                                      return (
                                        <MenuItem
                                          dense
                                          item
                                          key={sp.id}
                                          style={{
                                            borderLeft: `solid 3px ${sp.color}`,
                                          }}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            console.log(JSON.parse(sp.filter));
                                            this.setState(
                                              {
                                                filters: JSON.parse(sp.filter),
                                                myPresetChipEl: null,
                                              },
                                              () => this.updateFilters()
                                            );
                                          }}
                                        >
                                          <ListItemText
                                            primary={sp.name}
                                            secondary={
                                              sp.global ? "Global" : ""
                                            }
                                          />
                                          {sp.userUUID === user.uuid ? (
                                            <ListItemSecondaryAction>
                                              <IconButton
                                                size="small"
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  e.preventDefault();
                                                  deleteSearchPreset(
                                                    sp.id
                                                  ).then(getSearchPresets);
                                                }}
                                              >
                                                <Clear
                                                  style={{ fontSize: 14 }}
                                                />
                                              </IconButton>
                                            </ListItemSecondaryAction>
                                          ) : (
                                            []
                                          )}
                                        </MenuItem>
                                      );
                                    })}
                              </Menu>
                            </Grid>
                          ) : (
                            []
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item style={{ marginTop: -8, display: "flex" }}>
                    {exportEndpoint ? (
                      <>
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          style={{ display: "flex", alignItems: "center" }}
                          onClick={() => {
                            const a = encodeURI(
                              `${REACT_APP_API}${REACT_APP_API_PREFIX}${exportEndpoint}?filters=${JSON.stringify(
                                filters
                              )}`
                            );
                            window.open(a, "_blank");
                          }}
                        >
                          <CloudDownload
                            style={{
                              fontSize: 14,
                              marginRight: 4,
                            }}
                          />{" "}
                          Export in CSV
                        </Typography>
                        {this.props.rowCount ? (
                          <Typography
                            variant="caption"
                            color="textSecondary"
                            style={{ margin: "0px 8px" }}
                          >
                            {" - "}
                          </Typography>
                        ) : (
                          []
                        )}
                      </>
                    ) : (
                      []
                    )}
                    {this.props.rowCount ? (
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        style={{ marginRight: 4 }}
                      >
                        Total: {this.props.rowCount} Rows
                      </Typography>
                    ) : (
                      []
                    )}
                  </Grid>
                </Grid>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div style={{ width: "100%" }}>
                  <Grid container alignItems="center">
                    {filters.map((f, index) =>
                      this.getSearchField(
                        searchItems.find((si) => si.name === f.name).type,
                        f.name,
                        index
                      )
                    )}
                    {/* <Grid item>
                      <Chip
                        label={'+ Search Item'}
                        variant="outlined"
                        onClick={this.handleClick}
                      />
                      <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={this.handleClose}
                      >
                        {
                          searchItemsToAdd && searchItemsToAdd.map(sc => (
                            <MenuItem
                              key={`${sc.name}`}
                              onClick={() => {
                                this.handleClose();
                                this.handleChange(sc.name, undefined, undefined, -1)({ target: { value: sc.default } });
                              }}
                            >
                              {sc.label}
                            </MenuItem>
                          ))
                        }
                      </Menu>
                    </Grid> */}
                  </Grid>
                  <Grid
                    container
                    justify="space-between"
                    spacing={1}
                    style={{ marginTop: 8 }}
                  >
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item>
                      <Chip
                        onClick={this.clearFilters.bind(this)}
                        variant="outlined"
                        label="Clear"
                        style={{ marginLeft: 8 }}
                        icon={<Clear />}
                      />
                      <Chip
                        onClick={() => this.setState({ presetOpen: true })}
                        label="Save as Preset"
                        style={{ marginLeft: 8 }}
                        icon={<SaveAltIcon />}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item>
                      <Chip
                        onClick={this.updateFilters.bind(this)}
                        color="primary"
                        label="Search"
                        style={{ padding: "0px 16px" }}
                        icon={<Search />}
                      />
                    </Grid>
                  </Grid>
                </div>
              </ExpansionPanelDetails>
              <FormPreset
                createSearchPreset={createSearchPreset}
                open={presetOpen}
                filters={JSON.stringify(filters)}
                keyword={keyword}
                refresh={getSearchPresets}
                close={() => this.setState({ presetOpen: false })}
              />
            </ExpansionPanel>
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            {loading ? <CircularProgress /> : children}
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AdvancedSearch));
