import * as Api from "api";
import {
  CREATE_EXPORT,
  DELETE_EXPORT,
  GET_EXPORTS,
  GET_EXPORT_BY_ID,
  UPDATE_EXPORT,
} from "constants/exports";
import { networkAction } from "helpers/network/networkAction";

export const getExports = () => async (dispatch) =>
  networkAction(dispatch, GET_EXPORTS, Api.getExports, []);

export const getExportById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_EXPORT_BY_ID, Api.getExportById, [id]);

export const deleteExport = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_EXPORT, Api.deleteExport, [id]);

export const createExport = (filters) => async (dispatch) =>
  networkAction(dispatch, CREATE_EXPORT, Api.createExport, [filters]);

export const updateExport = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_EXPORT, Api.updateExport, [id, body]);
