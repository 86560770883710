import * as Api from "api";
import {
  COMPANIES_GL_LOCK_DATES,
  CREATE_COMPANY,
  DELETE_COMPANY,
  GET_COMPANIES,
  GET_COMPANY_BY_ID,
  UPDATE_COMPANY,
} from "constants/companies";
import { networkAction } from "helpers/network/networkAction";

export const getCompanies = () => async (dispatch) =>
  networkAction(dispatch, GET_COMPANIES, Api.getCompanies, []);

export const getCompanyById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_COMPANY_BY_ID, Api.getCompanyById, [id]);

export const deleteCompany = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_COMPANY, Api.deleteCompany, [id]);

export const createCompany = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_COMPANY, Api.createCompany, [body]);

export const updateCompany = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_COMPANY, Api.updateCompany, [id, body]);

export const companiesGlLockDates = (id, body) => async (dispatch) =>
  networkAction(dispatch, COMPANIES_GL_LOCK_DATES, Api.companiesGlLockDates, [
    id,
    body,
  ]);
