import { IconButton, Menu, MenuItem, Tooltip } from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import Grid from "@material-ui/core/Grid";
// material-ui
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import BeenhereIcon from "@material-ui/icons/Beenhere";
import FlagIcon from "@material-ui/icons/Flag";
import File from "@material-ui/icons/InsertDriveFileTwoTone";
import List from "@material-ui/icons/List";
import Lock from "@material-ui/icons/Lock";
import LockOpen from "@material-ui/icons/LockOpenTwoTone";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Rating } from "@material-ui/lab";
import AdvancedSearch from "components/AdvancedSearchRefactor";
// custom
import Table from "components/TablePagination";
import { REACT_APP_API_PREFIX } from "config";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

const PayableListMenu = ({ payable, onDuplicate }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(null);
  };

  const handleDuplicate = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(null);
    onDuplicate(payable);
  };

  return (
    <div>
      <IconButton onClick={handleClick} aria-label="delete" size="small">
        <MoreVertIcon fontSize="inherit" />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleDuplicate}>Duplicate</MenuItem>
      </Menu>
    </div>
  );
};
class PayablesList extends Component {
  static propTypes = {
    payables: PropTypes.array,
    users: PropTypes.array,
    dataLoading: PropTypes.bool,
    onSelect: PropTypes.func,
    onDoubleSelect: PropTypes.func,
    selectedPayable: PropTypes.object,
    advancedSearch: PropTypes.bool,
    history: PropTypes.object,
    location: PropTypes.object,
    filters: PropTypes.array,
    companies: PropTypes.array,
    changeStep: PropTypes.func,
    getPayables: PropTypes.func,
    onDuplicate: PropTypes.func,
    vendors: PropTypes.array,
    noAdvancedSearch: PropTypes.bool,
    groupSelectEnabled: PropTypes.bool,
    onGroupSelect: PropTypes.func,
    priorityLevels: PropTypes.array,
  };

  render() {
    const {
      payables,
      selectedPayable,
      history,
      onSelect,
      onDoubleSelect,
      changeStep,
      getPayables,
      companies,
      vendors,
      noAdvancedSearch,
      groupSelectEnabled,
      onGroupSelect,
      priorityLevels,
      onDuplicate,
      users,
    } = this.props;

    let data = payables;

    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });

    const TABLE = (
      <Table
        data={data}
        groupSelectEnabled={groupSelectEnabled}
        onGroupSelect={onGroupSelect}
        noToolbar
        rowStyle={(d) => ({
          borderLeft: d.approved ? "solid 3px #4caf50" : "solid 3px #9e9e9e",
          background:
            selectedPayable && selectedPayable.id === d.id
              ? "rgba(33,150,243, 0.1)"
              : undefined,
        })}
        meta={[
          {
            path: "locked",
            title: "Status",
            width: 100,
            render: (t, d) => (
              <Grid container spacing={1} alignItems="center">
                {d.lineItems && d.lineItems.length ? (
                  <Grid item>
                    <Badge badgeContent={d.lineItems.length}>
                      <List
                        style={{ fontSize: 18 }}
                        onClick={() => changeStep(1)}
                      />
                    </Badge>
                  </Grid>
                ) : (
                  <Grid item>
                    <List
                      style={{ fontSize: 18, opacity: 0.3 }}
                      onClick={() => changeStep(1)}
                    />
                  </Grid>
                )}
                {d.files && d.files.length ? (
                  <Grid item>
                    <Badge badgeContent={d.files.length}>
                      <File
                        style={{ fontSize: 18 }}
                        onClick={() => changeStep(2)}
                      />
                    </Badge>
                  </Grid>
                ) : (
                  <Grid item>
                    <File
                      style={{ fontSize: 18, opacity: 0.3 }}
                      onClick={() => changeStep(2)}
                    />
                  </Grid>
                )}
                {d.locked ? (
                  <Grid item>
                    <Badge>
                      <Lock style={{ fontSize: 18 }} />
                    </Badge>
                  </Grid>
                ) : (
                  <Grid item>
                    <Badge>
                      <LockOpen style={{ fontSize: 18 }} />
                    </Badge>
                  </Grid>
                )}
                {d.exportID ? (
                  <Grid item>
                    <Badge>
                      <Tooltip title={`Exported on batch #${d.exportID}`}>
                        <BeenhereIcon style={{ fontSize: 18 }} />
                      </Tooltip>
                    </Badge>
                  </Grid>
                ) : (
                  []
                )}
              </Grid>
            ),
          },
          {
            path: "referenceNumber",
            title: "Ref",
            numeric: false,
          },
          {
            path: "date",
            title: "Date",
            numeric: false,
            transform: (t) => moment(t).format("lll"),
          },
          {
            path: "createdBy",
            title: "Created By",
            numeric: false,
            transform: (t) => `${t ? t.firstName : ""} ${t ? t.lastName : ""}`,
            render: (t, d) => (
              <>
                <Typography variant="body2">{t}</Typography>
                <Typography variant="caption" color="textSecondary">
                  {moment(d.createdAt).format("lll")}
                </Typography>
              </>
            ),
          },
          {
            path: "assignedTo",
            title: "Assign To",
            numeric: false,
            render: (t, d) => (
              <div>
                {d.assignedTo ? (
                  <Grid container spacing={1} alignItems="center">
                    <Grid item style={{ width: "calc(100% - 50px)" }}>
                      <Typography display="block" variant="body2">
                        {d.assignedTo.firstName} {d.assignedTo.lastName}
                      </Typography>
                      {d.approved ? (
                        <Typography
                          display="block"
                          variant="caption"
                          style={{ color: "#4caf50" }}
                        >
                          Approved
                        </Typography>
                      ) : (
                        <Typography
                          display="block"
                          variant="caption"
                          color="textSecondary"
                        >
                          Not Approved
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                ) : (
                  <Typography
                    display="block"
                    variant="caption"
                    color="textSecondary"
                  >
                    Unassigned
                  </Typography>
                )}
              </div>
            ),
          },
          {
            path: "dualAssignedTo",
            title: "Dual Assign To",
            numeric: false,
            render: (t, d) => (
              <div>
                {d.dualAssignedTo ? (
                  <Grid container spacing={1} alignItems="center">
                    <Grid item style={{ width: "calc(100% - 50px)" }}>
                      <Typography display="block" variant="body2">
                        {d.dualAssignedTo.firstName} {d.dualAssignedTo.lastName}
                      </Typography>
                      {d.dualApproved ? (
                        <Typography
                          display="block"
                          variant="caption"
                          style={{ color: "#4caf50" }}
                        >
                          Approved
                        </Typography>
                      ) : (
                        <Typography
                          display="block"
                          variant="caption"
                          color="textSecondary"
                        >
                          Not Approved
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                ) : (
                  <Typography
                    display="block"
                    variant="caption"
                    color="textSecondary"
                  >
                    Unassigned
                  </Typography>
                )}
              </div>
            ),
          },
          {
            path: "vendorName",
            title: "Vendor",
            numeric: false,
          },
          {
            path: "amount",
            title: "Amount",
            numeric: true,
            render: (t, d) => {
              let balance = 0;
              d &&
                d.lineItems &&
                d.lineItems.forEach((l) => (balance += l.amount));
              return (
                <React.Fragment>
                  <Typography display="block" variant="body2">
                    <b>{formatter.format(d.amount)}</b>
                  </Typography>
                  <Typography
                    display="block"
                    variant="caption"
                    color="textSecondary"
                  >
                    Balance: {formatter.format(balance)}
                  </Typography>
                </React.Fragment>
              );
            },
          },
          {
            path: "priorityLevelID",
            title: "Priority",
            render: (t, d) => {
              if (d.priorityLevelID === 0) return <div />;
              return (
                <div style={{ textAlign: "right" }}>
                  <Rating
                    size="small"
                    style={{
                      color: "#f44336",
                    }}
                    value={
                      t && priorityLevels
                        ? priorityLevels.length - d.priorityLevelID + 1
                        : null
                    }
                    readOnly
                    defaultValue={1}
                    max={priorityLevels.length}
                    icon={<FlagIcon fontSize="inherit" />}
                  />
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    display="block"
                  >
                    {`${d.priorityLevel.followUpDays} Follow up days`}
                  </Typography>
                </div>
              );
            },
          },
          {
            path: "id",
            title: "",
            render: (t, d) => (
              <PayableListMenu payable={d} onDuplicate={onDuplicate} />
            ),
          },
        ]}
        title={"Payables"}
        onRowSelect={onSelect}
        onRowDoubleSelect={onDoubleSelect}
      />
    );

    if (noAdvancedSearch) {
      return TABLE;
    }

    return (
      <AdvancedSearch
        history={history}
        rowCount={payables.length}
        location={window.location}
        disabled={groupSelectEnabled}
        refresh={getPayables}
        keyword="payables"
        metaDataEndpoint={`${REACT_APP_API_PREFIX}/ap-dashboard/payables/searchMetadata`}
        metaDataOveride={{
          companyID: {
            type: "autocomplete",
            data: companies,
            valueKey: "id",
            labelKey: "name",
          },
          vendorID: {
            type: "autocomplete",
            data: vendors,
            valueKey: "id",
            labelKey: "name",
          },
          assignedToUUID: {
            type: "autocomplete",
            data: users,
            valueKey: "uuid",
            labelKey: "email",
          },
          transactionType: {
            type: "autocomplete",
            data: [
              { id: "0", name: "Bill" },
              { id: "1", name: "Credit" },
              { id: "2", name: "Check" },
              { id: "3", name: "Journal Entry" },
            ],
            valueKey: "id",
            labelKey: "name",
          },
          createdByUUID: {
            type: "autocomplete",
            data: users,
            valueKey: "uuid",
            labelKey: "email",
          },
          priorityLevelID: {
            type: "autocomplete",
            data: priorityLevels,
            valueKey: "id",
            labelKey: "name",
          },
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {TABLE}
          </Grid>
        </Grid>
      </AdvancedSearch>
    );
  }
}

export default withStyles(styles)(PayablesList);
