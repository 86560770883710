import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

// mui
import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';

// reactor
import Loading from 'components/Loading';

// styles
const styles = theme => ({
  background: {
    background: theme.palette.background.default,
  },
  paper: {
    background: theme.palette.background.paper,
    position: 'sticky',
    top: 0,
    zIndex: 999,
  },
});

class Page extends Component {
  static propTypes = {
    classes: PropTypes.object,
    tabs: PropTypes.any,
    helmet: PropTypes.string,
    header: PropTypes.any,
    loadingMessage: PropTypes.string,
    loading: PropTypes.bool,
    children: PropTypes.node,
    noPadding: PropTypes.bool,
    fab: PropTypes.node,
  };

  render() {
    const {
      classes,
      helmet,
      tabs,
      loading,
      loadingMessage,
      children,
      header,
      noPadding,
      fab,
    } = this.props;

    return (
      <div>
        <div className={`${classes.background} page`}>
          <Helmet>
            <title>{helmet}</title>
          </Helmet>
          <Paper
            className={classes.paper}
          >
            {
              header
            }
            {
            tabs
              ? (
                <AppBar
                  position="relative"
                  className="appBar"
                  style={{
                    boxShadow: 'none',
                  }}
                >
                  {tabs}
                </AppBar>
              ) : <React.Fragment />
          }
          </Paper>
          {
            loading
              ? (
                <Loading
                  message={loadingMessage}
                />
              )
              : (
                <div className="container">
                  <div className={noPadding ? 'noPadding' : 'padding'}>
                    {children}
                  </div>
                </div>
              )
          }
        </div>
        {
          fab
            ? (
              <div
                style={{
                  position: 'absolute',
                  bottom: 20,
                  right: 20,
                }}
              >
                {fab}
              </div>
            ) : <React.Fragment />
        }
      </div>
    );
  }
}

export default withStyles(styles)(Page);