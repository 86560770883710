import {
  GET,
  PUT,
  DELETE,
  POST,
} from 'constants/methods';

import { REACT_APP_API_PREFIX } from 'config/env';

const service = 'account';
const roles = `/${REACT_APP_API_PREFIX}/${service}/roles`;

export function getRoles(filters) {
  const url = filters ? `${roles}${filters}` : `${roles}`;
  return {
    service,
    method: GET,
    url,
  };
}

export function getRoleById(id) {
  const url = `${roles}/${id}`;
  return {
    service,
    method: GET,
    url,
  };
}

export function updateRole(id, body) {
  const url = `${roles}/${id}`;
  return {
    service,
    method: PUT,
    url,
    body,
  };
}

export function deleteRole(id) {
  const url = `${roles}/${id}`;
  return {
    service,
    method: DELETE,
    url,
  };
}

export function createRole(body) {
  const url = `${roles}`;
  return {
    service,
    method: POST,
    url,
    body,
  };
}
