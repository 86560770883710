import { REACT_APP_API_PREFIX } from "config/env";
import { DATA, DELETE, GET, POST, PUT } from "constants/methods";

const service = "ap-dashboard";
const vendors = `${REACT_APP_API_PREFIX}/${service}/vendors`;

export function getVendors(filters) {
  const url = `${vendors}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getVendorById(id) {
  const url = `${vendors}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateVendor(id, body) {
  const url = `${vendors}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteVendor(id) {
  const url = `${vendors}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createVendor(body) {
  const url = `${vendors}`;
  return {
    method: POST,
    url,
    body,
  };
}

export function importVendors(file, companyID) {
  const data = new FormData();
  data.append("file", file);
  data.append("companyID", companyID);

  const url = `${vendors}/import`;

  return {
    method: DATA,
    url,
    body: data,
  };
}
