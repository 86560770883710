export const GET_JOURNAL_ENTRIES = 'GET_JOURNAL_ENTRIES';
export const GET_JOURNAL_ENTRY_BY_ID = 'GET_JOURNAL_ENTRY_BY_ID';
export const UPDATE_JOURNAL_ENTRY = 'UPDATE_JOURNAL_ENTRY';
export const DELETE_JOURNAL_ENTRY = 'DELETE_JOURNAL_ENTRY';
export const CREATE_JOURNAL_ENTRY = 'CREATE_JOURNAL_ENTRY';
export const UPDATE_JOURNAL_ENTRY_ITEM = 'UPDATE_JOURNAL_ENTRY_ITEM';
export const DELETE_JOURNAL_ENTRY_ITEM = 'DELETE_JOURNAL_ENTRY_ITEM';
export const CREATE_JOURNAL_ENTRY_ITEM = 'CREATE_JOURNAL_ENTRY_ITEM';
export const LOCK_JOURNAL_ENTRY = 'LOCK_JOURNAL_ENTRY';
export const UPLOAD_JOURNAL_ENTRY_FILE = 'UPLOAD_JOURNAL_ENTRY_FILE';
