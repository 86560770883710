import React from 'react';
import PropTypes from 'prop-types';
import hotkeys from 'hotkeys-js';

// material-ui
import withStyles from '@material-ui/core/styles/withStyles';

// styles
import styles from './styles';

class KeyboardEvents extends React.Component {
  static propTypes = {
    children: PropTypes.node,
  };

  static childContextTypes = {
    KeyboardEventCenter: PropTypes.object,
  };

  getChildContext() {
    return {
      KeyboardEventCenter: {
        listen: this.listen.bind(this),
        disregard: this.disregard.bind(this),
      },
    };
  }

  listen(combination, callback) {
    hotkeys(combination, (e, handler) => {
      callback(handler);
    });
  }

  disregard(combination) {
    hotkeys.unbind(combination);
  }

  render() {
    const { children } = this.props;

    return (
      <div>
        {children}
      </div>
    );
  }
}

export default withStyles(styles)(KeyboardEvents);
