import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React from "react";

// styles
const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
    width: "100%",
    zIndex: 1,
    overflow: "hidden",
    position: "absolute",
    display: "flex",
    background: theme.palette.background.paper,
  },
});

class Loading extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    message: PropTypes.string,
  };

  render() {
    const { classes, message } = this.props;

    return (
      <Grid
        container
        className={classes.root}
        alignContent="center"
        alignItems="center"
      >
        <Grid
          item
          xs={12}
          style={{
            textAlign: "center",
          }}
        >
          <CircularProgress />
          <Typography display="block" variant="caption">
            {message}
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Loading);
