// actions
import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// component
import WrapperGlAccountPage from "../component/WrapperGlAccountPage";

const mapStateToProps = (state) => ({
  glAccounts: state.glAccounts,
  companies: state.companies,
});

const mapDispatchToProps = (dispatch) => ({
  getGlAccounts: () => dispatch(Actions.getGlAccounts()),
  getGlAccountById: (...args) => dispatch(Actions.getGlAccountById(...args)),
  createGlAccount: (...args) => dispatch(Actions.createGlAccount(...args)),
  deleteGlAccount: (...args) => dispatch(Actions.deleteGlAccount(...args)),
  updateGlAccount: (...args) => dispatch(Actions.updateGlAccount(...args)),
  getCompanies: (...args) => dispatch(Actions.getCompanies(...args)),
});
class GlAccountPage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    glAccounts: PropTypes.array,
    getGlAccounts: PropTypes.func,
    getGlAccountById: PropTypes.func,
    createGlAccount: PropTypes.func,
    deleteGlAccount: PropTypes.func,
    updateGlAccount: PropTypes.func,

    getCompanies: PropTypes.func,
    companies: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { location, glAccounts, getCompanies } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: glAccounts.length === 0,
    };

    getCompanies();
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.refresh();
  }

  async refresh() {
    const { getGlAccounts } = this.props;
    await Promise.all([getGlAccounts()]);

    this.setState({ loading: false });
  }

  render() {
    const {
      history,
      glAccounts,
      getGlAccountById,
      createGlAccount,
      deleteGlAccount,
      updateGlAccount,
      getGlAccounts,
      companies,
    } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperGlAccountPage
        history={history}
        refresh={this.refresh.bind(this)}
        loading={loading}
        glAccounts={glAccounts}
        getGlAccounts={getGlAccounts}
        getGlAccountById={getGlAccountById}
        createGlAccount={createGlAccount}
        deleteGlAccount={deleteGlAccount}
        updateGlAccount={updateGlAccount}
        urlParams={urlParams}
        companies={companies}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(GlAccountPage);
