import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
// material-ui
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Close from "@material-ui/icons/Close";
import Email from "@material-ui/icons/Email";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class User extends Component {
  static propTypes = {
    classes: PropTypes.object,
    user: PropTypes.object,
    deleteOrganizationUser: PropTypes.func,
    refresh: PropTypes.func,
  };

  async deleteOrganizationUser() {
    const { deleteOrganizationUser, user, refresh } = this.props;
    await deleteOrganizationUser(user.relationID);
    refresh();
  }

  render() {
    const { classes, user } = this.props;

    return (
      <div className={!user.pending ? classes.container : classes.pending}>
        <Close
          style={{
            position: "absolute",
            top: 4,
            right: 4,
            cursor: "pointer",
            height: 15,
            zIndex: 999,
          }}
          onClick={this.deleteOrganizationUser.bind(this)}
        />
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <Avatar>
              {user.active ? (
                `${user.firstName[0]}${user.lastName[0]}`
              ) : (
                <Email />
              )}
            </Avatar>
          </Grid>
          <Grid item xs={10}>
            <Typography
              display="block"
              style={{ width: 220, overflowWrap: "break-word" }}
            >
              {user.firstName} {user.lastName}
            </Typography>
            <Typography
              display="block"
              variant="caption"
              color="textSecondary"
              style={{ width: 220, overflowWrap: "break-word" }}
            >
              {user.email}
            </Typography>
            {user.pending ? (
              <Typography display="block" style={{ color: "#e91e63" }}>
                Invited
              </Typography>
            ) : (
              []
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(User);
