// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import FormVendor from "components/FormVendor";
import Page from "components/Page";
// components
import VendorsList from "components/VendorsList";
import ImportVendors from "hooks/ImportVendors";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class WrapperVendorsPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    loading: PropTypes.bool,

    //vendors
    vendors: PropTypes.array,
    getVendorById: PropTypes.func,
    createVendor: PropTypes.func,
    deleteVendor: PropTypes.func,
    updateVendor: PropTypes.func,
    getVendors: PropTypes.func,

    //vendorItems
    getVendorLineItems: PropTypes.func,
    createVendorLineItem: PropTypes.func,
    deleteVendorLineItem: PropTypes.func,
    updateVendorLineItem: PropTypes.func,

    glAccounts: PropTypes.array,
    itemClasses: PropTypes.array,
    companies: PropTypes.array,
    users: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedVendor: undefined,
      formVendorOpen: false,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const { vendors, history, getVendors, companies } = this.props;

    switch (index) {
      case 0:
        return (
          <VendorsList
            vendors={vendors}
            companies={companies}
            history={history}
            getVendors={getVendors}
            onSelect={(selectedVendor) =>
              this.setState({
                selectedVendor,
                formVendorOpen: true,
              })
            }
          />
        );
      default:
    }
  }

  render() {
    const {
      classes,
      history,
      loading,
      createVendor,
      deleteVendor,
      updateVendor,
      getVendorLineItems,
      createVendorLineItem,
      deleteVendorLineItem,
      updateVendorLineItem,
      glAccounts,
      itemClasses,
      companies,
      refresh,
      users,
    } = this.props;

    const { index, selectedVendor, formVendorOpen } = this.state;

    return (
      <Page
        helmet={"Vendors"}
        loadingMessage={"Loading Document"}
        loading={loading}
        // fab={
        //   <Fab
        //     variant="fab"
        //     color="primary"
        //     aria-label="Add"
        //     className={classes.floatingActionButton}
        //     onClick={() => this.setState({ formVendorOpen: true })}
        //   >
        //     <AddIcon />
        //   </Fab>
        // }
        tabs={
          <div className={classes.background}>
            <Tabs
              value={index}
              onChange={(e, v) => {
                this.setState({ index: v });
                history.push(`?index=${v}`);
              }}
              style={{ flexGrow: 1 }}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
              classes={{
                root: classes.tabsRoot,
                indicator: classes.tabsIndicator,
              }}
            >
              <Tab
                label="Vendors"
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
              />
            </Tabs>
            <ImportVendors refresh={refresh} />
          </div>
        }
      >
        {this.getStepContent()}
        <FormVendor
          open={formVendorOpen}
          vendor={selectedVendor}
          close={() => {
            this.setState({
              formVendorOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedVendor: undefined,
                }),
              200
            );
          }}
          createVendor={createVendor}
          deleteVendor={deleteVendor}
          updateVendor={updateVendor}
          getVendorLineItems={getVendorLineItems}
          createVendorLineItem={createVendorLineItem}
          deleteVendorLineItem={deleteVendorLineItem}
          updateVendorLineItem={updateVendorLineItem}
          companies={companies}
          glAccounts={glAccounts}
          users={users}
          itemClasses={itemClasses}
          refresh={refresh}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperVendorsPage);
