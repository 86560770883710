import * as Api from "api";
import {
  CREATE_PAYABLE,
  CREATE_PAYABLE_LINE_ITEM,
  DELETE_PAYABLE,
  DELETE_PAYABLE_FILE,
  DELETE_PAYABLE_LINE_ITEM,
  GET_MY_PAYABLES,
  GET_PAYABLES,
  GET_PAYABLE_BY_ID,
  LOCK_PAYABLE,
  QB_EXPORT,
  UPDATE_GL_ACCOUNT,
  UPDATE_PAYABLE,
  UPDATE_PAYABLE_LINE_ITEM,
  UPDATE_LINE_ITEM_INTERNAL_MEMO,
  UPLOAD_LINE_ITEM_FILE,
  UPLOAD_PAYABLE_FILE,
} from "constants/payables";
import { networkAction } from "helpers/network/networkAction";

export const getPayables = (filters) => async (dispatch) =>
  networkAction(dispatch, GET_PAYABLES, Api.getPayables, [filters]);

export const qbExport = (filters) => async (dispatch) =>
  networkAction(dispatch, QB_EXPORT, Api.qbExport, [filters]);

export const getMyPayables = (filters) => async (dispatch) =>
  networkAction(dispatch, GET_MY_PAYABLES, Api.getMyPayables, [filters]);

export const getPayableById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_PAYABLE_BY_ID, Api.getPayableById, [id]);

export const lockPayable = (id, body) => async (dispatch) =>
  networkAction(dispatch, LOCK_PAYABLE, Api.lockPayable, [id, body]);

export const updateGLAccount = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_GL_ACCOUNT, Api.updateGLAccount, [id, body]);

export const deletePayableFile = (payableID, fileID) => async (dispatch) =>
  networkAction(dispatch, DELETE_PAYABLE_FILE, Api.deletePayableFile, [
    payableID,
    fileID,
  ]);

export const uploadPayableFile = (id, file) => async (dispatch) =>
  networkAction(dispatch, UPLOAD_PAYABLE_FILE, Api.uploadPayableFile, [
    id,
    file,
  ]);

export const uploadLineItemFile = (id, file) => async (dispatch) =>
  networkAction(dispatch, UPLOAD_LINE_ITEM_FILE, Api.uploadLineItemFile, [
    id,
    file,
  ]);

export const deletePayable = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_PAYABLE, Api.deletePayable, [id]);

export const createPayable = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_PAYABLE, Api.createPayable, [body]);

export const updatePayable = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_PAYABLE, Api.updatePayable, [id, body]);

export const createPayableLineItem = (payableID, body) => async (dispatch) =>
  networkAction(dispatch, CREATE_PAYABLE_LINE_ITEM, Api.createPayableLineItem, [
    payableID,
    body,
  ]);

export const updatePayableLineItem = (payableID, lineItemID, body) => async (
  dispatch
) =>
  networkAction(dispatch, UPDATE_PAYABLE_LINE_ITEM, Api.updatePayableLineItem, [
    payableID,
    lineItemID,
    body,
  ]);

export const updateLineItemInternalMemo = (payableID, lineItemID, body) => async (
  dispatch
) =>
  networkAction(dispatch, UPDATE_LINE_ITEM_INTERNAL_MEMO, Api.updateLineItemInternalMemo, [
    payableID,
    lineItemID,
    body,
  ]);

export const deletePayableLineItem = (payableID, lineItemID) => async (
  dispatch
) =>
  networkAction(dispatch, DELETE_PAYABLE_LINE_ITEM, Api.deletePayableLineItem, [
    payableID,
    lineItemID,
  ]);
