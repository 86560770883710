import { serviceRoles } from "config/access";
import { VENDORS } from "constants/routes";
import { checkAccess } from "helpers/auth";
import Page from "./container/VendorsPage";

export default function getSettingsRoute(userAuth) {
  const { account, apDashboard } = serviceRoles;
  const requireAuth = {
    [account.name]: [account.roles.superadmin],
    [apDashboard.name]: [
      apDashboard.roles.accounting_manager,
      apDashboard.roles.accounting,
    ],
  };
  return {
    requireAuth,
    onEnter: () => checkAccess(requireAuth, userAuth),
    path: VENDORS,
    withSidebar: true,
    withAppBar: true,
    component: Page,
  };
}
