import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// component
import WrapperItemClassePage from '../component/WrapperItemClassePage';

// helpers
import { getJsonFromUrl } from 'helpers';

// actions
import * as Actions from 'actions';

const mapStateToProps = state => ({
  itemClasses: state.itemClasses,
  companies: state.companies,
});

const mapDispatchToProps = dispatch => ({
  getItemClasses: () => dispatch(Actions.getItemClasses()),
  getItemClasseById: (...args) => dispatch(Actions.getItemClasseById(...args)),
  createItemClasse: (...args) => dispatch(Actions.createItemClasse(...args)),
  deleteItemClasse: (...args) => dispatch(Actions.deleteItemClasse(...args)),
  updateItemClasse: (...args) => dispatch(Actions.updateItemClasse(...args)),
  getCompanies: (...args) => dispatch(Actions.getCompanies(...args)),
});
class ItemClassePage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    itemClasses: PropTypes.array,
    getItemClasses: PropTypes.func,
    getItemClasseById: PropTypes.func,
    createItemClasse: PropTypes.func,
    deleteItemClasse: PropTypes.func,
    updateItemClasse: PropTypes.func,

    getCompanies: PropTypes.func,
    companies: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { location, itemClasses, getCompanies } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: itemClasses.length === 0,
    };

    getCompanies();
  }

  componentDidMount() {
    console.log('componentDidMount');
    this.refresh();
  }

  async refresh() {
    const { getItemClasses } = this.props;
    await Promise.all([
      getItemClasses(),
    ]);

    this.setState({ loading: false });
  }

  render() {
    const {
      history,
      itemClasses,
      getItemClasseById,
      createItemClasse,
      deleteItemClasse,
      updateItemClasse,
      companies,
    } = this.props;

    const {
      urlParams,
      loading,
    } = this.state;

    return (
      <WrapperItemClassePage
        history={history}
        refresh={this.refresh.bind(this)}
        loading={loading}
        itemClasses={itemClasses}
        getItemClasseById={getItemClasseById}
        createItemClasse={createItemClasse}
        deleteItemClasse={deleteItemClasse}
        updateItemClasse={updateItemClasse}
        companies={companies}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ItemClassePage);
