export default theme => ({
  bottomContainer: {
    background: 'rgba(255, 152, 0, 0.9)',
    padding: 10,
    position: 'absolute',
    bottom: 'bottom',
    zIndex: 999,
    width: 'calc(100vw - 20px)',
    backdropFilter: 'blur(10px)',
    WebkitBackdropFilter: 'blur(10px)',
  },
  container: {
    background: 'rgba(255, 152, 0, 0.9)',
    padding: 10,
    position: 'absolute',
    zIndex: 999,
    width: 'calc(100vw - 20px)',
    backdropFilter: 'blur(10px)',
    WebkitBackdropFilter: 'blur(10px)',
  },
});
