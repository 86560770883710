import { REACT_APP_API_PREFIX } from "config/env";
import { DELETE, GET, POST, PUT } from "constants/methods";

const service = "ap-dashboard";
const priorityLevels = `${REACT_APP_API_PREFIX}/${service}/priorityLevels`;

export function getPriorityLevels(filters) {
  const url = `${priorityLevels}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getPriorityLevelById(id) {
  const url = `${priorityLevels}/${id}/full`;
  return {
    method: GET,
    url,
  };
}

export function updatePriorityLevel(id, body) {
  const url = `${priorityLevels}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deletePriorityLevel(id) {
  const url = `${priorityLevels}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createPriorityLevel(body) {
  const url = `${priorityLevels}`;
  return {
    method: POST,
    url,
    body,
  };
}
