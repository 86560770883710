import * as Api from "api";
import {
  CREATE_VENDOR_LINE_ITEM,
  DELETE_VENDOR_LINE_ITEM,
  GET_VENDOR_LINE_ITEMS,
  GET_VENDOR_LINE_ITEM_BY_ID,
  UPDATE_VENDOR_LINE_ITEM,
} from "constants/vendorLineItems";
import { networkAction } from "helpers/network/networkAction";

export const getVendorLineItems = (filters) => async (dispatch) =>
  networkAction(dispatch, GET_VENDOR_LINE_ITEMS, Api.getVendorLineItems, [
    filters,
  ]);

export const getVendorLineItemById = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_VENDOR_LINE_ITEM_BY_ID,
    Api.getVendorLineItemById,
    [id]
  );

export const deleteVendorLineItem = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_VENDOR_LINE_ITEM, Api.deleteVendorLineItem, [
    id,
  ]);

export const createVendorLineItem = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_VENDOR_LINE_ITEM, Api.createVendorLineItem, [
    body,
  ]);

export const updateVendorLineItem = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_VENDOR_LINE_ITEM, Api.updateVendorLineItem, [
    id,
    body,
  ]);
