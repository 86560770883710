import {
  REACT_APP_PRIMARY_COLOR,
  REACT_APP_SECONDARY_COLOR,
} from 'config';

import * as Colors from '@material-ui/core/colors/';

export default {
  primary: {
    color: Colors[REACT_APP_PRIMARY_COLOR],
    light: Colors[REACT_APP_PRIMARY_COLOR][50],
    main: Colors[REACT_APP_PRIMARY_COLOR][800],
    dark: Colors[REACT_APP_PRIMARY_COLOR][900],
    contrastText: '#fff',
  },
  secondary: Colors[REACT_APP_SECONDARY_COLOR],
  error: Colors.red,
  contrastThreshold: 6,
  tonalOffset: 0.8,
};
