// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
// components
import ExportsList from "components/ExportsList";
import FormExport from "components/FormExport";
import Page from "components/Page";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class WrapperExportsPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    loading: PropTypes.bool,

    //exports
    exports: PropTypes.array,
    getExportById: PropTypes.func,
    createExport: PropTypes.func,
    deleteExport: PropTypes.func,
    updateExport: PropTypes.func,
    getExports: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedExport: undefined,
      formExportOpen: false,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const { exports, history, getExports } = this.props;

    switch (index) {
      case 0:
        return (
          <ExportsList
            exports={exports}
            getExports={getExports}
            history={history}
            onSelect={(selectedExport) =>
              history.push(
                `/payables?index=1&filters=%5B%7B"name"%3A"payables.exportID"%2C"comparison"%3A"eq"%2C"value"%3A"${selectedExport.id}"%7D%5D`
              )
            }
          />
        );
      default:
    }
  }

  render() {
    const {
      classes,
      history,
      loading,
      createExport,
      deleteExport,
      updateExport,
      refresh,
    } = this.props;

    const { index, selectedExport, formExportOpen } = this.state;

    return (
      <Page
        helmet={"Exports"}
        loadingMessage={"Loading Document"}
        loading={loading}
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({ index: v });
              history.push(`?index=${v}`);
            }}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            <Tab
              label="Exports"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        }
      >
        {this.getStepContent()}
        <FormExport
          open={formExportOpen}
          export={selectedExport}
          close={() => {
            this.setState({
              formExportOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedExport: undefined,
                }),
              200
            );
          }}
          createExport={createExport}
          deleteExport={deleteExport}
          updateExport={updateExport}
          refresh={refresh}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperExportsPage);
