import { networkAction } from 'helpers/network/networkAction';

import {
  GET_GL_ACCOUNTS,
  GET_GL_ACCOUNT_BY_ID,
  DELETE_GL_ACCOUNT,
  UPDATE_GL_ACCOUNT,
  CREATE_GL_ACCOUNT,
} from 'constants/glAccounts';

import * as Api from 'api';

export const getGlAccounts = () => async dispatch => networkAction(
  dispatch,
  GET_GL_ACCOUNTS,
  Api.getGlAccounts,
  [],
);

export const getGlAccountById = id => async dispatch => networkAction(
  dispatch,
  GET_GL_ACCOUNT_BY_ID,
  Api.getGlAccountById,
  [id],
);

export const deleteGlAccount = id => async dispatch => networkAction(
  dispatch,
  DELETE_GL_ACCOUNT,
  Api.deleteGlAccount,
  [id],
);

export const createGlAccount = body => async dispatch => networkAction(
  dispatch,
  CREATE_GL_ACCOUNT,
  Api.createGlAccount,
  [body],
);

export const updateGlAccount = (id, body) => async dispatch => networkAction(
  dispatch,
  UPDATE_GL_ACCOUNT,
  Api.updateGlAccount,
  [id, body],
);
