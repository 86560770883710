import { REACT_APP_API_PREFIX } from "config/env";
import { GET } from "constants/methods";

const service = "ap-dashboard";
const users = `${REACT_APP_API_PREFIX}/${service}/users`;

export function getUsers(filters) {
  const url = `${users}${filters ? `?filters=${JSON.stringify(filters)}` : ""}`;
  return {
    service,
    method: GET,
    url,
  };
}
