export default (theme) => ({
  floatingActionButton: {
    position: "fixed",
    right: 20,
    bottom: 20,
  },
  container: {
    padding: 20,
    height: "calc(100vh - 136px)",
    overflow: "scroll",
  },
  page: {
    height: "calc(100vh - 48px)",
    background: "#FAFAFA",
    position: "relative",
  },
  tabs: {
    background: "white",
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  background: {
    backgroundColor: theme.palette.background.paper,
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
  },
  tabsRoot: {
    borderBottom: "1px solid #e8e8e8",
    background: "white",
  },
  tabsIndicator: {
    backgroundColor: "#000000",
  },
  tabRoot: {
    textTransform: "initial",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing.unit * 4,
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "#000000",
      opacity: 1,
    },
    "&$tabSelected": {
      color: "#000000",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "#000000",
    },
  },
  tabSelected: {},
  typography: {
    padding: theme.spacing.unit * 3,
  },
});
