import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// component
import WrapperReportsPage from '../component/WrapperReportsPage';

// helpers
import { getJsonFromUrl } from 'helpers';

// actions
import * as Actions from 'actions';
import { getReports } from 'api';


// const mapDispatchToProps = dispatch => ({
//   getReports: (...args) => dispatch(Actions.getReports(...args)),
// });
class ReportsPage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const {location} = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: false,
    };

  }

  // async generateReport(filters) {
  //   const { getReports } = this.props;
  //   const promises = [getReports(filters)];
  //   await Promise.all(promises);

  //   this.setState({ loading: false });
  // }


  render() {

    return (
      <WrapperReportsPage
        generateReport={getReports}
      />
    );
  }
}
export default connect(null, null)(ReportsPage);
