// material-ui
import { withStyles } from "@material-ui/core/styles";
// custom
import Table from "components/Table";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class PriorityLevelsList extends Component {
  static propTypes = {
    classes: PropTypes.object,
    priorityLevels: PropTypes.array,
    onSelect: PropTypes.func,
  };

  onSelect(selectedPriorityLevel) {
    const { onSelect } = this.props;
    this.setState({ selectedPriorityLevel });
    if (onSelect) {
      onSelect(selectedPriorityLevel);
    }
  }

  render() {
    const { priorityLevels } = this.props;

    return (
      <Table
        data={priorityLevels}
        meta={[
          {
            path: "name",
            title: "Name",
            numeric: false,
          },
        ]}
        title={"PriorityLevels"}
        onRowSelect={this.onSelect.bind(this)}
      />
    );
  }
}

export default withStyles(styles)(PriorityLevelsList);
