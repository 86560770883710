import React from 'react';
import PropTypes from 'prop-types';

class AuthChecker extends React.Component {
  static propTypes = {
    user: PropTypes.object,
    init: PropTypes.func,
    routes: PropTypes.array,
    history: PropTypes.object,
    location: PropTypes.object,
    getCurrentUser: PropTypes.func,
    children: PropTypes.node,
    onReady: PropTypes.func,
    signinRoute: PropTypes.string,
    redirectAccordingToRole: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    this.routine();
  }

  componentWillReceiveProps(nextProps) {
    const { location, user } = this.props;

    // route changed
    if (nextProps.location.pathname !== location.pathname) {
      setTimeout(() => this.routine(), 100);
    }

    // user arrived
    if (nextProps.user.services && (user.services === undefined)) {
      setTimeout(() => this.routine(), 100);
    }
  }

  userDidRequestSigninRoute() {
    console.log('userDidRequestSigninRoute');
    const { onReady } = this.props;
    onReady();
  }

  async unloggedUserDidRequestRoot() {
    console.log('unloggedUserDidRequestRoot');
    const {
      getCurrentUser,
      init,
    } = this.props;
    await Promise.all([
      await getCurrentUser(),
      await init(),
    ]);
  }

  loggedUserDidRequestRoot() {
    console.log('loggedUserDidRequestRoot');
    const { history, user, redirectAccordingToRole } = this.props;
    const redirect = redirectAccordingToRole(user);

    history.push(redirect);
  }

  loggedUserDidRequestRouteWithAuth() {
    console.log('loggedUserDidRequestRouteWithAuth');
    const { onReady } = this.props;
    onReady();
  }

  async unloggedUserDidRequestRouteWithAuth() {
    console.log('unloggedUserDidRequestRouteWithAuth');
    const { onReady } = this.props;
    // await getCurrentUser();
    onReady();
  }

  loggedUserDidRequestRouteWithoutAuth() {
    console.log('loggedUserDidRequestRouteWithoutAuth');
    const { history, user, redirectAccordingToRole } = this.props;
    history.push(redirectAccordingToRole(user));
  }

  async unloggedUserDidRequestRouteWithoutAuth() {
    console.log('unloggedUserDidRequestRouteWithoutAuth');
    const {
      getCurrentUser,
      location,
      init,
    } = this.props;

    const { localStorage } = window;
    localStorage.redirectUrl = location.pathname;
    await Promise.all([
      await getCurrentUser(),
      await init(),
    ]);
  }

  routine() {
    console.log('routine');
    const {
      location,
      routes,
      user,
      signinRoute,
    } = this.props;
    const route = routes.find(r => r.path === location.pathname);
    const path = route ? route.path : '/';

    const userIsLogged = user.uuid !== undefined;

    if (path === signinRoute) {
      return this.userDidRequestSigninRoute();
    }

    if ((path === '/') && userIsLogged) {
      return this.loggedUserDidRequestRoot();
    }

    if ((path === '/') && !userIsLogged) {
      return this.unloggedUserDidRequestRoot();
    }

    if (route.onEnter()) {
      if (userIsLogged) {
        return this.loggedUserDidRequestRouteWithAuth();
      }

      return this.unloggedUserDidRequestRouteWithAuth();
    }

    if (!route.onEnter()) {
      if (userIsLogged) {
        return this.loggedUserDidRequestRouteWithoutAuth();
      }

      return this.unloggedUserDidRequestRouteWithoutAuth();
    }

    return undefined;
  }

  render() {
    const { children } = this.props;
    return (
      children
    );
  }
}

export default AuthChecker;
