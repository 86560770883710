export default theme => ({
  container: {
    padding: 5,
    border: 'solid 1px rgba(155,155,155,0.3)',
    background: 'rgba(155,155,155,0.1)',
    borderRadius: 4,
    position: 'relative',
  },
  pending: {
    padding: 5,
    border: 'solid 1px rgba(233,30,99, 0.3)',
    background: 'rgba(233,30,99, 0.1)',
    borderRadius: 4,
    position: 'relative',
  },
});
