import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Portal from "@material-ui/core/Portal";
// material-ui
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React, { Component } from "react";

// styles
const styles = () => ({
  grid: {
    padding: 5,
  },
});

class EnvMessage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    envMessage: PropTypes.object,
  };

  static contextTypes = {
    MessageCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { envMessage } = this.props;
    const { MessageCenter } = this.context;

    if (envMessage) {
      MessageCenter.open(envMessage.anchor || "top");
    }
    this.state = {
      open: true,
    };
  }

  render() {
    const { MessageCenter } = this.context;
    const { envMessage } = this.props;
    const { open } = this.state;

    if (envMessage === undefined || !open) return <div />;

    return (
      <Portal container={MessageCenter.getPortalContainer(envMessage.anchor)}>
        <Grid
          container
          justify={envMessage.justify || "space-between"}
          alignItems="center"
          style={{
            padding: 5,
            background: envMessage.background || "#2196f3",
            color: envMessage.color || "white",
            height: "100%",
          }}
        >
          <Grid item>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <Typography display="block" color="inherit" variant="caption">
                  {envMessage.content}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {envMessage.dismissButton ? (
            <Grid item>
              <Button
                size="small"
                color="inherit"
                variant="outlined"
                onClick={() => {
                  MessageCenter.close(envMessage.anchor);
                  this.setState({ open: false });
                }}
              >
                Ok
              </Button>
            </Grid>
          ) : (
            <div />
          )}
        </Grid>
      </Portal>
    );
  }
}

export default withStyles(styles)(EnvMessage);
