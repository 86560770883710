// *https://www.registers.service.gov.uk/registers/country/use-the-api*
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Icon,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Popper,
  Tooltip,
  Typography,
  Chip,
  Badge,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { Autocomplete } from "@material-ui/lab";
import AddAttachment from "hooks/AddAttachment";
import React from "react";
import NumberFormat from "react-number-format";

const PopperMy = function (props) {
  return <Popper {...props} style={{ width: 400 }} placement="bottom-start" />;
};

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

export default function LineItem({
  dragHandle,
  lineItem,
  lite,
  glAccounts,
  itemClasses,
  onDelete,
  index,
  update,
  updateInternalMemo,
  disabled,
  company,
  companyID,
  uploadLineItemFile,
  setSelectedFile,
}) {
  const [glAccount, setGlAccount] = React.useState(
    glAccounts.find((g) => g.id === lineItem.glAccountID)
  );
  const [glAccountInput, setGlAccountInput] = React.useState("");

  const [itemClass, setItemClass] = React.useState(
    itemClasses.find((g) => g.id === lineItem.classID)
  );
  const [itemClassInput, setItemClassInput] = React.useState("");

  const [amount, setAmount] = React.useState(lineItem.amount);
  const [memo, setMemo] = React.useState(lineItem.memo);
  const [files, setFiles] = React.useState(lineItem.files || []);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [internalMemo, setInternalMemo] = React.useState(lineItem.internalMemo);
  const [internalMemoOpen, setInternalMemoOpen] = React.useState(false);

  React.useEffect(() => {
    if (files && files.length && lineItem.files && files.length !== lineItem.files.length) {
      onBlur();
    }
  }, [files]);

  const onBlur = () => {
    update(
      {
        glAccountID: glAccount && glAccount.id,
        classID: itemClass && itemClass.id,
        internalMemo,
        amount: Number(amount),
        memo: memo,
        id: lineItem.id,
        tmpID: lineItem.tmpID,
        files,
      },
      index
    );
  };

  const saveInternalMemo = () => {
    updateInternalMemo(
      {
        internalMemo,
        id: lineItem.id,
      },
      index
    );
  };

  const userDidDrop = async (f) => {
    const _files = [...files, ...f];
    setFiles(_files);
    setSelectedFile(f[0]);
    if (lineItem?.id) {
      await new Promise(async (r) => {
        const funcs = [];
        for (const k in f) {
          if (f.hasOwnProperty(k)) {
            const file = f[k];
            funcs.push(uploadLineItemFile(lineItem.id, file));
          }
        }
        await Promise.all(funcs);
      });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        alignItems: "stretch",
        position: "relative",
      }}
    >
      {dragHandle ? (
        <div style={{ color: "#9e9e9e", display: "flex", marginTop: 12 }}>
          <DragIndicatorIcon style={{ fontSize: 14 }} />
        </div>
      ) : (
        []
      )}
      {!lite ? (
        <Tooltip title={internalMemo || "Add an internal memo"}>
          <div
            onClick={() => setInternalMemoOpen(true)}
            style={{
              margin: "8px 4px",
              cursor: "pointer",
              color: internalMemo ? "#ffc107" : undefined,
            }}
          >
            <i className="fad fa-sticky-note"></i>
          </div>
        </Tooltip>
      ) : (
        []
      )}
      {!lite ? (
        <>
          {files?.length ? (
            <div
              style={{
                margin: "5px 4px",
              }}
            >
              <Typography
                color="textSecondary"
                variant="caption"
                display="block"
                color="inherit"
              >
                  <AddAttachment userDidDrop={userDidDrop} noLabel />
                <Tooltip title="View Attachments">
                  <Chip
                    onClick={(e) => {
                      setAnchorEl(e.currentTarget);
                    }}
                    style={{ marginLeft: 4, paddingBottom: -5, fontSize: 12, cursor: "pointer" }}
                  label={files.length}
                  size="small"></Chip>

                </Tooltip>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  onClose={() => setAnchorEl(null)}
                >
                  {files.map((f) => (
                    <MenuItem
                      key={f.name}
                      style={{ width: 300 }}
                      onClick={() => {
                        setSelectedFile(f);
                        setAnchorEl(null);
                      }}
                    >
                      <ListItemText primary={f.name} />
                      {/* <ListItemSecondaryAction>
                    <IconButton size="small">
                      <Visibility style={{ fontSize: 18 }} />
                    </IconButton>
                    <IconButton size="small">
                      <HighlightOffIcon style={{ fontSize: 18 }} />
                    </IconButton>
                  </ListItemSecondaryAction> */}
                    </MenuItem>
                  ))}
                </Menu>
              </Typography>
            </div>
          ) : (
            <div
              style={{
                margin: "5px 10px",
                paddingRight: 16,
                color: files?.length ? "#ffc107" : undefined,
              }}
            >
              <AddAttachment userDidDrop={userDidDrop} noLabel />
            </div>
          )}
        </>
      ) : (
        []
      )}
      <div style={{ flex: 1, margin: 4 }}>
        <Tooltip title={glAccountInput}>
          <div>
            <Autocomplete
              value={glAccount}
              onChange={(e, v) => setGlAccount(v)}
              inputValue={glAccountInput}
              onInputChange={(event, newInputValue) => {
                setGlAccountInput(newInputValue);
              }}
              disabled={disabled}
              onBlur={onBlur}
              id="controllable-states-demo"
              options={glAccounts.filter((gl) => gl.companyID === companyID)}
              disableClearable
              size="small"
              getOptionLabel={(option) => `${option.accountNumber !== "" ? option.accountNumber+" - " : ""} ${option.name} (${option.type})`}
              renderInput={(params) => {
                params.InputProps.style = {
                  fontSize: 12,
                  background: "#E8E8E8",
                };
                return <TextField {...params} multiline />;
              }}
              PopperComponent={PopperMy}
            />
            {glAccount && glAccount.companyID !== companyID ? (
              <Typography
                variant="caption"
                style={{ color: "#f44336" }}
              >{`Not in ${company && company.name}`}</Typography>
            ) : (
              []
            )}
          </div>
        </Tooltip>
      </div>
      <div style={{ flex: 1, margin: 4 }}>
        <Tooltip title={itemClassInput}>
          <div>
            <Autocomplete
              value={itemClass}
              size="small"
              onChange={(e, v) => setItemClass(v)}
              disableClearable
              inputValue={itemClassInput}
              onInputChange={(event, newInputValue) => {
                setItemClassInput(newInputValue);
              }}
              disabled={disabled}
              id="controllable-states-demo"
              options={itemClasses.filter((ic) => ic.companyID === companyID)}
              onBlur={onBlur}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => {
                params.InputProps.style = {
                  fontSize: 12,
                  background: "#E8E8E8",
                };
                return <TextField {...params} multiline />;
              }}
              PopperComponent={PopperMy}
            />
            {itemClass && itemClass.companyID !== companyID ? (
              <Typography
                variant="caption"
                style={{ color: "#f44336" }}
              >{`Not in ${company && company.name}`}</Typography>
            ) : (
              []
            )}
          </div>
        </Tooltip>
      </div>
      {!lite ? (
        <TextField
          fullWidth
          style={{
            flex: 1,
            margin: 4,
          }}
          disabled={disabled}
          value={memo}
          onBlur={onBlur}
          multiline
          rowsMax={4}
          rows={1}
          InputProps={{
            style: { fontSize: 12, background: "#E8E8E8" },
          }}
          onChange={(e) => setMemo(e.target.value)}
        />
      ) : (
        []
      )}
      {!lite ? (
        <TextField
          fullWidth
          style={{
            flex: 1,
            margin: 4,
          }}
          disabled={disabled}
          onBlur={onBlur}
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          InputProps={{
            inputComponent: NumberFormatCustom,
            style: { fontSize: 12, background: "#E8E8E8" },
          }}
        />
      ) : (
        []
      )}
      {!disabled ? (
        <IconButton
          tabIndex={-1}
          style={{ padding: 4 }}
          onClick={() => onDelete(lineItem, index)}
        >
          <HighlightOffIcon style={{ fontSize: 18 }} />
        </IconButton>
      ) : (
        []
      )}
      <Dialog
        open={internalMemoOpen}
        onClose={() => setInternalMemoOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <TextField
            label="Internal Memo"
            variant="outlined"
            value={internalMemo}
            multiline
            rowsMax={5}
            fullWidth
            size="small"
            onChange={(e) => setInternalMemo(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setInternalMemoOpen(false);
              saveInternalMemo();
            }}
            color="primary"
            variant="contained"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
