import { REACT_APP_API_PREFIX } from "config/env";
import { DELETE, GET, POST, PUT } from "constants/methods";

const service = "ap-dashboard";
const companies = `${REACT_APP_API_PREFIX}/${service}/companies`;

export function getCompanies() {
  const url = `${companies}`;
  return {
    method: GET,
    url,
  };
}

export function getCompanyById(id) {
  const url = `${companies}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateCompany(id, body) {
  const url = `${companies}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteCompany(id) {
  const url = `${companies}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createCompany(body) {
  const url = `${companies}`;
  return {
    method: POST,
    url,
    body,
  };
}

export function companiesGlLockDates(id, body) {
  const url = `${companies}/${id}/glLockDates`;
  return {
    method: POST,
    url,
    body,
  };
}
