import Avatar from "@material-ui/core/Avatar";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
// material-ui
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Account from "@material-ui/icons/AccountBalanceTwoTone";
import Close from "@material-ui/icons/Close";
// custom
import AutoComplete from "components/AutoComplete";
import AddAttachment from "hooks/AddAttachment";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class LineItem extends Component {
  static propTypes = {
    lineItem: PropTypes.object,
    updatePayableLineItem: PropTypes.func,
    deletePayableLineItem: PropTypes.func,
    refresh: PropTypes.func,
    classes: PropTypes.object,
    itemClasses: PropTypes.array,
    payableID: PropTypes.number,
    glAccounts: PropTypes.array,
    locked: PropTypes.bool,
  };

  constructor(...args) {
    super(...args);
    const { lineItem } = this.props;
    this.state = {
      ...lineItem,
      loading: false,
      newIdentificationLoading: false,
    };
  }

  handleChange = (name) => (event) => {
    //eslint-disable-line
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  async save(name, numeric) {
    const { updatePayableLineItem, refresh, lineItem, payableID } = this.props;

    if (lineItem[name] === this.state[name]) return;

    this.setState({
      loading: true,
    });

    await updatePayableLineItem(payableID, lineItem.id, {
      [name]: numeric ? Number(this.state[name]) : this.state[name],
    });

    this.setState({
      loading: false,
    });

    refresh();
  }

  render() {
    const {
      classes,
      glAccounts,
      itemClasses,
      lineItem,
      deletePayableLineItem,
      refresh,
      payableID,
      locked,
    } = this.props;

    const {
      glAccountID,
      classID,
      amount,
      loading,
      glAccountName,
      memo,
    } = this.state;

    return (
      <div style={{ position: "relative" }}>
        <IconButton
          style={{ padding: 3, position: "absolute", top: 0, right: 0 }}
          onClick={() =>
            deletePayableLineItem(payableID, lineItem.id).then(refresh)
          }
        >
          <Close style={{ fontSize: 18 }} />
        </IconButton>
        <Grid container className={classes.container} spacing={1}>
          <Grid container spacing={2}>
            <Grid item>
              <AddAttachment />
            </Grid>
            <Grid item xs={12}>
              <TextField
                disabled={loading || locked}
                multiline
                label="amount"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                onBlur={() => this.save("amount", true)}
                value={amount}
                onChange={this.handleChange("amount")}
                onKeyPress={(e) => {
                  if (e.key === "Enter") this.save("amount", true);
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel htmlFor="group-simple">Class</InputLabel>
              <Select
                disabled={loading || locked}
                inputProps={{
                  name: "classID",
                  id: "classID",
                }}
                onChange={(e) => {
                  this.state.classID = e.target.value; // eslint-disable-line
                  this.save("classID");
                }}
                value={classID}
              >
                {itemClasses &&
                  itemClasses.map((g) => (
                    <MenuItem key={`itemClasses_${g.id}`} value={g.id}>
                      {g.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            {glAccountID && glAccountName ? (
              <div>
                <Typography
                  display="block"
                  variant="caption"
                  color="textSecondary"
                >
                  Vendor
                </Typography>
                <Grid
                  container
                  spacing={1}
                  alignItems="center"
                  className={classes.item}
                >
                  <Grid item style={{ width: 50 }}>
                    <Avatar>
                      <Account />
                    </Avatar>
                  </Grid>
                  <Grid item style={{ width: "calc(100% - 50px)" }}>
                    <Typography display="block" variant="body2">
                      {glAccountName}
                    </Typography>
                    {!locked ? (
                      <Typography
                        display="block"
                        color="primary"
                        variant="caption"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          this.setState({ glAccountID: undefined })
                        }
                      >
                        Clear
                      </Typography>
                    ) : (
                      []
                    )}
                  </Grid>
                </Grid>
              </div>
            ) : (
              <Grid
                container
                spacing={1}
                alignItems="center"
                className={classes.item}
              >
                <Grid item xs={12}>
                  <AutoComplete
                    placeHolder={"Select the GL Account"}
                    onSelect={(m) => {
                      this.state.glAccountID = m.value.id;
                      this.save("glAccountID");
                    }}
                    suggestions={
                      glAccounts &&
                      glAccounts.map((g) => ({
                        value: g,
                        label: g.name,
                      }))
                    }
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              background: "rgba(255,193,7, 0.2)",
              border: "solid 1px rgba(255,193,7, 0.3)",
              marginTop: 10,
            }}
          >
            <TextField
              id="memo"
              fullWidth
              multiline
              label="Memo"
              className={classes.textField}
              value={memo}
              onChange={this.handleChange("memo")}
              onBlur={() => this.save("memo")}
              onKeyPress={(e) => {
                if (e.key === "Enter") this.save("memo");
              }}
              disabled={loading || locked}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(LineItem);
