import React from 'react';

export default class WrapperDefaultPage extends React.Component {
  static propTypes = {

  };

  render() {
    return (
      <div>
        404 Not Found
      </div>
    );
  }
}
