import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
// material-ui
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Close from "@material-ui/icons/Close";
import Download from "@material-ui/icons/CloudDownload";
import Left from "@material-ui/icons/KeyboardArrowLeft";
import Right from "@material-ui/icons/KeyboardArrowRight";
import ZoomIn from "@material-ui/icons/ZoomIn";
import ZoomOut from "@material-ui/icons/ZoomOut";
// custom
import PDF from "components/PDF";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class DocumentPreview extends Component {
  static propTypes = {
    preview: PropTypes.object,
    classes: PropTypes.object,
    clear: PropTypes.func,
    children: PropTypes.node,
  };

  static contextTypes = {
    KeyboardEventCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { KeyboardEventCenter } = this.context;
    const { clear } = this.props;
    this.state = {
      base: undefined,
      files: [],
      index: 0,
      scale: 1.5,
    };
    KeyboardEventCenter.listen("esc", clear);
    KeyboardEventCenter.listen("left", () => {
      if (this.state.index > 0) this.setState({ index: this.state.index - 1 });
    });
    KeyboardEventCenter.listen("right", () => {
      if (this.state.index < this.state.files.length - 1 > 0)
        this.setState({ index: this.state.index + 1 });
    });
  }

  componentWillMount() {
    const { KeyboardEventCenter } = this.context;

    KeyboardEventCenter.disregard();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.preview && this.props.preview === undefined) {
      this.setState({
        ...nextProps.preview,
      });
    }
  }

  render() {
    const { preview, clear, classes, children } = this.props;
    const { base, files, index, scale } = this.state;

    return (
      <Dialog
        fullScreen
        open={preview}
        onClose={clear}
        classes={{
          paper: classes.background,
        }}
      >
        <Grid
          container
          style={{
            flexGrow: 1,
          }}
          alignItems="stretch"
          direction="column"
        >
          <Grid
            item
            style={{
              flex: 3,
              display: "flex",
              position: "relative",
              color: "white",
            }}
          >
            {base ? (
              <Grid
                container
                style={{
                  flexGrow: 1,
                  color: "white",
                }}
                direction="column"
              >
                <Grid item style={{ textAlign: "right" }}>
                  <Grid container justify="space-between">
                    <Grid item>
                      <IconButton
                        color="inherit"
                        onClick={() =>
                          this.setState((prevState) => ({
                            scale: prevState.scale - 0.5,
                          }))
                        }
                      >
                        <ZoomOut />
                      </IconButton>
                      <IconButton
                        color="inherit"
                        onClick={() =>
                          this.setState((prevState) => ({
                            scale: prevState.scale + 0.5,
                          }))
                        }
                      >
                        <ZoomIn />
                      </IconButton>
                    </Grid>
                    <Grid item style={{ textAlign: "center", padding: 20 }}>
                      <Typography display="block" color="inherit" variant="h6">
                        {`${files[index].name}`}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <IconButton
                        color="inherit"
                        onClick={() =>
                          window.open(`${base}${files[index].id}`, "_blank")
                        }
                      >
                        <Download />
                      </IconButton>
                      <IconButton color="inherit" onClick={clear}>
                        <Close />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item style={{ flexGrow: 1 }}>
                  <Grid
                    container
                    justify="space-between"
                    style={{ height: "100%" }}
                    alignItems="center"
                  >
                    <Grid item style={{ width: 74 }}>
                      {index > 0 ? (
                        <IconButton color="inherit">
                          <Left
                            style={{ fontSize: 50 }}
                            onClick={() => this.setState({ index: index - 1 })}
                          />
                        </IconButton>
                      ) : (
                        []
                      )}
                    </Grid>
                    <Grid
                      item
                      style={{
                        flexGrow: 1,
                        width: "calc(100% - 148px)",
                        height: "100%",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          position: "relative",
                        }}
                      >
                        {`${files[index].name}`.split(".")[1] === "pdf" ? (
                          <PDF
                            src={`${base}${files[index].id}`}
                            key={`${base}${files[index].id}`}
                            scale={scale}
                          />
                        ) : (
                          <div
                            style={{
                              width: "100%",
                              textAlign: "center",
                              backgroundImage: `url(${base}${files[index].id})`,
                              backgroundSize: "fit",
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "center",
                              position: "relative",
                              height: "calc(100% - 20px)",
                            }}
                          />
                        )}
                      </div>
                    </Grid>
                    <Grid item style={{ width: 74 }}>
                      {index < files.length - 1 ? (
                        <IconButton color="inherit">
                          <Right
                            style={{ fontSize: 50 }}
                            onClick={() => this.setState({ index: index + 1 })}
                          />
                        </IconButton>
                      ) : (
                        []
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              []
            )}
            <div
              style={{
                position: "absolute",
                bottom: 0,
                height: 20,
                background:
                  "linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.32) 100%)",
                width: "100%",
                zIndex: 99999,
              }}
            />
          </Grid>
          <Grid item style={{ flex: 1, overflow: "scroll" }}>
            {children}
          </Grid>
        </Grid>
      </Dialog>
    );
  }
}

export default withStyles(styles)(DocumentPreview);
