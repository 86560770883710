// actions
import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// component
import WrapperVendorsPage from "../component/WrapperVendorsPage";

const mapStateToProps = (state) => ({
  vendors: state.vendors,
  itemClasses: state.itemClasses,
  glAccounts: state.glAccounts,
  companies: state.companies,
  users: state.users,
});

const mapDispatchToProps = (dispatch) => ({
  getVendors: (...args) => dispatch(Actions.getVendors(...args)),
  getVendorById: (...args) => dispatch(Actions.getVendorById(...args)),
  createVendor: (...args) => dispatch(Actions.createVendor(...args)),
  deleteVendor: (...args) => dispatch(Actions.deleteVendor(...args)),
  updateVendor: (...args) => dispatch(Actions.updateVendor(...args)),

  getVendorLineItems: (...args) =>
    dispatch(Actions.getVendorLineItems(...args)),
  createVendorLineItem: (...args) =>
    dispatch(Actions.createVendorLineItem(...args)),
  deleteVendorLineItem: (...args) =>
    dispatch(Actions.deleteVendorLineItem(...args)),
  updateVendorLineItem: (...args) =>
    dispatch(Actions.updateVendorLineItem(...args)),

  getItemClasses: (...args) => dispatch(Actions.getItemClasses(...args)),
  getGlAccounts: (...args) => dispatch(Actions.getGlAccounts(...args)),
  getCompanies: (...args) => dispatch(Actions.getCompanies(...args)),
  getUsers: (...args) => dispatch(Actions.getUsers(...args)),
});
class VendorsPage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    //vendors
    vendors: PropTypes.array,
    getVendors: PropTypes.func,
    getVendorById: PropTypes.func,
    createVendor: PropTypes.func,
    deleteVendor: PropTypes.func,
    updateVendor: PropTypes.func,

    //vendorItems
    getVendorLineItems: PropTypes.func,
    createVendorLineItem: PropTypes.func,
    deleteVendorLineItem: PropTypes.func,
    updateVendorLineItem: PropTypes.func,

    glAccounts: PropTypes.array,
    getGlAccounts: PropTypes.func,
    getUsers: PropTypes.func,

    itemClasses: PropTypes.array,
    getItemClasses: PropTypes.func,

    companies: PropTypes.array,
    getCompanies: PropTypes.func,
    users: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const {
      location,
      vendors,
      getGlAccounts,
      getItemClasses,
      getCompanies,
      getUsers,
    } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: vendors.length === 0,
    };

    getGlAccounts();
    getItemClasses();
    getCompanies();
    getUsers([
      {
        name: "roles.serviceNameID",
        comparison: "eq",
        value: 17,
      },
    ]);
  }

  componentDidMount() {
    this.refresh();
  }

  async refresh() {
    const { getVendors } = this.props;
    const { urlParams } = this.state;
    await Promise.all([getVendors(urlParams.filters)]);

    this.setState({ loading: false });
  }

  render() {
    const {
      vendors,
      getVendorById,
      createVendor,
      deleteVendor,
      updateVendor,
      getVendors,
      getVendorLineItems,
      createVendorLineItem,
      deleteVendorLineItem,
      updateVendorLineItem,
      history,
      companies,
      glAccounts,
      itemClasses,
      users,
    } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperVendorsPage
        refresh={this.refresh.bind(this)}
        loading={loading}
        vendors={vendors}
        history={history}
        getVendors={getVendors}
        getVendorById={getVendorById}
        createVendor={createVendor}
        deleteVendor={deleteVendor}
        updateVendor={updateVendor}
        getVendorLineItems={getVendorLineItems}
        createVendorLineItem={createVendorLineItem}
        deleteVendorLineItem={deleteVendorLineItem}
        updateVendorLineItem={updateVendorLineItem}
        urlParams={urlParams}
        users={users}
        companies={companies}
        glAccounts={glAccounts}
        itemClasses={itemClasses}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(VendorsPage);
