import {
  STORE_RESET_PASSWORD_TOKEN,
  SET_DARK_MODE,
} from 'constants/app';

function getCookie(cookiename) {
  // Get name followed by anything except a semicolon
  const cookiestring = RegExp(`${cookiename}[^;]+`).exec(document.cookie);

  // Return everything after the equal sign, or an empty string if the cookie name not found
  const ret = decodeURIComponent(
    cookiestring
      ? cookiestring.toString().replace(/^[^=]+./, '')
      : '',
  );
  return ret;
}

export default (state = { darkMode: getCookie('darkMode') === 'true' }, action) => {
  switch (action.type) {
    case STORE_RESET_PASSWORD_TOKEN:
      return { ...state, resetPasswordToken: action.token };
    case SET_DARK_MODE:
      return { ...state, darkMode: action.token };
    default:
      return state;
  }
};
