import {
  GET_EXPORTS,
} from 'constants/exports';

export default (state = [], action) => {
    switch (action.type) {
    case `${GET_EXPORTS}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};
