// actions
import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// component
import WrapperPayablePage from "../component/WrapperPayablePage";

const mapStateToProps = (state) => ({
  users: state.users,
  user: state.user,
  vendors: state.vendors,
  payables: state.payables,
  myPayables: state.myPayables,
  itemClasses: state.itemClasses,
  glAccounts: state.glAccounts,
  companies: state.companies,
  priorityLevels: state.priorityLevels,
});

const mapDispatchToProps = (dispatch) => ({
  getPayables: (...args) => dispatch(Actions.getPayables(...args)),
  getUsers: (...args) => dispatch(Actions.getUsers(...args)),
  getPayableById: (...args) => dispatch(Actions.getPayableById(...args)),
  createPayable: (...args) => dispatch(Actions.createPayable(...args)),
  deletePayable: (...args) => dispatch(Actions.deletePayable(...args)),
  updatePayable: (...args) => dispatch(Actions.updatePayable(...args)),
  uploadPayableFile: (...args) => dispatch(Actions.uploadPayableFile(...args)),
  uploadLineItemFile: (...args) =>
    dispatch(Actions.uploadLineItemFile(...args)),
  lockPayable: (...args) => dispatch(Actions.lockPayable(...args)),
  updateGLAccount: (...args) => dispatch(Actions.updateGLAccount(...args)),
  getVendors: (...args) => dispatch(Actions.getVendors(...args)),
  createPayableLineItem: (...args) =>
    dispatch(Actions.createPayableLineItem(...args)),
  updatePayableLineItem: (...args) =>
    dispatch(Actions.updatePayableLineItem(...args)),
  updateLineItemInternalMemo: (...args) =>
    dispatch(Actions.updateLineItemInternalMemo(...args)),
  deletePayableLineItem: (...args) =>
    dispatch(Actions.deletePayableLineItem(...args)),
  getItemClasses: (...args) => dispatch(Actions.getItemClasses(...args)),
  getGlAccounts: (...args) => dispatch(Actions.getGlAccounts(...args)),
  getCompanies: (...args) => dispatch(Actions.getCompanies(...args)),
  getMyPayables: (...args) => dispatch(Actions.getMyPayables(...args)),
  qbExport: (...args) => dispatch(Actions.qbExport(...args)),
  createExport: (...args) => dispatch(Actions.createExport(...args)),
  getPriorityLevels: (...args) => dispatch(Actions.getPriorityLevels(...args)),
});
class PayablePage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    getUsers: PropTypes.func,
    getPayables: PropTypes.func,
    getPayableById: PropTypes.func,
    createPayable: PropTypes.func,
    deletePayable: PropTypes.func,
    updatePayable: PropTypes.func,
    uploadPayableFile: PropTypes.func,
    updateGLAccount: PropTypes.func,
    lockPayable: PropTypes.func,
    getVendors: PropTypes.func,
    vendors: PropTypes.array,
    users: PropTypes.array,
    payables: PropTypes.array,
    user: PropTypes.object,
    createPayableLineItem: PropTypes.func,
    updatePayableLineItem: PropTypes.func,
    updateLineItemInternalMemo: PropTypes.func,
    deletePayableLineItem: PropTypes.func,
    uploadLineItemFile: PropTypes.func,
    qbExport: PropTypes.func,

    itemClasses: PropTypes.array,
    getItemClasses: PropTypes.func,

    glAccounts: PropTypes.array,
    getGlAccounts: PropTypes.func,

    companies: PropTypes.array,
    getCompanies: PropTypes.func,

    getMyPayables: PropTypes.func,
    myPayables: PropTypes.array,

    createExport: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const {
      location,
      getUsers,
      getVendors,
      getItemClasses,
      getGlAccounts,
      getPriorityLevels,
      getCompanies,
    } = this.props;
    const urlParams = getJsonFromUrl(location);
    this.state = {
      urlParams,
      payableID: urlParams.id ? Number(urlParams.id) : undefined,
      index: urlParams.index ? Number(urlParams.index) : 0,
      filters: urlParams.filters ? JSON.parse(urlParams.filters) : [],
      loading: true,
      payablesAssignedToMe: [],
      payablesCreatedByMe: [],
    };
    Promise.all([
      getUsers([
        {
          name: "roles.serviceNameID",
          comparison: "eq",
          value: 17,
        },
      ]),
      getVendors(),
      getGlAccounts(),
      getItemClasses(),
      getPriorityLevels(),
      getCompanies(),
    ]).then(this.refresh.bind(this));
  }

  async refresh() {
    const { getPayables, getMyPayables, getPayableById } = this.props;
    const { filters, payableID } = this.state;
    const urlParams = getJsonFromUrl(window.location);
    const promises = [getMyPayables()];
    const role = this.getRole();
    if (role === "superadmin" || "accounting_manager") {
      promises.push(
        getPayables(urlParams.filters ? JSON.parse(urlParams.filters) : [])
      );
    }

    if (payableID) promises.push(getPayableById(payableID));

    const resps = await Promise.all(promises);

    const refreshKey = new Date().getTime().toString();

    this.setState({
      loading: false,
      dataLoading: false,
      contentLoading: false,
      selectedPayable: payableID ? resps[resps.length - 1].payload : undefined,
      refreshKey,
    });
  }

  getRole() {
    const { user } = this.props;
    let role = "user";
    const apDashboardService =
      user && user.services && user.services["ap-dashboard"];
    if (
      apDashboardService &&
      apDashboardService.find((r) => r === "accounting")
    )
      role = "accounting";
    if (
      apDashboardService &&
      apDashboardService.find((r) => r === "superadmin")
    )
      role = "superadmin";
    if (
      apDashboardService &&
      apDashboardService.find((r) => r === "accounting_manager")
    )
      role = "manager";

    return role;
  }

  render() {
    const {
      history,
      location,
      getPayableById,
      createPayable,
      deletePayable,
      updatePayable,
      uploadPayableFile,
      updateGLAccount,
      lockPayable,
      users,
      vendors,
      getPayables,
      user,
      payables,
      createPayableLineItem,
      updatePayableLineItem,
      updateLineItemInternalMemo,
      deletePayableLineItem,
      itemClasses,
      glAccounts,
      companies,
      myPayables,
      qbExport,
      createExport,
      getMyPayables,
      priorityLevels,
      uploadLineItemFile,
    } = this.props;

    const {
      urlParams,
      loading,
      refreshKey,
      payablesAssignedToMe,
      payablesCreatedByMe,
      selectedPayable,
      payableID,
      filters,
      contentLoading,
    } = this.state;
    let role = this.getRole();

    return (
      <WrapperPayablePage
        history={history}
        location={location}
        getPayableById={getPayableById}
        getMyPayables={getMyPayables}
        refresh={this.refresh.bind(this)}
        loading={loading}
        role={role}
        filters={filters}
        payables={payables}
        getPayables={getPayables}
        selectedPayable={selectedPayable}
        priorityLevels={priorityLevels}
        payablesAssignedToMe={payablesAssignedToMe}
        payablesCreatedByMe={payablesCreatedByMe}
        getPayableById={getPayableById}
        createPayable={createPayable}
        deletePayable={deletePayable}
        updatePayable={updatePayable}
        uploadPayableFile={uploadPayableFile}
        uploadLineItemFile={uploadLineItemFile}
        users={users}
        myPayables={myPayables}
        companies={companies}
        vendors={vendors}
        user={user}
        payableID={payableID}
        urlParams={urlParams}
        refreshKey={refreshKey}
        contentLoading={contentLoading}
        updateGLAccount={updateGLAccount}
        lockPayable={lockPayable}
        createExport={createExport}
        createPayableLineItem={createPayableLineItem}
        updatePayableLineItem={updatePayableLineItem}
        updateLineItemInternalMemo={updateLineItemInternalMemo}
        deletePayableLineItem={deletePayableLineItem}
        qbExport={qbExport}
        itemClasses={itemClasses}
        glAccounts={glAccounts}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PayablePage);
