// import PropTypes from 'prop-types';
// material-ui
import { Chip, Tooltip } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

export default function AddAttachment({ userDidDrop, noLabel }) {
  const onDrop = useCallback((acceptedFiles) => {
    for (const key in acceptedFiles) {
      if (Object.hasOwnProperty.call(acceptedFiles, key)) {
        const file = acceptedFiles[key];
        file.preview = URL.createObjectURL(file);
      }
    }
    userDidDrop(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Tooltip title="Add Attachment">
      <div
        {...getRootProps()}
        style={{ cursor: "pointer", display: "inline-block" }}
      >
        <input {...getInputProps()} />
        {noLabel !== true ? (
          <Chip
            style={{ cursor: "pointer", color: "white", background: "#03a9f4" }}
            label={
              <span>
                <i className="far fa-paperclip"></i>
                {!noLabel ? "Add attachment" : ""}
              </span>
            }
          />
        ) : (
          <Typography
            color="textSecondary"
            variant="caption"
            display="block"
            color="inherit"
          >
            <i className="far fa-paperclip"></i>
            {!noLabel ? "Add attachment" : ""}
          </Typography>
        )}
      </div>
    </Tooltip>
  );
}
