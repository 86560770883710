export default theme => ({
  flex: {
    flexGrow: 1,
  },
  paddingTop: {
    paddingTop: 15,
  },
  content: {
    flexGrow: 1,
  },
  container: {
    padding: 5,
  },
  tableRow: {
    cursor: 'pointer',
  },
  toolbar: {
    backgroundColor: theme.palette.background.paper,
  },
  head: {
    backgroundColor: theme.palette.background.paper,
  },
  body: {
    fontSize: 14,
  },
  root: {
    paddingRight: theme.spacing.unit,
    flexGrow: 1,
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: 'black',
      }
      : {
        color: 'white',
      },
  spacer: {
    flex: '1 1 100%',
  },
  title: {
    flex: '0 0 auto',
  },
  textField: {
    width: '100%',
  },
});
