import {
  GET,
  PUT,
  DELETE,
  POST,
  DATA,
} from 'constants/methods';

import { REACT_APP_API_PREFIX } from 'config/env';

const service = 'ap-dashboard';
const journalEntries = `${REACT_APP_API_PREFIX}/${service}/journalEntries`;
const journalEntryItems = `${REACT_APP_API_PREFIX}/${service}/journalEntryItems`;

export function getJournalEntries(filters) {
  const url = `${journalEntries}${
      filters ? `?filters=${JSON.stringify(filters)}` : ""
    }`;
  return {
    method: GET,
    url,
  };
}

export function getJournalEntryByID(id) {
  const url = `${journalEntries}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateJournalEntry(id, body) {
  const url = `${journalEntries}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteJournalEntry(id) {
  const url = `${journalEntries}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createJournalEntry(body) {
  const url = `${journalEntries}`;
  return {
    method: POST,
    url,
    body,
  };
}

export function lockJournalEntry(id) {
  const url = `${journalEntries}/${id}/lock`;
  return {
    method: POST,
    url,
  };
}

export function createJournalEntryItem(journalEntryID, body) {
  const url = `${journalEntries}/${journalEntryID}/journalEntryItems`;
  return {
    method: POST,
    url,
    body,
  };
}

export function updateJournalEntryItem( journalEntryItemID, body) {
  const url = `${journalEntryItems}/${journalEntryItemID}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteJournalEntryItem(journalEntryItemID) {
  const url = `${journalEntryItems}/${journalEntryItemID}`;
  return {
    method: DELETE,
    url,
  };
}

export function uploadJournalEntryFile(id, file) {
  const data = new FormData();
  data.append("file", file);

  const url = `${journalEntries}/${id}/upload`;

  return {
    method: DATA,
    url,
    body: data,
  };
}
