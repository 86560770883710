import { networkAction } from 'helpers/network/networkAction';

import {
  GET_JOURNAL_ENTRIES,
  GET_JOURNAL_ENTRY_BY_ID,
  DELETE_JOURNAL_ENTRY,
  UPDATE_JOURNAL_ENTRY,
  CREATE_JOURNAL_ENTRY,
  LOCK_JOURNAL_ENTRY,
  DELETE_JOURNAL_ENTRY_ITEM,
  UPDATE_JOURNAL_ENTRY_ITEM,
  CREATE_JOURNAL_ENTRY_ITEM,
  UPLOAD_JOURNAL_ENTRY_FILE,
} from 'constants/journalEntries';

import * as Api from 'api';

export const getJournalEntries = (filters) => async dispatch => networkAction(
  dispatch,
  GET_JOURNAL_ENTRIES,
  Api.getJournalEntries,
  [filters],
);

export const getJournalEntryByID = id => async dispatch => networkAction(
  dispatch,
  GET_JOURNAL_ENTRY_BY_ID,
  Api.getJournalEntryByID,
  [id],
);

export const deleteJournalEntry = id => async dispatch => networkAction(
  dispatch,
  DELETE_JOURNAL_ENTRY,
  Api.deleteJournalEntry,
  [id],
);

export const createJournalEntry = body => async dispatch => networkAction(
  dispatch,
  CREATE_JOURNAL_ENTRY,
  Api.createJournalEntry,
  [body],
);

export const updateJournalEntry = (id, body) => async dispatch => networkAction(
  dispatch,
  UPDATE_JOURNAL_ENTRY,
  Api.updateJournalEntry,
  [id, body],
);

export const lockJournalEntry = (id, body) => async dispatch => networkAction(
  dispatch,
  LOCK_JOURNAL_ENTRY,
  Api.lockJournalEntry,
  [id, body],
);

export const deleteJournalEntryItem = id => async dispatch => networkAction(
  dispatch,
  DELETE_JOURNAL_ENTRY_ITEM,
  Api.deleteJournalEntryItem,
  [id],
);

export const createJournalEntryItem = (journalEntryID, body) => async dispatch => networkAction(
  dispatch,
  CREATE_JOURNAL_ENTRY_ITEM,
  Api.createJournalEntryItem,
  [journalEntryID, body],
);

export const updateJournalEntryItem = (id, body) => async dispatch => networkAction(
  dispatch,
  UPDATE_JOURNAL_ENTRY_ITEM,
  Api.updateJournalEntryItem,
  [id, body],
);

export const uploadJournalEntryFile = (id, file) => async (dispatch) => networkAction(
  dispatch,
  UPLOAD_JOURNAL_ENTRY_FILE,
  Api.uploadJournalEntryFile,
  [id,file],
);